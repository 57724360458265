import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag4-sanfona1.css'

const Pag4Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag4-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona1-topo">
        <span className="pag4-sanfona1-text10 Titulo-Extra-B">RoboCup</span>
        <div className="pag4-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag4-sanfona1-container2 DefaultInnerPadding">
          <div className="pag4-sanfona1-container3">
            <div className="pag4-sanfona1-container4">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag4-sanfona1-text40 Subtitulo-A">
                      <span>
                        RoboCup
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag4-sanfona1-text42">
                        é um
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>campeonato mundial de robótica.</span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span>
                  Considerada a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona1-text14">
                  maior competição de Robótica e Inteligência Artificial do
                  mundo.
                </span>
                <br></br>
                <br></br>
                <span>
                  O
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona1-text18">
                  objetivo da RoboCup é fazer com que os robôs derrotem os
                  campeões mundiais
                </span>
                <span>
                  {' '}
                  de futebol humanos em 2050. O evento conta com 17 competições
                  em 5 categorias.
                </span>
                <br></br>
                <br></br>
                <span>
                  As categorias incluem robôs de futebol que jogam de forma
                  independente uns contra os outros, robôs de cuidado que ajudam
                  as pessoas nas tarefas diárias, robôs de resgate que localizam
                  vítimas em situações de desastre, robôs logísticos que
                  trabalham em conjunto com pessoas em ambientes corporativos e
                  competições juniores, em que cerca de mil jovens competem com
                  robôs autoconstruídos e programados.
                </span>
              </span>
            </div>
            <img
              alt="image"
              src="/Pagina 4/imagens/pagina%204%20imagem%202-1500w.png"
              className="pag4-sanfona1-image1 Radius"
            />
          </div>
          <div className="pag4-sanfona1-container5 Radius">
            <div className="pag4-sanfona1-container6">
              <div className="pag4-sanfona1-container7">
                <h1 className="Titulo-Extra">
                  <span className="pag4-sanfona1-text24">
                    Alunos do
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>Colégio Etapa</span>
                  <span className="pag4-sanfona1-text26">
                    , de São Paulo, foram
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>premiados na RoboCup 2023</span>
                </h1>
                <span className="Texto">
                  <span>
                    O evento ocorreu entre os dias 4 e 10 de julho, na cidade
                    francesa de Bordeaux, e reuniu 2,5 mil competidores de 45
                    países. A
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona1-text30">
                    equipe paulista Momentum Robotics realizou uma performance
                  </span>
                  <span> na qual humanos e máquina interagiam.</span>
                </span>
              </div>
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%203-1500w.png"
                className="pag4-sanfona1-image2 Radius"
              />
            </div>
            <a
              href="https://www.youtube.com/watch?v=xKaqPjE1E5E"
              target="_blank"
              rel="noreferrer noopener"
              className="pag4-sanfona1-link Texto"
            >
              <span>
                Os alunos utilizaram a criatividade para simular um comercial
                que demonstrava as qualidades de Maria, a robô desenvolvida por
                eles. Durante a apresentação, dois integrantes do time
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag4-sanfona1-text33">
                interagiram com ela, enquanto a máquina demonstrava suas
                habilidades
              </span>
              <span>
                {' '}
                de compreensão, detecção facial e resolução de cálculos
                matemáticos.
              </span>
              <br></br>
              <span className="pag4-sanfona1-text36">
                Dá só uma conferida na apresentação dos Momentum Robotics.
              </span>
              <br></br>
              <br></br>
              <span>
                Os jovens foram selecionados para o torneio após conquistarem o
                1º lugar na Competição Latino-Americana de Robótica (LARC) 2022,
                que ocorreu em São Paulo. Sim, nós temos robótica!
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

Pag4Sanfona1.defaultProps = {
  heading111: undefined,
  rootClassName: '',
}

Pag4Sanfona1.propTypes = {
  heading111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag4Sanfona1
