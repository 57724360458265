import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import ModeloSlideClassificao from './modelo-slide-classificao'
import './home-sanfona1.css'

const HomeSanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`home-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div className="home-sanfona1-container2">
        <div
          data-thq="slider"
          data-navigation="false"
          data-pagination="true"
          className="home-sanfona1-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="home-sanfona1-slider-slide1 swiper-slide"
            >
              <ModeloSlideClassificao
                titulo={
                  <Fragment>
                    <span className="home-sanfona1-text10 Titulo">
                      De onde viemos? Uma breve história da Robótica
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="home-sanfona1-text11 Texto">
                      <span>
                        Embarque numa experiência imersiva pela história da
                        Robótica. Vamos te apresentar os mais antigos modelos de
                        robôs que se tem registro na história da humanidade,
                        passando pelo
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="home-sanfona1-text13">
                        primeiro robô industrial e pelos filmes de ficção
                        científica, até chegar ao futuro da tecnologia
                      </span>
                      <span>
                        , com impacto no dia a dia de todos nós. Já imaginou a
                        sua geladeira encomendando as compras no supermercado?
                        Este é só o começo! No entretenimento, nas pesquisas
                        científicas, na saúde, na educação…
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="home-sanfona1-text15">
                        A robótica tem o poder de revolucionar!
                      </span>
                      <br></br>
                      <br></br>
                      <span>Duração: 6min</span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina Home/home%20slide%201%20image%201-1200w.png"
                imageSrc2="/Pagina Home/pagina%201%20slide%201%20mobile%20imagem%201-1500h.png"
                rootClassName="modelo-slide-classificaoroot-class-name"
              ></ModeloSlideClassificao>
            </div>
            <div
              data-thq="slider-slide"
              className="home-sanfona1-slider-slide2 swiper-slide"
            >
              <ModeloSlideClassificao
                titulo={
                  <Fragment>
                    <span className="home-sanfona1-text19 Titulo">
                      Salve a Robótica: ferramentas para um futuro sustentável
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="home-sanfona1-text20 Texto">
                      <span>
                        Descubra, nesta viagem imersiva, como a Robótica pode
                        melhorar a qualidade de vida no planeta. Enquanto
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="home-sanfona1-text22">
                        o aquecimento global e outras ações humanas colocam
                        espécies animais e vegetais em risco, a tecnologia ajuda
                        a frear as mudanças climáticas e contribui para os
                        Objetivos Globais de Desenvolvimento Sustentável.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        O Brasil tem, inclusive, uma professora que é referência
                        internacional em Robótica aplicada à sustentabilidade.
                        Mas como isso ocorre na prática? E o que cada um de nós
                        tem a ver com isso? Assista e confira!
                      </span>
                      <br></br>
                      <br></br>
                      <span>Duração: 6min</span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina Home/home%20slide%201%20image%202-1200w.png"
                imageSrc2="/Pagina Home/pagina%201%20slide%201%20mobile%20imagem%202-1500h.png"
                rootClassName="modelo-slide-classificaoroot-class-name1"
              ></ModeloSlideClassificao>
            </div>
            <div
              data-thq="slider-slide"
              className="home-sanfona1-slider-slide3 swiper-slide"
            >
              <ModeloSlideClassificao
                titulo={
                  <Fragment>
                    <span className="home-sanfona1-text27 Titulo">
                      O mundo sem sair do lugar: cidades digitais
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="home-sanfona1-text28 Texto">
                      <span className="home-sanfona1-text29">
                        Vamos passear virtualmente pelas cidades mais
                        inteligentes do mundo, onde pesquisas aceleram o
                        desenvolvimento de soluções inovadoras.
                      </span>
                      <span>
                        {' '}
                        Conheça os polos globais de referência digital, que
                        ditam o futuro da Robótica e de outras ferramentas que
                        transformam nosso jeito de se relacionar uns com os
                        outros e com o planeta. Na palma da mão, nas ruas ou no
                        óculos de realidade virtual, a tecnologia invadiu nossos
                        dias, expandiu possibilidades, conectou realidades,
                        permitiu encontros até então improváveis.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Duração: 3min</span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina Home/home%20slide%201%20image%203-1200w.png"
                imageSrc2="/Pagina Home/pagina%201%20slide%201%20mobile%20imagem%203-1500h.png"
                rootClassName="modelo-slide-classificaoroot-class-name2"
              ></ModeloSlideClassificao>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="home-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="home-sanfona1-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="home-sanfona1-slider-button-next swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  )
}

HomeSanfona1.defaultProps = {
  rootClassName: '',
}

HomeSanfona1.propTypes = {
  rootClassName: PropTypes.string,
}

export default HomeSanfona1
