import React, { useState } from 'react'

import './hero-pages.css'

const HeroPages = (props) => {
  const [isAcessivel, setIsAcessivel] = useState(false)
  return (
    <div className="hero-pages-hero DefaultPadding">
      <img
        alt="image"
        src="/Geral/fundo%20topo%20pagina-600h.png"
        className="hero-pages-image"
      />
      <div className="hero-pages-container1 DefaultInnerPadding">
        <div className="hero-pages-container2">
          <span className="hero-pages-text10">01</span>
          <div className="hero-pages-container3">
            <span className="hero-pages-text11 Subtitulo-A">
              <span className="hero-pages-text12">
                De onde viemos? Para onde vamos?
              </span>
              <span>Uma breve história da Robótica</span>
            </span>
            <div className="hero-pages-container4">
              <div
                onClick={() => setIsAcessivel(!isAcessivel)}
                className="hero-pages-boto"
              >
                <span className="hero-pages-text14 Botao">
                  Versão acessível
                </span>
              </div>
              <div className="hero-pages-indicaes">
                <img
                  alt="Vector9161"
                  src="/Geral/sinais-200w.png"
                  className="hero-pages-vector1"
                />
                <img
                  alt="Vector9161"
                  src="/Geral/ingles-200w.png"
                  className="hero-pages-vector2"
                />
                <img
                  alt="Vector9161"
                  src="/Geral/descricao-200w.png"
                  className="hero-pages-vector3"
                />
              </div>
            </div>
          </div>
        </div>
        {!isAcessivel && (
          <div className="hero-pages-container5">
            <iframe
              src="https://www.youtube.com/embed/LdrV6A95sqw?si=I6IgGlCny53i2Etl"
              className="hero-pages-iframe1"
            ></iframe>
          </div>
        )}
        {isAcessivel && (
          <div className="hero-pages-container6">
            <iframe
              src="https://www.youtube.com/embed/zA-eCGFBXjM?si=Yr4KKKSH5lrTx7Py"
              className="hero-pages-iframe2"
            ></iframe>
          </div>
        )}
        <div className="hero-pages-container7">
          <img
            alt="Vector1068"
            src="/external/vector1068-4e2.svg"
            className="hero-pages-vector4"
          />
          <span className="Observacao">
            <span className="hero-pages-text16">Vá além e aprenda mais</span>
            <span> a partir dos</span>
            <br></br>
            <span>conteúdos que você assistiu no vídeo</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default HeroPages
