import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Pag3Sanfona1 from '../components/pag3-sanfona1'
import Pag3Sanfona3 from '../components/pag3-sanfona3'
import Pag3Sanfona4 from '../components/pag3-sanfona4'
import Pag3Sanfona5 from '../components/pag3-sanfona5'
import SeoFinal from '../components/seo-final'
import './pagina-3.css'

const Pagina3 = (props) => {
  return (
    <div className="pagina3-container1">
      <Helmet>
        <title>Pagina-3 - MIRT</title>
        <meta property="og:title" content="Pagina-3 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name3"></Navbar>
      <div className="pagina3-hero DefaultPadding">
        <img
          alt="image"
          src="/Geral/fundo%20topo%20pagina-700h.png"
          className="pagina3-image1"
        />
        <div className="pagina3-container2 DefaultInnerPadding">
          <div className="pagina3-container3">
            <span className="pagina3-text10">03</span>
            <div className="pagina3-container4">
              <span className="pagina3-text11 Subtitulo-A">
                <span>Sim, tem um robô perto de você</span>
                <br></br>
                <span>(ou terá muito em breve)</span>
              </span>
              <div className="pagina3-container5">
                <div className="pagina3-boto">
                  <span className="pagina3-text15 Botao">Versão acessível</span>
                </div>
                <div className="pagina3-indicaes">
                  <img
                    alt="Vector9161"
                    src="/Geral/sinais-200w.png"
                    className="pagina3-vector1"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/ingles-200w.png"
                    className="pagina3-vector2"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/descricao-200w.png"
                    className="pagina3-vector3"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="/Pagina 3/imagens/pagina%203%20imagem%201-1200w.png"
            className="pagina3-image2"
          />
          <div className="pagina3-container6">
            <img
              alt="Vector1068"
              src="/external/vector1068-4e2.svg"
              className="pagina3-vector4"
            />
            <span className="Observacao">
              <span className="pagina3-text17">Vá além e aprenda mais</span>
              <span> a partir dos</span>
              <br></br>
              <span>conteúdos que você assistiu no vídeo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="pagina3-container7">
        <Pag3Sanfona1
          heading111={
            <Fragment>
              <span className="pagina3-text21 Subtitulo-A">
                O que é uma casa inteligente?
              </span>
            </Fragment>
          }
          rootClassName="pag3-sanfona1root-class-name"
        ></Pag3Sanfona1>
        <Pag3Sanfona3 rootClassName="pag3-sanfona3root-class-name"></Pag3Sanfona3>
        <Pag3Sanfona4
          heading111={
            <Fragment>
              <span className="pagina3-text22 Subtitulo-A">
                <span className="pagina3-text23">
                  Você se lembra ou chegou a ver os
                </span>
                <span> Jetsons?</span>
              </span>
            </Fragment>
          }
          rootClassName="pag3-sanfona4root-class-name"
        ></Pag3Sanfona4>
        <Pag3Sanfona5
          text={
            <Fragment>
              <span className="pagina3-text25">Fontes: GOV Observatório</span>
            </Fragment>
          }
          heading111={
            <Fragment>
              <span className="pagina3-text26 Subtitulo-A">
                Ok, estamos no futuro, mas você já percebeu que os tempos se
                comunicam, certo?
              </span>
            </Fragment>
          }
          heading1111={
            <Fragment>
              <span className="pagina3-text27 Subtitulo-A">
                <span className="pagina3-text28">
                  Você notou alguma coincidência nos
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>nomes das missões</span>
                <span className="pagina3-text30"> da Nasa?</span>
              </span>
            </Fragment>
          }
          rootClassName="pag3-sanfona5root-class-name"
        ></Pag3Sanfona5>
      </div>
      <div className="pagina3-sugestao-conteudo DefaultPadding">
        <div className="pagina3-interno MaxWidthMenor">
          <div className="pagina3-texto-ei">
            <span className="pagina3-text31 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina3-text32 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina3-button Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina3-container8">
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%2010%20teste-500h.png"
              className="pagina3-image3"
            />
          </div>
          <div className="pagina3-container9">
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%2010-1200w.png"
              className="pagina3-image4"
            />
          </div>
        </div>
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name2"
      ></SeoFinal>
    </div>
  )
}

export default Pagina3
