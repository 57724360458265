import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag5-sanfona2.css'

const Pag5Sanfona2 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag5-sanfona2-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag5-sanfona2-topo">
        <span className="pag5-sanfona2-text10 Titulo-Extra-B">
          Robôs nas telas do cinema
        </span>
        <div className="pag5-sanfona2-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag5-sanfona2-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag5-sanfona2-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag5-sanfona2-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag5-sanfona2-container3">
          <span className="DefaultInnerPadding Texto pag5-sanfona2-text11">
            <span>
              Além dos games, os robôs estão presentes nas telas dos cinemas, e
              também dá pra aprender mais sobre eles curtindo a sétima arte,
              seja com amigos, familiares, ou até mesmo sozinhos. Todos os
              filmes citados aqui têm
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag5-sanfona2-text13">
              classificação indicativa até 14 anos.
            </span>
            <br></br>
          </span>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag5-sanfona2-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag5-sanfona2-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 5/images/pagina%205%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona2-text15 Titulo">
                        Wall-E (2008)
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona2-text16 Texto">
                        <span>Direção: Andrew Stanton</span>
                        <br className="pag5-sanfona2-text18"></br>
                        <br className="pag5-sanfona2-text19"></br>
                        <span>
                          Um adorável filme da Pixar sobre um pequeno robô
                          designado para limpar a Terra desolada em sua jornada
                          para
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona2-text21">
                          descobrir o verdadeiro significado da vida.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 2/pagina%205%20slide%202%20imagem%201-1500h.png"
                  imageSrc1="/Pagina 5/slide 2/pagina%205%20slide%202%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name74"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona2-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 5/images/pagina%205%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona2-text22 Titulo">
                        Robôs (2005)
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona2-text23 Texto">
                        <span>Direção: Chris Wedge</span>
                        <br></br>
                        <br></br>
                        <span>
                          Uma animação que conta a jornada que um
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona2-text28">
                          jovem robô realiza em busca de seus sonhos
                        </span>
                        <span>
                          {' '}
                          e de desafiar o status quo da sociedade robótica em
                          que vive.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 2/pagina%205%20slide%202%20imagem%202-1500h.png"
                  imageSrc1="/Pagina 5/slide 2/pagina%205%20slide%202%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name75"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona2-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 5/images/pagina%205%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona2-text31 Titulo">
                        Operação Big Hero (2014)
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona2-text32 Texto">
                        <span>Direção: Don Hall e Chris Williams</span>
                        <br></br>
                        <br></br>
                        <span>
                          Essa história da Disney é sobre um
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona2-text37">
                          jovem prodígio e seu robô inflável
                        </span>
                        <span>
                          {' '}
                          que vivem uma incrível aventura para combater o crime
                          em uma cidade futurista.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 2/pagina%205%20slide%202%20imagem%203-1500h.png"
                  imageSrc1="/Pagina 5/slide 2/pagina%205%20slide%202%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name76"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona2-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 5/images/pagina%205%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona2-text39 Titulo">
                        Astro Boy (2009)
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona2-text40 Texto">
                        <span>Direção: David Bowers</span>
                        <br></br>
                        <br></br>
                        <span>
                          Baseado no
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona2-text45">mangá</span>
                        <span>
                          , este filme conta a história de
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona2-text47">
                          um robô criado para substituir o filho falecido de um
                          cientista
                        </span>
                        <span>
                          {' '}
                          em suas aventuras para descobrir sua verdadeira
                          identidade.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 2/pagina%205%20slide%202%20imagem%204-1500h.png"
                  imageSrc1="/Pagina 5/slide 2/pagina%205%20slide%202%20mobile%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name77"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona2-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 5/images/pagina%205%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona2-text49 Titulo">
                        Os Incríveis (2004)
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona2-text50 Texto">
                        <span>Direção: Brad Bird</span>
                        <br></br>
                        <br></br>
                        <span>
                          Embora não seja exclusivamente sobre robôs, este filme
                          da Pixar apresenta um vilão que usa uma série de robôs
                          como parte de seu plano maligno, trazendo uma
                          perspectiva interessante sobre a interação entre
                          humanos e máquinas.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 2/pagina%205%20slide%202%20imagem%205-1500h.png"
                  imageSrc1="/Pagina 5/slide 2/pagina%205%20slide%202%20mobile%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name78"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag5-sanfona2-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag5-sanfona2-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag5-sanfona2-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag5Sanfona2.defaultProps = {
  rootClassName: '',
}

Pag5Sanfona2.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pag5Sanfona2
