import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Pagina6 from './views/pagina-6'
import Pagina2 from './views/pagina-2'
import Pagina3 from './views/pagina-3'
import Home from './views/home'
import Pagina1 from './views/pagina-1'
import Formulario from './views/formulario'
import Pagina5 from './views/pagina-5'
import Pagina4 from './views/pagina-4'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Pagina6} exact path="/pagina-6" />
        <Route component={Pagina2} exact path="/pagina-2" />
        <Route component={Pagina3} exact path="/pagina-3" />
        <Route component={Home} exact path="/" />
        <Route component={Pagina1} exact path="/pagina-1" />
        <Route component={Formulario} exact path="/formulario" />
        <Route component={Pagina5} exact path="/pagina-5" />
        <Route component={Pagina4} exact path="/pagina-4" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
