import React, { useState, Fragment, useEffect } from "react";

import PropTypes from "prop-types";

import ModeloSlideLink from "./modelo-slide-link";
import ModeloSlide from "./modelo-slide";
import "./pag1-sanfona1.css";

const Pag1Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag1-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona1-topo">
        <span className="pag1-sanfona1-text10 Titulo-Extra-B">
          Cirurgia robótica
        </span>
        <div className="pag1-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag1-sanfona1-container2">
        <div
          data-thq="slider"
          data-navigation="false"
          data-pagination="true"
          className="pag1-sanfona1-slider swiper"
          style={isAtivo ? {} : { display: "none" }}
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="pag1-sanfona1-slider-slide1 swiper-slide"
            >
              <ModeloSlideLink
                link="https://wyss.harvard.edu/"
                icone="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona1-text11 Titulo">
                      Mais de 27 mil cirurgias robóticas são realizadas no
                      Brasil todos os anos
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona1-text12 Texto">
                      <span>
                        O número de cirurgias realizadas com o auxílio da
                        plataforma robótica no Brasil saltou de 24 mil (2021),
                        para 27 mil (2022).
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona1-text14">
                        Foi um crescimento de 12,5% em um ano, segundo
                        representantes do setor no mercado.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Desde 2008, quando iniciou a operação do primeiro
                        sistema no país, até o final de 2022 foram feitos cerca
                        de 100 mil procedimentos.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Se você se interessa por tecnologias inovadoras e
                        bioinspiradas na área da medicina, dê uma olhada no
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona1-text21">
                        site do Instituto Wyss, da Universidade de Harvard.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20imagem%201-1500h.png"
                imageSrc1="/Pagina 1/Mobile/pagina%201%20slide%201%20mobile%20imagem%2011-1500h.png"
                rootClassName="modelo-slide-linkroot-class-name18"
              ></ModeloSlideLink>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona1-slider-slide2 swiper-slide"
            >
              <ModeloSlideLink
                link="https://www.portaldavinci.com.br/"
                icone="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona1-text22 Titulo">
                      Da Vinci é o nome de um dos mais avançados robôs de
                      cirurgia
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona1-text23 Texto">
                      <span>
                        No vídeo falamos que o primeiro robô foi criado por
                        Leonardo Da Vinci, certo? O Autômato.
                      </span>
                      <br></br>
                      <span>
                        É este o nome que foi dado a um dos robôs mais avançados
                        de cirurgia.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Ao todo, mais de 13 milhões de procedimentos já foram
                        realizados ao redor do mundo com robôs Da Vinci.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="pag1-sanfona1-text32">
                        Conhecer mais sobre o Da Vinci.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20imagem%202-1500h.png"
                imageSrc1="/Pagina 1/Mobile/pagina%201%20slide%201%20mobile%20imagem%2021-1500h.png"
                rootClassName="modelo-slide-linkroot-class-name19"
              ></ModeloSlideLink>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona1-slider-slide3 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona1-text34 Titulo">
                      Mais de 27 mil cirurgias robóticas são realizadas no
                      Brasil todos os anos
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona1-text35 Texto">
                      <span className="pag1-sanfona1-text36">
                        O Da Vinci ajuda os médicos a realizarem cirurgias com
                        maior precisão.
                      </span>
                      <span>
                        {" "}
                        Mas, para isso, além de ser médico é necessário saber
                        operar o robô.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        A robótica e a tecnologia vão transformar bastante o
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona1-text41">
                        mercado de trabalho do futuro.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Que tal dar uma pesquisada sobre esse assunto no Google?
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 1/pagina%201%20slide%201%20imagem%203-1500h.png"
                imageSrc1="/Pagina 1/Mobile/pagina%201%20slide%201%20mobile%20imagem%2031-1500h.png"
                rootClassName="modelo-slideroot-class-name8"
              ></ModeloSlide>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="pag1-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="pag1-sanfona1-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="pag1-sanfona1-slider-button-next swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  );
};

Pag1Sanfona1.defaultProps = {
  rootClassName: "",
};

Pag1Sanfona1.propTypes = {
  rootClassName: PropTypes.string,
};

export default Pag1Sanfona1;
