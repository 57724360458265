import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Pag4Sanfona1 from '../components/pag4-sanfona1'
import Pag4Sanfona2 from '../components/pag4-sanfona2'
import Pag4Sanfona3 from '../components/pag4-sanfona3'
import Pag4Sanfona4 from '../components/pag4-sanfona4'
import Pag4Sanfona5 from '../components/pag4-sanfona5'
import Pag4Sanfona6 from '../components/pag4-sanfona6'
import SeoFinal from '../components/seo-final'
import './pagina-4.css'

const Pagina4 = (props) => {
  return (
    <div className="pagina4-container1">
      <Helmet>
        <title>Pagina-4 - MIRT</title>
        <meta property="og:title" content="Pagina-4 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name4"></Navbar>
      <div className="pagina4-hero DefaultPadding">
        <img
          alt="image"
          src="/Geral/fundo%20topo%20pagina-700h.png"
          className="pagina4-image1"
        />
        <div className="pagina4-container2 DefaultInnerPadding">
          <div className="pagina4-container3">
            <span className="pagina4-text10">04</span>
            <div className="pagina4-container4">
              <span className="pagina4-text11 Subtitulo-A">
                <span className="pagina4-text12">Batalha de robôs:</span>
                <br></br>
                <span>Quando a educação e o lúdico caminham juntos</span>
              </span>
              <div className="pagina4-container5">
                <div className="pagina4-boto">
                  <span className="pagina4-text15 Botao">Versão acessível</span>
                </div>
                <div className="pagina4-indicaes">
                  <img
                    alt="Vector9161"
                    src="/Geral/sinais-200w.png"
                    className="pagina4-vector1"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/ingles-200w.png"
                    className="pagina4-vector2"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/descricao-200w.png"
                    className="pagina4-vector3"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="/Pagina 4/imagens/pagina%204%20imagem%201-1200w.png"
            className="pagina4-image2 Radius"
          />
          <div className="pagina4-container6">
            <img
              alt="Vector1068"
              src="/external/vector1068-4e2.svg"
              className="pagina4-vector4"
            />
            <span className="Observacao">
              <span className="pagina4-text17">Vá além e aprenda mais</span>
              <span> a partir dos</span>
              <br></br>
              <span>conteúdos que você assistiu no vídeo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="pagina4-container7">
        <Pag4Sanfona1
          heading111={
            <Fragment>
              <span className="pagina4-text21 Subtitulo-A">
                <span>
                  RoboCup
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text23">
                  é um
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>campeonato mundial de robótica.</span>
              </span>
            </Fragment>
          }
          rootClassName="pag4-sanfona1root-class-name"
        ></Pag4Sanfona1>
        <Pag4Sanfona2
          heading111={
            <Fragment>
              <span className="pagina4-text25 Subtitulo-A">
                <span>
                  Ubbu
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text27">
                  é uma ferramenta de aprendizado que ensina
                </span>
                <span>
                  {' '}
                  ciência da computação e programação para crianças e jovens
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag4-sanfona2root-class-name"
        ></Pag4Sanfona2>
        <Pag4Sanfona3
          heading111={
            <Fragment>
              <span className="pagina4-text29 Subtitulo-A">
                <span>
                  FIRST LEGO League
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text31">
                  é um programa que apresenta ciência, tecnologia, engenharia e
                  matemática
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>(STEM)</span>
              </span>
            </Fragment>
          }
          rootClassName="pag4-sanfona3root-class-name"
        ></Pag4Sanfona3>
        <Pag4Sanfona4
          heading111={
            <Fragment>
              <span className="pagina4-text33 Subtitulo-A">
                <span className="pagina4-text34">
                  O que é
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>robótica educacional?</span>
              </span>
            </Fragment>
          }
          rootClassName="pag4-sanfona4root-class-name"
        ></Pag4Sanfona4>
        <Pag4Sanfona5 rootClassName="pag4-sanfona5root-class-name"></Pag4Sanfona5>
        <Pag4Sanfona6
          text6={
            <Fragment>
              <span className="pagina4-text36 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text7={
            <Fragment>
              <span className="pagina4-text37 Texto">
                <span>
                  O programa
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text39">Muito Além dos Robôs</span>
                <span>
                  {' '}
                  é um bate-papo mensal sobre as diferentes
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text41">
                  aplicações da robótica na vida das pessoas
                </span>
                <span>
                  , com entrevistas e muitas ideias para os projetos de inovação
                  das equipes.
                </span>
                <br></br>
                <br></br>
                <span>Acesso disponível nas plataformas:</span>
                <br></br>
                <span className="pagina4-text47">Spotify</span>
                <span>
                  ,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text49">Google Podcasts</span>
                <span>
                  {' '}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text51">Deezer</span>
                <span>.</span>
              </span>
            </Fragment>
          }
          text71={
            <Fragment>
              <span className="pagina4-text53 Texto">
                <span>
                  O canal
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text55">Sou Robótica</span>
                <span>
                  {' '}
                  é sobre robótica educacional, além de ter o mesmo nome de um
                  programa de computador famoso, o qual foi um marco da
                  iniciação tecnológica.
                </span>
                <br></br>
                <br></br>
                <span>
                  Acesse disponível no
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text60">canal de Youtube</span>
                <span>.</span>
              </span>
            </Fragment>
          }
          heading1111={
            <Fragment>
              <span className="pagina4-text62 Subtitulo-A">
                <span>
                  E já que falamos de
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina4-text64">design e arte...</span>
              </span>
            </Fragment>
          }
          rootClassName="pag4-sanfona6root-class-name"
        ></Pag4Sanfona6>
      </div>
      <div className="pagina4-sugestao-conteudo1 DefaultPadding">
        <div className="pagina4-interno1 MaxWidthMenor">
          <div className="pagina4-texto-ei1">
            <span className="pagina4-text65 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina4-text66 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina4-button1 Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina4-container8">
            <img
              alt="image"
              src="/Pagina 4/imagens/pagina%204%20imagem%2014-1200w.png"
              className="pagina4-image3"
            />
          </div>
        </div>
      </div>
      <div className="pagina4-sugestao-conteudo2">
        <div className="pagina4-interno2 DefaultInnerPadding MaxWidthMenor">
          <div className="pagina4-texto-ei2">
            <span className="pagina4-text67 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina4-text68 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina4-button2 Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina4-container9">
            <img
              alt="image"
              src="/Pagina 4/imagens/pagina%204%20imagem%2014%20new-1200w.png"
              className="pagina4-image4"
            />
          </div>
        </div>
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name3"
      ></SeoFinal>
    </div>
  )
}

export default Pagina4
