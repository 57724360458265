import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './modelo-slide-link.css'

const ModeloSlideLink = (props) => {
  return (
    <div className={`modelo-slide-link-container ${props.rootClassName} `}>
      <div className="modelo-slide-link-interno">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="modelo-slide-link-image-desktop HideOnMobile"
        />
        <img
          alt={props.imageAlt1}
          src={props.imageSrc1}
          imageSrc1={props.imageImageSrc1}
          className="modelo-slide-link-image-mobile ShowOnMobile"
        />
        <div className="modelo-slide-link-frame1000003600">
          <img
            alt={props.iconeAlt}
            src={props.icone}
            className="modelo-slide-link-image"
          />
          <span className="modelo-slide-link-text10 Titulo">
            {props.titulo ?? (
              <Fragment>
                <span className="modelo-slide-link-text12 Titulo">
                  Mais de 27 mil cirurgias robóticas são realizadas no Brasil
                  todos os anos
                </span>
              </Fragment>
            )}
          </span>
          <a
            href={props.link}
            target="_blank"
            rel="noreferrer noopener"
            className="modelo-slide-link-text11"
          >
            {props.conteudo ?? (
              <Fragment>
                <span className="modelo-slide-link-text13 Texto">
                  <span></span>
                  <span className="modelo-slide-link-text15">
                    Foi um crescimento de 12,5% em um ano, segundo
                    representantes do setor no mercado.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Desde 2008, quando iniciou a operação do primeiro sistema no
                    país, até o final de 2022 foram feitos cerca de 100 mil
                    procedimentos.
                  </span>
                  <br></br>
                </span>
              </Fragment>
            )}
          </a>
          <div className="modelo-slide-link-indicaes">
            <img
              alt="DJCTQL16141"
              src="/external/livre-200w-200w.png"
              className="modelo-slide-link-djctql1"
            />
            <img
              alt="Vector9161"
              src="/external/sinais-200w-200w.png"
              className="modelo-slide-link-vector1"
            />
            <img
              alt="Vector9161"
              src="/external/ingles-200w-200w.png"
              className="modelo-slide-link-vector2"
            />
            <img
              alt="Vector9161"
              src="/external/descricao-200w-200w.png"
              className="modelo-slide-link-vector3"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ModeloSlideLink.defaultProps = {
  imageAlt: 'image',
  iconeAlt: 'icone',
  imageImageSrc1: '',
  link: 'https://www.teleporthq.io',
  rootClassName: '',
  imageSrc:
    '/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%201-1500h.png',
  imageSrc1:
    '/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%201-1500h.png',
  imageAlt1: 'image',
  titulo: undefined,
  icone: '/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%205-1500h.png',
  conteudo: undefined,
}

ModeloSlideLink.propTypes = {
  imageAlt: PropTypes.string,
  iconeAlt: PropTypes.string,
  imageImageSrc1: PropTypes.string,
  link: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  titulo: PropTypes.element,
  icone: PropTypes.string,
  conteudo: PropTypes.element,
}

export default ModeloSlideLink
