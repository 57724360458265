import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './seo-final.css'

const SeoFinal = (props) => {
  return (
    <div className={`seo-final-seo-final ${props.rootClassName} `}>
      <div className="seo-final-container1 thq-section-max-width">
        <div className="seo-final-container2">
          <div className="seo-final-container3">
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="seo-final-image1"
            />
            <img
              alt={props.imageAlt4}
              src={props.imageSrc4}
              className="seo-final-image2"
            />
            <img
              alt={props.imageAlt41}
              src={props.imageSrc41}
              className="seo-final-image3"
            />
          </div>
          <Link
            to="/formulario"
            className="seo-final-button Pointer Botao HoverScale"
          >
            {props.button}
          </Link>
        </div>
        <div className="seo-final-robo">
          <img
            alt={props.imageAlt5}
            src={props.imageSrc5}
            className="seo-final-image4"
          />
          <img
            alt="image"
            src="/Secao final/secao%20final%20robo-1300w.png"
            className="seo-final-image5"
          />
        </div>
        <a href="#navbar" className="seo-final-link">
          <img
            alt={props.imageAlt1}
            src={props.imageSrc1}
            className="seo-final-image6"
          />
        </a>
        <img
          alt={props.imageAlt2}
          src={props.imageSrc2}
          className="seo-final-image7"
        />
      </div>
    </div>
  )
}

SeoFinal.defaultProps = {
  imageSrc41: '/Secao final/logo%20mirt%20mobile-800h.png',
  imageAlt5: 'image',
  button: 'Quero a MIRT pertinho de mim',
  imageAlt2: 'image',
  imageAlt41: 'image',
  imageSrc1: '/Secao final/secao%20final%20flecha-800h.png',
  rootClassName: '',
  imageAlt4: 'image',
  imageSrc3: '/Secao final/secao%20final%20sesc-800h.png',
  imageSrc4: '/Secao final/secao%20final%20mostra-800h.png',
  imageSrc5: '/Secao final/secao%20final%20sombra%20robo-1400w.png',
  imageAlt3: 'image',
  imageSrc2: '/Secao final/secao%20final%20grid-800h.png',
  imageAlt1: 'image',
}

SeoFinal.propTypes = {
  imageSrc41: PropTypes.string,
  imageAlt5: PropTypes.string,
  button: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt41: PropTypes.string,
  imageSrc1: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt4: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc4: PropTypes.string,
  imageSrc5: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt1: PropTypes.string,
}

export default SeoFinal
