import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './modelo-slide.css'

const ModeloSlide = (props) => {
  return (
    <div className={`modelo-slide-container ${props.rootClassName} `}>
      <div className="modelo-slide-interno">
        <img
          alt={props.imageAlt}
          src={props.imageSrc1}
          imageSrc1={props.imageImageSrc1}
          className="modelo-slide-image-mobile ShowOnMobile"
        />
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="modelo-slide-image-desktop HideOnMobile"
        />
        <div className="modelo-slide-frame1000003600">
          <img
            alt={props.iconeAlt}
            src={props.icone}
            className="modelo-slide-image"
          />
          <span className="modelo-slide-text10 Titulo">
            {props.titulo ?? (
              <Fragment>
                <span className="modelo-slide-text12 Titulo">
                  Mais de 27 mil cirurgias robóticas são realizadas no Brasil
                  todos os anos
                </span>
              </Fragment>
            )}
          </span>
          <span className="modelo-slide-text11">
            {props.conteudo ?? (
              <Fragment>
                <span className="Texto">
                  <span className="modelo-slide-text14">
                    Foi um crescimento de 12,5% em um ano, segundo
                    representantes do setor no mercado.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    Desde 2008, quando iniciou a operação do primeiro sistema no
                    país, até o final de 2022 foram feitos cerca de 100 mil
                    procedimentos.
                  </span>
                  <br></br>
                  <span>
                    O número de cirurgias realizadas com o auxílio da plataforma
                    robótica no Brasil saltou de 24 mil (2021), para 27 mil
                    (2022).
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              </Fragment>
            )}
          </span>
          <div className="modelo-slide-indicaes">
            <img
              alt="DJCTQL16141"
              src="/external/livre-200w-200w.png"
              className="modelo-slide-djctql1"
            />
            <img
              alt="Vector9161"
              src="/external/sinais-200w-200w.png"
              className="modelo-slide-vector1"
            />
            <img
              alt="Vector9161"
              src="/external/ingles-200w-200w.png"
              className="modelo-slide-vector2"
            />
            <img
              alt="Vector9161"
              src="/external/descricao-200w-200w.png"
              className="modelo-slide-vector3"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ModeloSlide.defaultProps = {
  icone: '/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%205-1500h.png',
  imageAlt: 'image',
  titulo: undefined,
  iconeAlt: 'icone',
  conteudo: undefined,
  imageSrc1:
    '/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%201-1500h.png',
  rootClassName: '',
  imageSrc:
    '/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%201-1500h.png',
  imageImageSrc1: '',
}

ModeloSlide.propTypes = {
  icone: PropTypes.string,
  imageAlt: PropTypes.string,
  titulo: PropTypes.element,
  iconeAlt: PropTypes.string,
  conteudo: PropTypes.element,
  imageSrc1: PropTypes.string,
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  imageImageSrc1: PropTypes.string,
}

export default ModeloSlide
