import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag3-sanfona5.css'

const Pag3Sanfona5 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag3-sanfona5-container10 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag3-sanfona5-topo">
        <span className="pag3-sanfona5-text100 Titulo-Extra-B">
          Já que o papo é futurista...
        </span>
        <div className="pag3-sanfona5-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona5-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona5-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag3-sanfona5-container11" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag3-sanfona5-container12">
          <span className="Texto">
            <span>
              Alguém aí já sonhou ser astronauta? Ou sonhou visitar a Lua?
              Outros planetas?
            </span>
            <br className="pag3-sanfona5-text103"></br>
            <span>
              Bom, no mínimo, você tem curiosidade sobre o espaço, certo? E
              afinal, já que estamos falando de inventos dos tempos futuros,
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag3-sanfona5-text105">
              impossível não pensar em viagens espaciais e demais descobertas
              sobre a lua, as estrelas, o sol, os planetas, além da Terra e todo
              o resto do universo infinito e desconhecido.
            </span>
          </span>
          <a
            href={props.textUrl}
            target="_blank"
            rel="noreferrer noopener"
            className="pag3-sanfona5-link1 Texto"
          >
            {props.text ?? (
              <Fragment>
                <span className="pag3-sanfona5-text201">
                  Fontes: GOV Observatório
                </span>
              </Fragment>
            )}
          </a>
        </div>
        <div
          data-thq="slider"
          data-navigation="false"
          data-pagination="true"
          className="pag3-sanfona5-slider swiper"
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="pag3-sanfona5-slider-slide1 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag3-sanfona5-text106 Titulo">
                      Missões com Artemis
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag3-sanfona5-text107 Texto">
                      <span>
                        Há duas missões espaciais no radar da Nasa
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text109">
                        envolvendo o satélite natural que conhecemos como Lua.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Uma das missões é o </span>
                      <span className="pag3-sanfona5-text113">
                        Programa Artemis
                      </span>
                      <span>
                        , que consiste, basicamente, no plano da Nasa para
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text115">
                        voltar à Lua, sendo que a primeira visita de humanos por
                        lá foi em 1969.
                      </span>
                      <span>
                        {' '}
                        Nessa missão, estão incluídas a primeira mulher e a
                        primeira pessoa negra.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 3/slide 2/pagina%203%20slide%202%20imagem%201-1200w.png"
                imageSrc1="/Pagina 3/slide 2/pagina%203%20slide%202%20mobile%20imagem%201-1500h.png"
                rootClassName="modelo-slideroot-class-name50"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag3-sanfona5-slider-slide2 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag3-sanfona5-text117 Titulo">
                      Missões com Artemis
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag3-sanfona5-text118 Texto">
                      <span>
                        A Nasa já realizou a primeira fase do programa, sem
                        astronautas a bordo, em dezembro de 2023.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="pag3-sanfona5-text122">Artemis I</span>
                      <span>
                         foi uma missão de teste não tripulada que
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text124">
                        enviou a cápsula Orion
                      </span>
                      <span>
                        {' '}
                        da Nasa em uma viagem de 2,25 milhões de quilômetros
                        para dar uma volta na Lua. Mas a Artemis II não deve
                        decolar antes de 2025.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Estima-se que</span>
                      <span className="pag3-sanfona5-text129">
                        {' '}
                        a jornada dure cerca de dez dias e envie a tripulação
                        para além da Lua, mais longe do que qualquer ser humano
                        já viajou na história,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        embora a distância exata ainda não tenha sido
                        determinada. Depois de circular a Lua, a espaçonave
                        retornará à Terra para um pouso no Oceano Pacífico.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 3/slide 2/pagina%203%20slide%202%20imagem%202-1200w.png"
                imageSrc1="/Pagina 3/slide 2/pagina%203%20slide%202%20mobile%20imagem%202-1500h.png"
                rootClassName="modelo-slideroot-class-name51"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag3-sanfona5-slider-slide3 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag3-sanfona5-text132 Titulo">
                      Missão VIPER
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag3-sanfona5-text133 Texto">
                      <span>A outra missão da Nasa é a </span>
                      <span className="pag3-sanfona5-text135">VIPER</span>
                      <span>
                        {' '}
                        (Volatiles Investigating Polar Exploration Rover).
                      </span>
                      <br></br>
                      <span>A empreitada dessa vez é</span>
                      <span className="pag3-sanfona5-text139">
                        {' '}
                        enviar um robô do tamanho de um carrinho de golfe para
                        explorar o polo sul da Lua.
                      </span>
                      <br className="pag3-sanfona5-text140"></br>
                      <br></br>
                      <span>
                        Esta missão vai
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text143">
                        procurar moléculas voláteis
                      </span>
                      <span>
                        , que facilmente vaporizam, como água e dióxido de
                        carbono, em temperaturas lunares. Esses materiais
                        poderiam fornecer recursos para a futura exploração
                        humana na Lua.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 3/slide 2/pagina%203%20slide%202%20imagem%203-1200w.png"
                imageSrc1="/Pagina 3/slide 2/pagina%203%20slide%202%20mobile%20imagem%203-1500h.png"
                rootClassName="modelo-slideroot-class-name52"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag3-sanfona5-slider-slide4 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag3-sanfona5-text145 Titulo">
                      Missão Europa Clipper
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag3-sanfona5-text146 Texto">
                      <span>Já a missão </span>
                      <span className="pag3-sanfona5-text148">
                        Europa Clipper vai explorar uma das maiores luas
                        de Júpiter, Europa
                      </span>
                      <span>
                        {' '}
                        (olha aí um nome resgatado da mitologia grega!). Europa
                        é um pouco menor que a lua da Terra, com uma superfície
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text150">
                        feita de gelo que, possivelmente, esconde um oceano de
                        água salgada!
                      </span>
                      <span>
                        {' '}
                        Os cientistas acreditam que esse oceano contém mais que
                        o dobro da água de todos os oceanos da Terra juntos!
                        Vocês conseguem imaginar?
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        A espaçonave será lançada em um foguete SpaceX Falcon
                        Heavy e chegará ao sistema de Júpiter em 2030.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 3/slide 2/pagina%203%20slide%202%20imagem%204-1200w.png"
                imageSrc1="/Pagina 3/slide 2/pagina%203%20slide%202%20mobile%20imagem%204-1500h.png"
                rootClassName="modelo-slideroot-class-name53"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag3-sanfona5-slider-slide5 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag3-sanfona5-text155 Titulo">
                      MMX: Exploração da Lua de Marte da JAXA
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag3-sanfona5-text156 Texto">
                      <span>
                        A
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text158">missão MMX</span>
                      <span>
                        , tem como destino as
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text160">
                        luas de Marte
                      </span>
                      <span>
                        ,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text162">
                        Fobos e Deimos
                      </span>
                      <span>
                        {' '}
                        (cujos nomes também são homenagens a deuses da
                        mitologia, sendo ambos filhos de Marte, deusa da guerra,
                        Fobos significa medo e Deimos significa terror).
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        O objetivo da missão é determinar a origem das luas de
                        Marte. Isso porque os cientistas não têm certeza se
                        Fobos e Deimos são
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text167">
                        antigos asteroides
                      </span>
                      <span>
                        {' '}
                        que Marte
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag3-sanfona5-text169">
                        capturou com sua gravidade ou se eles se formaram a
                        partir de detritos
                      </span>
                      <span> que já estavam em órbita em torno o planeta.</span>
                      <br></br>
                      <span>Ficaremos de olho!</span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 3/slide 2/pagina%203%20slide%202%20imagem%205-1200w.png"
                imageSrc1="/Pagina 3/slide 2/pagina%203%20slide%202%20mobile%20imagem%205-1500h.png"
                rootClassName="modelo-slideroot-class-name54"
              ></ModeloSlide>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="pag3-sanfona5-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="pag3-sanfona5-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="pag3-sanfona5-slider-button-next swiper-button-next"
          ></div>
        </div>
        <div className="pag3-sanfona5-container13">
          <div className="pag3-sanfona5-container14">
            <h1>
              {props.heading111 ?? (
                <Fragment>
                  <h1 className="pag3-sanfona5-text200 Subtitulo-A">
                    Ok, estamos no futuro, mas você já percebeu que os tempos se
                    comunicam, certo?
                  </h1>
                </Fragment>
              )}
            </h1>
            <span className="Texto">
              <span>
                Para trás, no que chamamos de tempo passado, também temos muito
                o que descobrir, e tudo o que sabemos hoje foi construído por
                uma
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona5-text176">
                multiplicidade de saberes acumulados desde o primeiro humano na
                Terra,
              </span>
              <span>
                {' '}
                surgido na África Oriental há cerca de 2,5 milhões de anos, no
                final do
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona5-text178">
                Plioceno, período que compreende de 5 a 2 milhões atrás.
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/Pagina 3/imagens/pagina%203%20imagem%207-1500w.webp"
            className="pag3-sanfona5-image1"
          />
        </div>
        <div className="pag3-sanfona5-container15">
          <div className="pag3-sanfona5-container16">
            <h1>
              {props.heading1111 ?? (
                <Fragment>
                  <h1 className="pag3-sanfona5-text202 Subtitulo-A">
                    <span className="pag3-sanfona5-text203">
                      Você notou alguma coincidência nos
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>nomes das missões</span>
                    <span className="pag3-sanfona5-text205"> da Nasa?</span>
                  </h1>
                </Fragment>
              )}
            </h1>
            <span className="Texto">
              <span className="pag3-sanfona5-text182">Ártemis</span>
              <span>
                {' '}
                era uma divindade da mitologia grega conhecida como
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona5-text184">
                deusa da caça, da natureza e da castidade, além de ser protetora
              </span>
              <span>
                {' '}
                das mulheres, das crianças e dos nascimentos. Era ainda a irmã
                gêmea de Apolo, deus grego do Sol, da profecia, da poesia, das
                artes, da música, da medicina e da justiça. E Apollo é o
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona5-text186">
                nome da missão que levou o homem à Lua no final da década de
                1960.
              </span>
              <br className="pag3-sanfona5-text187"></br>
              <br className="pag3-sanfona5-text188"></br>
              <span>
                É claro que você também já percebeu que os planetas do sistema
                solar são os deuses do Olimpo em sua nomenclatura romana:
                Mercúrio (Hermes), Vênus (Afrodite), Terra (Gaia), Marte (Ares),
                Júpiter (Zeus), Saturno (Cronos), Urano (Urano) e Netuno
                (Poseidon).
              </span>
            </span>
            <a
              href="https://canaltech.com.br/espaco/como-os-nomes-de-estrelas-sao-escolhidos-208385/"
              target="_blank"
              rel="noreferrer noopener"
              className="pag3-sanfona5-link2 Texto"
            >
              <span>
                Ufa! Você não imagina a trabalheira que foi, e que é até hoje,
                dar nome para as estrelas! Se tiver interesse no assunto,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona5-text191">
                dá uma olhada no site CanalTech
              </span>
              <span>.</span>
            </a>
          </div>
          <img
            alt="image"
            src="/Pagina 3/imagens/pagina%203%20imagem%208-1500w.png"
            className="pag3-sanfona5-image2"
          />
        </div>
        <div className="pag3-sanfona5-container17">
          <div className="pag3-sanfona5-container18 Radius">
            <div className="pag3-sanfona5-container19 Radius">
              <div className="pag3-sanfona5-container20">
                <h1 className="Titulo-Extra">
                  <span className="pag3-sanfona5-text194">
                    Só um spoiler pra quem é
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>fã de Harry Potter:</span>
                </h1>
                <span className="Texto">
                  <span className="pag3-sanfona5-text197">
                    Tem uma estrela que se chama Bellatrix.
                  </span>
                  <span>
                    {' '}
                    Inspiração para a franquia de Harry Potter, na qual a
                    personagem de mesmo nome é interpretada pela atriz Helena
                    Bonham Carter. Você sabia que Bellatrix é uma palavra de
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag3-sanfona5-text199">
                    origem latina, que significa “guerra feminina”?
                  </span>
                </span>
              </div>
            </div>
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%209-1500w.webp"
              className="pag3-sanfona5-image3 Radius"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Pag3Sanfona5.defaultProps = {
  textUrl:
    'https://www.gov.br/observatorio/pt-br/assuntos/noticias/confira-as-principais-missoes-espaciais-de-2024',
  heading111: undefined,
  text: undefined,
  heading1111: undefined,
  rootClassName: '',
}

Pag3Sanfona5.propTypes = {
  textUrl: PropTypes.string,
  heading111: PropTypes.element,
  text: PropTypes.element,
  heading1111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag3Sanfona5
