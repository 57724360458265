import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag1-sanfona6.css'

const Pag1Sanfona6 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag1-sanfona6-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona6-topo">
        <span className="pag1-sanfona6-text10 Titulo-Extra-B">
          Leonardo da Vinci
        </span>
        <div className="pag1-sanfona6-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona6-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona6-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag1-sanfona6-container2">
        <div
          data-thq="slider"
          data-navigation="false"
          data-pagination="true"
          className="pag1-sanfona6-slider swiper"
          style={isAtivo ? {} : { display: "none" }}
        >
          <div data-thq="slider-wrapper" className="swiper-wrapper">
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide1 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text11 Titulo">
                      Considerado um dos maiores símbolos renascentistas da
                      história
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text12 Texto">
                      <span>
                        O italiano
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text14">
                        Leonardo da Vinci (1452-1519)
                      </span>
                      <span>
                        {' '}
                        pintou as famosas obras “Mona Lisa” e “A Última Ceia”.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Mas na verdade, Da Vinci se aventurou em
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text19">
                        muitas outras áreas de conhecimento
                      </span>
                      <span>
                        , como desenho, escultura, arquitetura, engenharia,
                        ciência, música, matemática, química, física,
                        aeronáutica, literatura, astronomia, botânica, geologia,
                        história e cartografia.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text21">
                        Algumas de suas invenções eram muito avançadas para a
                        época! 
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%201-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%201-1500h.png"
                rootClassName="modelo-slideroot-class-name15"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide2 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text22 Titulo">
                      Escafandro
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text23 Texto">
                      <span>
                        Quando morava em Veneza, Da Vinci teve uma
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text25">
                        ideia para afastar os navios invasores
                      </span>
                      <span>
                        : enviar homens para o fundo do mar em roupas de
                        mergulho para sabotar as embarcações inimigas.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Naquele tempo, equipamento de mergulho para sabotagem
                        subaquática era inédito.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        A roupa dos mergulhadores seria de couro, com um tubo
                        preso na máscara facial, como um
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text31">snorkel</span>
                      <span>, que flutuava na superfície.</span>
                      <br></br>
                      <br></br>
                      <span>
                        O projeto não chegou a sair do papel na época, mas em
                        2003, o mergulhador
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text36">
                        Jacquie Cozens testou um traje baseado nesse projeto
                      </span>
                      <span>
                        , e confirmou que funcionava bem em águas rasas.
                      </span>
                      <br className="pag1-sanfona6-text38"></br>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%202-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%202-1500h.png"
                rootClassName="modelo-slideroot-class-name13"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide3 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text39 Titulo">
                      Máquinas voadoras
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text40 Texto">
                      <span>
                        O helicóptero desenhado por Da Vinci é de uma
                        criatividade impressionante para a época e inspirou a
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text42">
                        tecnologia contemporânea
                      </span>
                      <span>, que permitiu que o homem voasse.</span>
                      <br className="pag1-sanfona6-text44"></br>
                      <br className="pag1-sanfona6-text45"></br>
                      <span>
                        Muito antes de Santos Dumont (1873-1932) e dos irmãos
                        Wilbur (1867-1912) e Orville Wright (1871-1948), ele
                        criou este e outros equipamentos voadores:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text47">
                        um paraquedas e dois tipos diferentes de planadores.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%203-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%203-1500h.png"
                rootClassName="modelo-slideroot-class-name14"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide4 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text48 Titulo">
                      Energia solar
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text49 Texto">
                      <span>
                        Da Vinci foi também um
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text51">
                        precursor da ideia de utilizar a energia solar
                      </span>
                      <span>
                        {' '}
                        para o aquecimento. Ele projetou um mecanismo em que a
                        luz do sol seria concentrada por espelhos côncavos, de
                        modo a aquecer diretamente um reservatório de água.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        O italiano acreditava que seu invento, se concretizado,
                        iria diminuir a necessidade de corte de árvores,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text56">
                        em um tempo em que o aquecimento era baseado na queima
                        da lenha.
                      </span>
                      <span>
                        {' '}
                        Ou seja, ele foi um visionário do aquecimento global!
                        Não é genial?
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%204-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%204-1500h.png"
                rootClassName="modelo-slideroot-class-name16"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide5 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text58 Titulo">Robôs</span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text59 Texto">
                      <span>
                        Leonardo da Vinci concebeu
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text61">
                        máquinas movidas a corda
                      </span>
                      <span>
                        {' '}
                        que são consideradas as precursoras dos robôs.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Uma das mais incríveis era um leão mecânico que dava
                        vários passos e abria automaticamente o peito, exibindo
                        flores.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Os movimentos eram garantidos por engrenagens e a
                        sequência era dada mediante uma programação analógica.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%205-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%205-1500h.png"
                rootClassName="modelo-slideroot-class-name17"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide6 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text69 Titulo">
                      Anatomia do coração
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text70 Texto">
                      <span>
                        Os
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text72">
                        desenhos anatômicos
                      </span>
                      <span>
                        {' '}
                        de Leonardo da Vinci são pioneiros na demonstração da
                        estrutura e do funcionamento do coração humano.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Algumas das conclusões de Da Vinci foram tão avançadas
                        que, só hoje em dia, com o advento da tecnologia de
                        diagnósticos,
                      </span>
                      <br></br>
                      <span>
                        é possível interpretá-las. E, muitas vezes, suas
                        análises continuam a fazer sentido de acordo com o
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text79">
                        entendimento moderno de fisiologia e função cardíaca.
                      </span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%206-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%206-1500h.png"
                rootClassName="modelo-slideroot-class-name18"
              ></ModeloSlide>
            </div>
            <div
              data-thq="slider-slide"
              className="pag1-sanfona6-slider-slide7 swiper-slide"
            >
              <ModeloSlide
                icone="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20icone-1500h.png"
                titulo={
                  <Fragment>
                    <span className="pag1-sanfona6-text80 Titulo">
                      Automóvel autônomo
                    </span>
                  </Fragment>
                }
                conteudo={
                  <Fragment>
                    <span className="pag1-sanfona6-text81 Texto">
                      <span>
                        O carro movido por energia própria de Da Vinci pode ser
                        visto como o
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text83">
                        primeiro carro
                      </span>
                      <span>
                        {' '}
                        imaginado da história. Na verdade, como também não
                        incluía condutor, pode ser considerado o
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text85">
                        primeiro veículo robô inventado da história.
                      </span>
                      <br className="pag1-sanfona6-text86"></br>
                      <br></br>
                      <span>
                        Os desenhos que Da Vinci fez não revelam por completo o
                        mecanismo interno e os engenheiros modernos tiveram de
                        imaginar o que o faria funcionar. Muitos acreditam que a
                        ideia de Da Vinci baseava-se em um
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona6-text89">
                        mecanismo acionado por molas
                      </span>
                      <span>, semelhante ao de um relógio.</span>
                    </span>
                  </Fragment>
                }
                imageSrc="/Pagina 1/Sanfona 6/pagina%201%20sanfona%206%20imagem%207-1200w.png"
                imageSrc1="/Pagina 1/Mobile/mobile%20pagina%201%20slide%202%20imagem%207-1500h.png"
                rootClassName="modelo-slideroot-class-name12"
              ></ModeloSlide>
            </div>
          </div>
          <div
            data-thq="slider-pagination"
            className="pag1-sanfona6-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
          >
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
            <div
              data-thq="slider-pagination-bullet"
              className="swiper-pagination-bullet"
            ></div>
          </div>
          <div
            data-thq="slider-button-prev"
            className="pag1-sanfona6-slider-button-prev swiper-button-prev"
          ></div>
          <div
            data-thq="slider-button-next"
            className="pag1-sanfona6-slider-button-next swiper-button-next"
          ></div>
        </div>
      </div>
    </div>
  )
}

Pag1Sanfona6.defaultProps = {
  rootClassName: '',
}

Pag1Sanfona6.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pag1Sanfona6
