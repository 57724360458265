import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlideLink from './modelo-slide-link'
import ModeloSlide from './modelo-slide'
import './pag2-sanfona5.css'

const Pag2Sanfona5 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag2-sanfona5-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag2-sanfona5-topo">
        <span className="pag2-sanfona5-text10 Titulo-Extra-B">
          A robótica na sustentabilidade
        </span>
        <div className="pag2-sanfona5-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona5-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona5-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag2-sanfona5-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag2-sanfona5-container3">
          <div className="pag2-sanfona5-container4">
            <span>
              {props.text32 ?? (
                <Fragment>
                  <span className="pag2-sanfona5-text82 Texto">
                    <span>
                      A robótica desempenha um papel crucial na cadeia da
                      sustentabilidade,
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona5-text84">
                      impulsionando a eficiência e reduzindo o impacto ambiental
                      em várias indústrias
                    </span>
                    <span>
                      . Desde a automação de processos de produção até a gestão
                      inteligente de recursos naturais, os robôs oferecem
                      soluções inovadoras para os desafios enfrentados pela
                      sociedade contemporânea.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Eles ajudam a
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona5-text89">
                      otimizar o uso de energia, minimizar resíduos e melhorar a
                      reciclagem
                    </span>
                    <span>
                      , contribuindo para um futuro mais verde e sustentável.
                      São fundamentais na pesquisa e desenvolvimento de
                      tecnologias ambientalmente amigáveis, impulsionando a
                      inovação e promovendo práticas sustentáveis em todo o
                      mundo.
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <img
            alt={props.imageAlt2}
            src={props.imageSrc2}
            className="pag2-sanfona5-image"
          />
        </div>
        <div className="pag2-sanfona5-container5">
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag2-sanfona5-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide1 swiper-slide"
              >
                <ModeloSlideLink
                  link="https://marsemfim.com.br/mar-chileno-revela-mais-de-100-especies-novas-para-a-ciencia/"
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text12 Titulo">
                        Robôs submarinos
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text13 Texto">
                        <span>
                          Um dos mais recentes feitos de robôs em suas
                          explorações no fundo do mar foi a descoberta de 100
                          novas espécies marinhas nos montes submarinos do
                          Chile, em uma expedição liderada pelo Dr. Javier
                          Sellanes, da Universidad Católica del Norte.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          A equipe da expedição explorou a cordilheira Nazca e
                          Salas y Gómez, descobrindo uma variedade
                          impressionante de vida marinha, incluindo corais de
                          profundidade, esponjas e ouriços-do-mar.
                        </span>
                        <br></br>
                        <br></br>
                        <span className="pag2-sanfona5-text20">
                          Para saber mais sobre o assunto, dá uma olhada nessa
                          matéria.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%201-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%201-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name2"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text22 Titulo">
                        Robôs submarinos
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text23 Texto">
                        <span>
                          E tem mais: um robô cujo trabalho é
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text25">
                          coletar lixo dos rios
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>antes de entrar nos mares e oceanos!</span>
                        <br></br>
                        <span>
                          O lixo é distribuído uniformemente em vários
                          contêineres. Quando todos os recipientes estão
                          completos, o robô envia uma mensagem de texto para os
                          operadores locais de coleta de lixo. Uma bateria de
                          íons de lítio alimenta o dispositivo e pode coletar
                          mais de 50 mil quilos de resíduos por dia.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Estes robôs estão instalados nos rios em Tangerang
                          (Indonésia) e Klang (Malásia).
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text32">
                          Até 2025, o desenvolvedor planeja limpar mil rios, que
                          respondem por até 80% da poluição dos oceanos por
                          resíduos plásticos.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%202-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name36"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text33 Titulo">
                        Robôs na agricultura
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text34 Texto">
                        <span>
                          Caminhar pela área de robótica de uma feira
                          tecnológica focada no setor do agronegócio, como a
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text36">Agrishow</span>
                        <span>
                          , considerada a maior do Brasil, é uma experiência
                          futurista. Os robôs conseguem semear, monitorar,
                          remover ervas daninhas, verificar a existência de
                          pragas, alertar o produtor em caso de problemas na
                          lavoura e fazer a colheita no fim da safra.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Mas se trata de um trabalho de
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text41">
                          constante pesquisa e evolução
                        </span>
                        <span>
                          , para aprimorar cada vez mais. O
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text43">
                          drone agrícola
                        </span>
                        <span>
                          , por exemplo, utilizado em cidades como Campos Novos,
                          em Santa Catarina, fornece precisão e rapidez, com
                          mitigação de riscos de contaminação ambiental e
                          humana.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%203-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name37"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text45 Titulo">
                        Robôs colaborativos: Cobots
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text46 Texto">
                        <span>
                          Sabe aquela cena de “Tempos Modernos”, quando o
                          personagem de Charles Chaplin interage com as novas
                          máquinas trazidas pela Revolução Industrial? Pois é,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text48">
                          as máquinas chegaram ainda mais além.
                        </span>
                        <span>
                          {' '}
                          Mas tudo indica que vieram para colaborar! O método
                          inclui
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text50">
                          robôs programados para interagir com os humanos no
                          ambiente de trabalho, são “auxiliares” chamados
                          de Cobots (colaboração + robótica).
                        </span>
                        <br className="pag2-sanfona5-text51"></br>
                        <br></br>
                        <span>
                          Além de muitas outras vantagens,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text54">
                          os cobots atuam sobre a saúde do trabalhador
                        </span>
                        <span>
                          , pois assumem as tarefas mais repetitivas ou
                          insalubres de um processo produtivo, o que reduz a
                          taxa de incidência de lesão ou acidentes de trabalho.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%204-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name38"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text56 Titulo">
                        Alexa nas tarefas domésticas
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text57 Texto">
                        <span>
                          Você já conhece a
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text59">Alexa</span>
                        <span>
                          ? Ela é uma
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text61">
                          assistente virtual
                        </span>
                        <span>
                          {' '}
                          da Amazon. E também oferece vantagens para a
                          sustentabilidade! Ao facilitar o controle inteligente
                          de dispositivos domésticos, como luzes e termostatos,
                          ela permite uma gestão mais eficiente de energia,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text63">
                          ajudando a reduzir o consumo desnecessário e os custos
                        </span>
                        <span> associados.</span>
                        <br></br>
                        <br></br>
                        <span>
                          Ela ainda fornece informações sobre o clima e o
                          tráfego, auxiliando na tomada de decisões que podem
                          contribuir para a diminuição das emissões de carbono,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text68">
                          incentivando o uso de meios de transporte mais
                          sustentáveis
                        </span>
                        <span>
                          . Depende apenas de você fazer um uso consciente dessa
                          auxiliar prestativa e eficiente!
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%205-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name39"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona5-slider-slide6 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 3/pagina%202%20slide%203%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona5-text70 Titulo">
                        Robôs no lixo
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona5-text71 Texto">
                        <span>
                          Por meio de sistemas automatizados e inteligentes,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text73">
                          os robôs podem classificar diferentes tipos de
                          resíduos
                        </span>
                        <span>
                          , como plástico, papel, metal e vidro, aumentando a
                          taxa de reciclagem e reduzindo a quantidade de
                          resíduos enviados para aterros sanitários.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Os
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text78">
                          robôs são capazes de operar em ambientes perigosos ou
                          insalubres, onde a intervenção humana seria arriscada
                        </span>
                        <span>
                          , garantindo assim um tratamento seguro e eficaz do
                          lixo. Com precisão e velocidade, contribui para uma
                          gestão mais sustentável,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona5-text80">
                          promovendo a economia circular e ajudando a proteger o
                          meio ambiente
                        </span>
                        <span> para as gerações futuras.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 3/pagina%202%20slide%203%20imagem%206-1200w.png"
                  imageSrc1="/Pagina 2/Slide 3/pagina%202%20mobile%20slide%203%20imagem%206-1500h.png"
                  rootClassName="modelo-slideroot-class-name40"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag2-sanfona5-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag2-sanfona5-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag2-sanfona5-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag2Sanfona5.defaultProps = {
  text32: undefined,
  rootClassName: '',
  imageAlt2: 'image',
  imageSrc2: '/Pagina 2/Imagens/pagina%202%20imagem%208-1500w.png',
}

Pag2Sanfona5.propTypes = {
  text32: PropTypes.element,
  rootClassName: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageSrc2: PropTypes.string,
}

export default Pag2Sanfona5
