import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <div id="navbar" className={`navbar-navbar ${props.rootClassName} `}>
      <div className="navbar-container1">
        <div className="navbar-container2">
          <img
            alt={props.vectorAlt1}
            src={props.vectorSrc1}
            className="navbar-vector1"
          />
          <Link to="/" className="navbar-navlink">
            <img
              alt={props.vectorAlt2}
              src={props.vectorSrc2}
              className="navbar-vector2"
            />
          </Link>
        </div>
        <div className="navbar-container3">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="navbar-image"
          />
          <img
            alt={props.vectorAlt}
            src={props.vectorSrc}
            className="navbar-vector3"
          />
        </div>
      </div>
    </div>
  )
}

Navbar.defaultProps = {
  vectorAlt: 'Vector9162',
  vectorSrc1: '/external/vector6161-5zuf.svg',
  vectorAlt1: 'Vector6161',
  vectorAlt2: 'Vector9163',
  vectorSrc2: '/external/vector9163-i6fw.svg',
  imageSrc: '/Geral/logo%20mirt%20mobile%20dark-200h.png',
  rootClassName: '',
  imageAlt: 'image',
  vectorSrc: '/external/vector9162-jefm.svg',
}

Navbar.propTypes = {
  vectorAlt: PropTypes.string,
  vectorSrc1: PropTypes.string,
  vectorAlt1: PropTypes.string,
  vectorAlt2: PropTypes.string,
  vectorSrc2: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  vectorSrc: PropTypes.string,
}

export default Navbar
