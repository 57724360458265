import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import './formulario.css'

const Formulario = (props) => {
  return (
    <div className="formulario-container1">
      <Helmet>
        <title>Formulario - MIRT</title>
        <meta property="og:title" content="Formulario - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <div className="formulario-seo1">
        <div className="formulario-container2">
          <div className="formulario-container3">
            <span className="formulario-text1 Titulo-Banner">
              <span>Quero a MIRT</span>
              <br></br>
              <span>pertinho de mim</span>
            </span>
            <span className="formulario-text5 Texto">
              <span>
                Leve a
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="formulario-text7">
                MIRT para a sua escola, evento ou qualquer atividade com
                tecnologia.
              </span>
              <span>
                {' '}
                Preencha o formulário para solicitar uma visita à MIRT.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <form
              action="https://script.google.com/macros/s/AKfycbyBwIhfD7uMM3JIdEJ6uN9-wNIiC_YZJOAwjfioijAzswl0Z9GCHkhRF-e7vnrcGZZ0/exec"
              method="POST"
              className="formulario-form BoxShadow"
            >
              <select name="atividade" className="formulario-select1 Texto">
                <option value="Option 1">Atividade</option>
              </select>
              <textarea
                name="descricao_atividade"
                placeholder="Descrição da atividade"
                className="formulario-textarea Texto"
              ></textarea>
              <div className="formulario-container4">
                <input
                  type="text"
                  name="cidade"
                  placeholder="Cidade"
                  className="formulario-textinput10 Texto"
                />
                <select name="uf" className="formulario-select2 Texto">
                  <option>UF</option>
                </select>
              </div>
              <input
                type="date"
                name="data"
                placeholder="Data"
                className="formulario-textinput11 Texto"
              />
              <input
                type="text"
                name="nome"
                placeholder="Nome completo"
                className="formulario-textinput12 Texto"
              />
              <input
                type="text"
                name="email"
                placeholder="E-mail"
                className="formulario-textinput13 Texto"
              />
              <input
                type="tel"
                name="telefone"
                placeholder="Telefone"
                className="formulario-textinput14 Texto"
              />
              <input
                type="text"
                name="nome_instituicao"
                placeholder="Nome da instituição"
                className="formulario-textinput15 Texto"
              />
              <input
                type="text"
                name="tipo_institucao"
                placeholder="Tipo de instituição"
                className="formulario-textinput16 Texto"
              />
              <input
                type="text"
                name="leciona"
                placeholder="O que você leciona?"
                className="formulario-textinput17 Texto"
              />
              <input
                type="text"
                name="adicional"
                placeholder="Informações adicionais"
                className="formulario-textinput18 Texto"
              />
              <input
                type="text"
                name="redirect"
                value="https://una-mirt.teleporthq.app"
                placeholder="redirect"
                className="formulario-textinput19 Texto"
              />
              <div className="formulario-container5">
                <div className="formulario-container6">
                  <input
                    type="checkbox"
                    name="aceite_termos"
                    className="formulario-checkbox"
                  />
                </div>
                <span className="formulario-text9 Observacao">
                  Aceito receber novos conteúdos da MIRT e do Sesc Santa
                  Catarina e estou de acordo com os termos de uso dos meus
                  dados.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
              <button type="submit" className="formulario-button Botao">
                Enviar solicitação
              </button>
            </form>
          </div>
          <img
            alt="image"
            src="/Pagina Formulario/submarino%20mobile-500h.png"
            className="formulario-image FloatAnimation"
          />
        </div>
      </div>
    </div>
  )
}

export default Formulario
