import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import ModeloSlideLink from './modelo-slide-link'
import './pag2-sanfona1.css'

const Pag2Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag2-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag2-sanfona1-topo">
        <span className="pag2-sanfona1-text100 Titulo-Extra-B">
          Galápagos e as suas tartarugas gigantes
        </span>
        <div className="pag2-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag2-sanfona1-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag2-sanfona1-container3">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="pag2-sanfona1-image1"
          />
          <div className="pag2-sanfona1-container4">
            <a
              href="https://galapagos.gob.ec/parque-nacional-galapagos/"
              target="_blank"
              rel="noreferrer noopener"
              className="pag2-sanfona1-link1 Texto"
            >
              <span>
                Os estudos genéticos nas
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag2-sanfona1-text102">
                ilhas de origem vulcânica do arquipélago de Galápagos,
                no Equador, sobre as tartarugas gigantes
              </span>
              <span>
                {' '}
                - que ficaram famosas por terem
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag2-sanfona1-text104">
                inspirado a teoria da evolução de Charles Darwin
              </span>
              <span>
                {' '}
                - começaram em 1995. Em seguida, o
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag2-sanfona1-text106">
                Parque Nacional Galápagos
              </span>
              <span> realizou a investigação sobre as espécies extintas.</span>
              <br></br>
              <br></br>
              <span>
                Os cientistas acreditam que a linhagem que antes se pensava
                corresponder às tartarugas de San Cristóbal pode pertencer a uma
                espécie extinta que coabitava com a sobrevivente.
              </span>
            </a>
          </div>
        </div>
        <div className="pag2-sanfona1-container5">
          <div className="pag2-sanfona1-container6">
            <div className="pag2-sanfona1-container7">
              <h1>
                {props.heading ?? (
                  <Fragment>
                    <h1 className="pag2-sanfona1-text202 Titulo-Extra">
                      Mas a história não para por aí
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span className="pag2-sanfona1-text113">
                  Quando uma pequena tartaruga fêmea foi vista em 2019 na ilha
                  vulcânica isolada de Fernandina
                </span>
                <span>
                  , a equipe de pesquisa que fazia a expedição ficou chocada.
                </span>
                <br className="pag2-sanfona1-text115"></br>
                <br className="pag2-sanfona1-text116"></br>
                <span>
                  Anteriormente, apenas
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag2-sanfona1-text118">
                  uma outra tartaruga,
                </span>
                <br className="pag2-sanfona1-text119"></br>
                <span className="pag2-sanfona1-text120">
                  um grande macho solitário, havia sido encontrada lá, em 1906,
                  pelo explorador Rollo Beck. 
                </span>
                <span>
                  Os cientistas pensaram que este animal era o último da espécie
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag2-sanfona1-text122">
                  “tartaruga gigante”
                </span>
                <span>, ou Chelonoidis phantasticus,</span>
                <br className="pag2-sanfona1-text124"></br>
                <span>o que tornaria a espécie extinta.</span>
                <br className="pag2-sanfona1-text126"></br>
                <br className="pag2-sanfona1-text127"></br>
                <span>
                  Então
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag2-sanfona1-text129">
                  encontraram Fernanda
                </span>
                <span>
                  . O DNA de Fernanda contou uma história surpreendente, muito
                </span>
                <br className="pag2-sanfona1-text131"></br>
                <span>parecida com a descoberta da própria tartaruga.</span>
                <br className="pag2-sanfona1-text133"></br>
                <br className="pag2-sanfona1-text134"></br>
                <span>
                  Saiba mais sobre a tartaruga Fernanda e sobre os motivos que
                  estão levando as tartarugas de Galápagos à extinção.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
              <div className="pag2-sanfona1-container8">
                <span className="pag2-sanfona1-text136 Texto">
                  <span>L</span>
                  <span>eia sobre em:</span>
                </span>
                <a
                  href="https://www.cnnbrasil.com.br/tecnologia/cientistas-descobrem-femea-de-tartaruga-que-acreditava-se-estar-extinta/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag2-sanfona1-link2 Texto"
                >
                  CNN Brasil
                </a>
                <span className="pag2-sanfona1-text139 Texto">e</span>
                <a
                  href="https://www.bbc.com/portuguese/geral-62721315"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag2-sanfona1-link3 Texto"
                >
                  BBC News Brasil.
                </a>
              </div>
            </div>
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="pag2-sanfona1-image2"
            />
          </div>
        </div>
        <div className="pag2-sanfona1-container9">
          <span className="pag2-sanfona1-text140 Titulo-Extra-B">
            Você sabe onde fica Galápagos?
          </span>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag2-sanfona1-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag2-sanfona1-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 1/pagina%202%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona1-text141 Titulo">
                        <span>Ilhas de Galápagos</span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona1-text144 Texto">
                        <span>
                          Ilhas de Galápagos, também conhecidas como
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text146">
                          Arquipélago de Colombo, são um conjunto de ilhas
                          vulcânicas
                        </span>
                        <span>
                          {' '}
                          localizadas no Oceano Pacífico e situadas a,
                          aproximadamente, 1.000km da costa do Equador.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 1/pagina%202%20slide%201%20image%201-1200w.png"
                  imageSrc1="/Pagina 2/Slide 1/pagina%202%20mobile%20slide%201%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name88"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona1-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 1/pagina%202%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona1-text149 Titulo">
                        Curiosidades sobre as Ilhas Galápagos:
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona1-text150 Texto">
                        <span>
                          Lá, foram
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text152">
                          registradas 60 erupções vulcânicas
                        </span>
                        <span> desde a visita de Charles Darwin em 1835.</span>
                        <br></br>
                        <br className="pag2-sanfona1-text155"></br>
                        <span className="pag2-sanfona1-text156">
                          O arquipélago está sempre em movimento!
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          Localizado no extremo norte da Placa de Nazca, o
                          arquipélago se move constantemente para o leste.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 1/pagina%202%20slide%201%20image%202-1200w.png"
                  imageSrc1="/Pagina 2/Slide 1/pagina%202%20mobile%20slide%201%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name89"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona1-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 1/pagina%202%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona1-text159 Titulo">
                        Curiosidades sobre as Ilhas Galápagos:
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona1-text160 Texto">
                        <span>
                          O nome Galápagos é uma palavra antiga que
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text162">
                          significa, em espanhol, “silla de montar”,
                        </span>
                        <span>
                          {' '}
                          um objeto
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text164">
                          que faz lembrar a casca dura das tartarugas.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Enquanto as
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text168">
                          ilhas mais antigas e pesadas
                        </span>
                        <span>
                          {' '}
                          do arquipélago estão
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text170">
                          afundando lentamente
                        </span>
                        <span>
                          {' '}
                          no Oceano Pacífico,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text172">
                          as mais recentes estão subindo
                        </span>
                        <span>.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 1/pagina%202%20slide%201%20image%203-1200w.png"
                  imageSrc1="/Pagina 2/Slide 1/pagina%202%20mobile%20slide%201%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name90"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona1-slider-slide4 swiper-slide"
              >
                <ModeloSlideLink
                  link="https://www.iucn.org/pt/content/salvar-a-rede-da-vida"
                  icone="/Pagina 2/Slide 1/pagina%202%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona1-text174 Titulo">
                        Curiosidades sobre as Ilhas Galápagos:
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona1-text175 Texto">
                        <span>
                          As tartarugas de Galápagos não podem nadar, então como
                          elas chegaram às ilhas?
                        </span>
                        <br></br>
                        <br></br>
                        <span className="pag2-sanfona1-text179">
                          Acontece que elas podem flutuar, o que significa que
                          furacões e tempestades podem transportá-las.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span>
                          Cerca de dois milhões ou três milhões de anos atrás,
                          uma tempestade carregou tartarugas gigantes para o
                          oeste da América do Sul.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          As tartarugas cruzaram entre si nas ilhas onde
                          desembarcaram, o que fez com que evoluíssem
                          rapidamente. As
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text184">
                          14 espécies que resultaram dessa cruza
                        </span>
                        <span>
                          {' '}
                          estão todas na
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona1-text186">
                          Lista Vermelha da União Internacional para a
                          Conservação da Natureza
                        </span>
                        <span>, variando de vulneráveis ​​a extintas.</span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 1/pagina%202%20slide%201%20image%204-1200w.png"
                  imageSrc1="/Pagina 2/Slide 1/pagina%202%20mobile%20slide%201%20imagem%204-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name20"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona1-slider-slide5 swiper-slide"
              >
                <ModeloSlideLink
                  link="https://www.youtube.com/watch?v=B3OjfK0t1XM"
                  icone="/Pagina 2/Slide 1/pagina%202%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona1-text189 Titulo">
                        Curiosidades sobre as Ilhas Galápagos:
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona1-text190 Texto">
                        <span>
                          Lá você encontrará também iguanas, flamingos,
                          albatrozes, atobás e pinguins.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Mas essas são apenas algumas das curiosidades de
                          Galápagos!
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Ah e lembra de um vídeo que viralizou, de serpentes
                          perseguindo uma iguana?
                        </span>
                        <br></br>
                        <br></br>
                        <span className="pag2-sanfona1-text200">
                          Foi lá na Ilha Fernandina, confere aqui.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 1/pagina%202%20slide%201%20image%205-1200w.png"
                  imageSrc1="/Pagina 2/Slide 1/pagina%202%20mobile%20slide%201%20imagem%205-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name21"
                ></ModeloSlideLink>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag2-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag2-sanfona1-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag2-sanfona1-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag2Sanfona1.defaultProps = {
  rootClassName: '',
  imageSrc: '/Pagina 2/Imagens/pagina%202%20imagem%202-1500w.png',
  imageAlt3: 'image',
  heading: undefined,
  imageAlt: 'image',
  imageSrc3: '/Pagina 2/Imagens/pagina%202%20imagem%203-1500w.png',
}

Pag2Sanfona1.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt3: PropTypes.string,
  heading: PropTypes.element,
  imageAlt: PropTypes.string,
  imageSrc3: PropTypes.string,
}

export default Pag2Sanfona1
