import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlideLink from './modelo-slide-link'
import './pag5-sanfona1.css'

const Pag5Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag5-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag5-sanfona1-topo">
        <span className="pag5-sanfona1-text10 Titulo-Extra-B">
          Robótica educacional
        </span>
        <div className="pag5-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag5-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag5-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag5-sanfona1-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag5-sanfona1-container3 DefaultInnerPadding">
          <div className="pag5-sanfona1-container4">
            <h1>
              {props.heading111 ?? (
                <Fragment>
                  <h1 className="pag5-sanfona1-text98 Subtitulo-A">
                    <span className="pag5-sanfona1-text99">
                      O
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      termo gamificação
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag5-sanfona1-text101">
                      se origina da expressão em inglês gamification
                    </span>
                  </h1>
                </Fragment>
              )}
            </h1>
            <span className="Texto">
              <span>
                Essa
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag5-sanfona1-text14">
                estratégia de aprendizagem propõe o uso de elementos de jogos
              </span>
              <span>
                {' '}
                como: progresso, pontuação, avatares, desafios e rankings em
                contextos escolares.
              </span>
              <br></br>
              <br></br>
              <span>
                A principal diferença entre
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag5-sanfona1-text19">game e gamificação</span>
              <span>
                {' '}
                é a finalidade de cada um. Enquanto os jogos demandam o
                cumprimento de objetivos em um mundo virtual (sem interferir
                diretamente no mundo real), a gamificação é criada para
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag5-sanfona1-text21">
                aumentar o engajamento dos participantes
              </span>
              <span> em tarefas reais, como estudar, por exemplo.</span>
              <br></br>
              <br></br>
              <span>
                Enquanto o game tem seus objetivos limitados ao próprio jogo, de
                acordo com as suas regras,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag5-sanfona1-text26">
                a gamificação tem como finalidade o uso do conhecimento
              </span>
              <span> no dia a dia.</span>
              <br></br>
              <span>
                Ou seja, os participantes saem do jogo com informações que são
                necessárias para o desafio que vem em seguida.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/Pagina 5/images/pagina%205%20imagem%202-1500w.png"
            className="pag5-sanfona1-image1 Radius"
          />
        </div>
        <div className="pag5-sanfona1-container5">
          <h1>
            {props.heading1111 ?? (
              <Fragment>
                <h1 className="pag5-sanfona1-text82 DefaultInnerPadding Subtitulo-A">
                  <span className="pag5-sanfona1-text83">
                    Aí vão algumas das
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>ferramentas de gamificação:</span>
                </h1>
              </Fragment>
            )}
          </h1>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag5-sanfona1-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide1 swiper-slide"
              >
                <ModeloSlideLink
                  link="https://kahoot.com/pt/"
                  icone="/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona1-text31 Titulo">
                        Kahoot
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona1-text32 Texto">
                        <span className="pag5-sanfona1-text33">
                          Aplicativo de perguntas e repostas com sistema de
                          pontuação.
                        </span>
                        <span>
                          {' '}
                          Professores podem criar perguntas específicas sobre um
                          assunto para compartilhar com os estudantes e aplicar
                          nas aulas.
                        </span>
                        <br className="pag5-sanfona1-text35"></br>
                        <br className="pag5-sanfona1-text36"></br>
                        <span>Quer entender melhor sobre o aplicativo? </span>
                        <span className="pag5-sanfona1-text38">
                          Dá uma olhada no site da Kahoot.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%201-1500h.png"
                  imageSrc1="/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name3"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide2 swiper-slide"
              >
                <ModeloSlideLink
                  icone="/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona1-text39 Titulo">
                        Matific
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona1-text40 Texto">
                        <span>
                          É uma ferramenta que contempla
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona1-text42">
                          atividades de matemática
                        </span>
                        <span>
                          {' '}
                          com elementos de gamificação focada na solução de
                          problemas e formação de pensamento crítico.
                        </span>
                        <br></br>
                        <br></br>
                        <span>Quer conhecer melhor sobre a ferramenta? </span>
                        <span className="pag5-sanfona1-text47">
                          Dá uma olhada no site da Matific.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%202-1500h.png"
                  imageSrc1="/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name4"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide3 swiper-slide"
              >
                <ModeloSlideLink
                  icone="/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona1-text48 Titulo">
                        Minecraft for Education
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona1-text49 Texto">
                        <span>
                          É uma ótima ferramenta para oferecer
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona1-text51">
                          aulas mais lúdicas
                        </span>
                        <span>, estimulando a criatividade.</span>
                        <br></br>
                        <span className="pag5-sanfona1-text54">
                          Conteúdos sugeridos
                        </span>
                        <span>
                          : planejamento urbano, mudanças climáticas,
                          biodiversidade, períodos da história humana,
                          geometria, perímetro e outros.
                        </span>
                        <br></br>
                        <br></br>
                        <span>Quer saber mais sobre essa ferramenta? </span>
                        <span className="pag5-sanfona1-text59">
                          Dá uma olhada no site Minecraft for Education.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%203-1500h.png"
                  imageSrc1="/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name5"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide4 swiper-slide"
              >
                <ModeloSlideLink
                  icone="/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona1-text60 Titulo">
                        Enem Game
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona1-text61 Texto">
                        <span>
                          É uma ferramenta gratuita que apresenta uma
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona1-text63">
                          forma divertida de estudo
                        </span>
                        <span>
                          {' '}
                          e treino para a realização do Enem. A plataforma usa
                          elementos de gamificação para incentivar a resolução
                          de exercícios.
                        </span>
                        <br></br>
                        <br></br>
                        <span>Quer entender mais sobre essa ferramenta?</span>
                        <span className="pag5-sanfona1-text68"> </span>
                        <span className="pag5-sanfona1-text69">
                          Dá uma olhada no site Enem Game.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%204-1500h.png"
                  imageSrc1="/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%204-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name6"
                ></ModeloSlideLink>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide5 swiper-slide"
              >
                <div className="pag5-sanfona1-container6">
                  <div className="pag5-sanfona1-interno">
                    <img
                      alt={props.imageAlt}
                      src={props.imageSrc}
                      className="pag5-sanfona1-image-desktop HideOnMobile"
                    />
                    <img
                      alt={props.imageAlt1}
                      src={props.imageSrc1}
                      imageSrc1={props.imageImageSrc1}
                      className="pag5-sanfona1-image-mobile ShowOnMobile"
                    />
                    <div className="pag5-sanfona1-frame1000003600">
                      <img
                        alt={props.iconeAlt}
                        src={props.icone}
                        className="pag5-sanfona1-image2"
                      />
                      <span className="pag5-sanfona1-text70 Titulo">
                        {props.titulo ?? (
                          <Fragment>
                            <span className="pag5-sanfona1-text97 Titulo">
                              Scratch
                            </span>
                          </Fragment>
                        )}
                      </span>
                      <a
                        href="https://scratch.mit.edu/"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="pag5-sanfona1-text71"
                      >
                        {props.conteudo ?? (
                          <Fragment>
                            <span className="pag5-sanfona1-text85 Texto">
                              <span>
                                É possível
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: ' ',
                                  }}
                                />
                              </span>
                              <span className="pag5-sanfona1-text87">
                                desenvolver os próprios
                              </span>
                              <span>
                                {' '}
                                jogos nesse aplicativo, é uma excelente forma de
                                aprender os primeiros passos de linguagem de
                                programação para os estudantes.
                              </span>
                              <br className="pag5-sanfona1-text89"></br>
                              <br className="pag5-sanfona1-text90"></br>
                              <span>
                                Quer saber mais sobre esse aplicativo? 
                              </span>
                              <span className="pag5-sanfona1-text92">
                                Dá uma olhada no site da Scratch.
                              </span>
                            </span>
                          </Fragment>
                        )}
                      </a>
                      <a
                        href="https://www.youtube.com/watch?v=ixmMxZVfJbU"
                        target="_blank"
                        rel="noreferrer noopener"
                        className="pag5-sanfona1-text72"
                      >
                        {props.conteudo1 ?? (
                          <Fragment>
                            <span className="pag5-sanfona1-text93 Texto">
                              <span>
                                E olha que legal o
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: ' ',
                                  }}
                                />
                              </span>
                              <span className="pag5-sanfona1-text95">
                                gameplay Naruto
                              </span>
                              <span>
                                {' '}
                                desenvolvido pelo canal Gamescrever através do
                                Scratch.
                              </span>
                            </span>
                          </Fragment>
                        )}
                      </a>
                      <div className="pag5-sanfona1-indicaes">
                        <img
                          alt="DJCTQL16141"
                          src="/external/livre-200w-200w.png"
                          className="pag5-sanfona1djctql1"
                        />
                        <img
                          alt="Vector9161"
                          src="/external/sinais-200w-200w.png"
                          className="pag5-sanfona1-vector1"
                        />
                        <img
                          alt="Vector9161"
                          src="/external/ingles-200w-200w.png"
                          className="pag5-sanfona1-vector2"
                        />
                        <img
                          alt="Vector9161"
                          src="/external/descricao-200w-200w.png"
                          className="pag5-sanfona1-vector3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="pag5-sanfona1-slider-slide6 swiper-slide"
              >
                <ModeloSlideLink
                  link="https://quizizz.com/admin"
                  icone="/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag5-sanfona1-text73 Titulo">
                        Quizziz
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag5-sanfona1-text74 Texto">
                        <span>
                          Nessa plataforma os alunos precisam
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag5-sanfona1-text76">
                          responder o mais rápido possível
                        </span>
                        <span>
                          {' '}
                          para ganhar mais pontos, e podem usar power-ups que o
                          próprio jogo disponibiliza para que a partida fique
                          balanceada.
                        </span>
                        <br></br>
                        <br></br>
                        <span>Quer conhecer mais sobre essa plataforma? </span>
                        <span className="pag5-sanfona1-text81">
                          Dá uma olhada no site Quizziz.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%206-1500h.png"
                  imageSrc1="/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%206-1500h.png"
                  rootClassName="modelo-slide-linkroot-class-name8"
                ></ModeloSlideLink>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag5-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag5-sanfona1-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag5-sanfona1-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag5Sanfona1.defaultProps = {
  imageAlt1: 'image',
  imageAlt: 'image',
  icone: '/Pagina 5/images/pagina%205%20slide%201%20icon-1500h.png',
  imageSrc1:
    '/Pagina 5/slide 1/pagina%205%20slide%201%20mobile%20imagem%205-900h.png',
  heading1111: undefined,
  conteudo: undefined,
  iconeAlt: 'icone',
  imageSrc: '/Pagina 5/slide 1/pagina%205%20slide%201%20imagem%205-1200w.png',
  imageImageSrc1: '',
  conteudo1: undefined,
  rootClassName1: '',
  titulo: undefined,
  heading111: undefined,
  rootClassName: '',
}

Pag5Sanfona1.propTypes = {
  imageAlt1: PropTypes.string,
  imageAlt: PropTypes.string,
  icone: PropTypes.string,
  imageSrc1: PropTypes.string,
  heading1111: PropTypes.element,
  conteudo: PropTypes.element,
  iconeAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  imageImageSrc1: PropTypes.string,
  conteudo1: PropTypes.element,
  rootClassName1: PropTypes.string,
  titulo: PropTypes.element,
  heading111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag5Sanfona1
