import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Pag2Sanfona1 from '../components/pag2-sanfona1'
import Pag2Sanfona3 from '../components/pag2-sanfona3'
import Pag2Sanfona4 from '../components/pag2-sanfona4'
import Pag2Sanfona5 from '../components/pag2-sanfona5'
import Pag2Sanfona6 from '../components/pag2-sanfona6'
import SeoFinal from '../components/seo-final'
import './pagina-2.css'

const Pagina2 = (props) => {
  return (
    <div className="pagina2-container1">
      <Helmet>
        <title>Pagina-2 - MIRT</title>
        <meta property="og:title" content="Pagina-2 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name2"></Navbar>
      <div className="pagina2-hero DefaultPadding">
        <img
          alt="image"
          src="/Geral/fundo%20topo%20pagina-700h.png"
          className="pagina2-image1"
        />
        <div className="pagina2-container2 DefaultInnerPadding">
          <div className="pagina2-container3">
            <span className="pagina2-text10">02</span>
            <div className="pagina2-container4">
              <span className="pagina2-text11 Subtitulo-A">
                <span>Salve a Robótica:</span>
                <br className="pagina2-text13"></br>
                <span>Ferramentas para um futuro sustentável</span>
              </span>
              <div className="pagina2-container5">
                <div className="pagina2-boto">
                  <span className="pagina2-text15 Botao">Versão acessível</span>
                </div>
                <div className="pagina2-indicaes">
                  <img
                    alt="Vector9161"
                    src="/Geral/sinais-200w.png"
                    className="pagina2-vector1"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/ingles-200w.png"
                    className="pagina2-vector2"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/descricao-200w.png"
                    className="pagina2-vector3"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="/Pagina 2/Imagens/pagina%202%20imagem%201-1200w.png"
            className="pagina2-image2"
          />
          <div className="pagina2-container6">
            <img
              alt="Vector1068"
              src="/external/vector1068-4e2.svg"
              className="pagina2-vector4"
            />
            <span className="Observacao">
              <span className="pagina2-text17">Vá além e aprenda mais</span>
              <span> a partir dos</span>
              <br></br>
              <span>conteúdos que você assistiu no vídeo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="pagina2-container7">
        <Pag2Sanfona1
          heading={
            <Fragment>
              <span className="pagina2-text21 Titulo-Extra">
                Mas a história não para por aí
              </span>
            </Fragment>
          }
          rootClassName="pag2-sanfona1root-class-name"
        ></Pag2Sanfona1>
        <Pag2Sanfona3
          text1={
            <Fragment>
              <span className="pagina2-text22 Subtitulo-A">
                <span>
                  Mas não é só na Dinamarca e nos EUA que temos tecnologia
                  sustentável de ponta!
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text24">
                  Aqui no Brasil, tem também.
                </span>
              </span>
            </Fragment>
          }
          text3={
            <Fragment>
              <span className="pagina2-text25 Texto">
                <span>
                  Bom, ao contrário de outras formas de obtenção de energia
                  elétrica,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text27">
                  os geradores eólicos causam poucos impactos ao meio ambiente,
                  pois não poluem a atmosfera com gases causadores do efeito
                  estufa.
                </span>
                <span>
                  {' '}
                  Seus impactos ambientais se resumem à poluição sonora e
                  visual. E sim, sobre esses efeitos, há muito ainda a ser
                  estudado e aprimorado. Estamos
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text29">
                  caminhando para um futuro em que tecnologia e natureza se
                  complementem e construam
                </span>
                <span> um tempo de cocriação possível.</span>
                <br></br>
                <br></br>
                <span>
                  Você pode começar por conhecer mais de perto as ODS.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text34">
                  Que tal dar uma olhada no site da ODS Brasil?
                </span>
              </span>
            </Fragment>
          }
          text11={
            <Fragment>
              <span className="pagina2-text35 Texto">
                <span>
                  Santa Catarina possui três parques eólicos operando em seu
                  território:
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text37">
                  o parque eólico de Bom Jardim da Serra, instalado na cidade de
                  Bom Jardim da Serra, e os de Água Doce e Horizonte
                </span>
                <span>
                  , que possuem 15 e 18 aerogeradores respectivamente, ambos
                  instalados na cidade de Água Doce.
                </span>
              </span>
            </Fragment>
          }
          text31={
            <Fragment>
              <span className="pagina2-text39 Texto">
                <span>
                  Um dos meios de se obter energia elétrica consiste no
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text41">
                  aproveitamento da energia cinética das partículas de ar em
                  movimento. Ou seja, do vento!
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Essa energia é conhecida como
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text43">Energia Eólica</span>
                <span>
                  . Hoje existem cerca de 30 mil turbinas dessa natureza
                  espalhadas pelo mundo, com uma capacidade de 13000 MW de
                  produção! É muito, gente!
                </span>
                <br></br>
                <br></br>
                <span className="pagina2-text47">
                  A primeira turbina eólica comercial ligada à rede elétrica
                  pública foi instalada em 1976, na Dinamarca.
                </span>
                <span>
                  {' '}
                  Atualmente, existem mais de 30 mil turbinas eólicas em
                  operação no mundo.
                </span>
                <br></br>
                <br></br>
                <span>
                  Um sistema de geração de energia elétrica baseado no
                  aproveitamento de energia eólica funciona basicamente da
                  seguinte forma: as partículas do ar em movimento possuem
                  energia cinética. Ao colidirem com as hélices de uma turbina,
                  elas provocam a rotação de um eixo acoplado a um gerador
                  elétrico. O gerador converte o trabalho mecânico, relacionado
                  à rotação do eixo, em trabalho elétrico,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text52">
                  utilizado para movimentar as cargas negativas em um condutor
                  elétrico, o que produz corrente elétrica.
                </span>
              </span>
            </Fragment>
          }
          heading11={
            <Fragment>
              <span className="pagina2-text53 Subtitulo-A">
                <span>
                  Mas o que tem a ver a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text55">
                  energia eólica com o meio ambiente, as tartarugas gigantes e
                  os tubarões?
                </span>
              </span>
            </Fragment>
          }
          heading111={
            <Fragment>
              <span className="pagina2-text56 Subtitulo-A">
                <span>
                  Para conservarmos essa beleza e todas essas curiosidades do
                  nosso planeta,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text58">
                  o jeito é cada vez mais investir em tecnologia, conhecimento e
                  inovação.
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag2-sanfona3root-class-name"
        ></Pag2Sanfona3>
        <Pag2Sanfona4
          text32={
            <Fragment>
              <span className="pagina2-text59 Texto">
                <span>
                  O
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text61">arquipélago das Maldivas</span>
                <span>
                  {' '}
                  é o paraíso dos mergulhadores. Suas águas cristalinas têm uma
                  visibilidade incrível e o clima é bom o ano todo. Ah e está
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text63">repleto de tubarões</span>
                <span>
                  , incluindo 26 espécies diferentes! Tem tubarões-raposo,
                  tubarões-baleia, tubarões-lixa e tubarões-tigre.
                </span>
                <br></br>
                <br></br>
                <span>
                  E mergulhar com esses seres marinhos pode até ser uma
                  experiência incrível. Mas, com certeza, tem que ser muito
                  experiente pra entrar numa aventura dessa, né gente?
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag2-sanfona4root-class-name"
        ></Pag2Sanfona4>
        <Pag2Sanfona5
          text32={
            <Fragment>
              <span className="pagina2-text68 Texto">
                <span>
                  A robótica desempenha um papel crucial na cadeia da
                  sustentabilidade,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text70">
                  impulsionando a eficiência e reduzindo o impacto ambiental em
                  várias indústrias
                </span>
                <span>
                  . Desde a automação de processos de produção até a gestão
                  inteligente de recursos naturais, os robôs oferecem soluções
                  inovadoras para os desafios enfrentados pela sociedade
                  contemporânea.
                </span>
                <br></br>
                <br></br>
                <span>
                  Eles ajudam a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text75">
                  otimizar o uso de energia, minimizar resíduos e melhorar a
                  reciclagem
                </span>
                <span>
                  , contribuindo para um futuro mais verde e sustentável. São
                  fundamentais na pesquisa e desenvolvimento de tecnologias
                  ambientalmente amigáveis, impulsionando a inovação e
                  promovendo práticas sustentáveis em todo o mundo.
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag2-sanfona5root-class-name"
        ></Pag2Sanfona5>
        <Pag2Sanfona6
          text8={
            <Fragment>
              <span className="pagina2-text77 Subtitulo-A">
                <span>
                  Ok, você não é um robô, mas sabe que
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text79">
                  os robôs só existem graças a nós, humanos
                </span>
                <span>
                  , certo?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </Fragment>
          }
          text32={
            <Fragment>
              <span className="pagina2-text81 Texto">
                <span>
                  E você também sabe que não precisa “virar gente grande” para
                  contribuir na
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina2-text83">
                  construção de um mundo melhor, mais limpo, seguro e saudável.
                </span>
                <span>
                  {' '}
                  Aí vão algumas dicas de pequenas ações que fazem toda a
                  diferença!
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag2-sanfona6root-class-name"
        ></Pag2Sanfona6>
      </div>
      <div className="pagina2-sugestao-conteudo DefaultPadding">
        <div className="pagina2-interno MaxWidthMenor">
          <div className="pagina2-texto-ei">
            <span className="pagina2-text85 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina2-text86 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina2-button Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina2-container8">
            <img
              alt="image"
              src="/Pagina 2/Imagens/pagina%202%20imagem%2010%20new-1200w.png"
              className="pagina2-image3"
            />
          </div>
        </div>
        <div className="pagina2-container9">
          <img
            alt="image"
            src="/Pagina 2/Imagens/pagina%202%20imagem%2010-1500w.png"
            className="pagina2-image4"
          />
        </div>
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name1"
      ></SeoFinal>
    </div>
  )
}

export default Pagina2
