import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag1-sanfona4.css'

const Pag1Sanfona4 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag1-sanfona4-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona4-topo">
        <span className="pag1-sanfona4-text10 Titulo-Extra-B">
          Soft Robotic
        </span>
        <div className="pag1-sanfona4-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona4-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona4-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag1-sanfona4-container2">
          <div className="pag1-sanfona4-container3">
            <div className="pag1-sanfona4-container4">
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="pag1-sanfona4-image1"
              />
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="pag1-sanfona4-image2"
              />
            </div>
            <div className="pag1-sanfona4-container5">
              <span>
                {props.text8 ?? (
                  <Fragment>
                    <span className="pag1-sanfona4-text12 Subtitulo-A">
                      <span>
                        A
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona4-text14">Soft Robotic</span>
                      <span>
                        {' '}
                        ou
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona4-text16">
                        Robótica Mole
                      </span>
                      <span> é o subcampo específico da robótica</span>
                    </span>
                  </Fragment>
                )}
              </span>
              <a
                href="https://www.epfl.ch/labs/rrl/research-2/research-soft/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona4-link"
              >
                {props.text3 ?? (
                  <Fragment>
                    <span className="pag1-sanfona4-text18 Texto">
                      <span>
                        A Soft Robotic trata da
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona4-text20">
                        construção de robôs a partir de materiais altamente
                        complacentes, semelhantes aos encontrados em organismos
                        vivos.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        No entanto, alguns dispositivos flexíveis podem depender
                        de sistemas de controle eletrônicos rígidos, e
                        dispositivos totalmente flexíveis permanecem restritos
                        em suas capacidades.
                      </span>
                      <br></br>
                      <br></br>
                      <span className="pag1-sanfona4-text26">
                        Veja mais nesse artigo.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag1Sanfona4.defaultProps = {
  text8: undefined,
  rootClassName: '',
  imageSrc1:
    '/Pagina 1/Sanfona 4/pagina%201%20sanfona%204%20imagem%201-200h.png',
  imageSrc:
    '/Pagina 1/Sanfona 4/pagina%201%20sanfona%204%20imagem%202-1500w.png',
  imageAlt1: 'image',
  imageAlt: 'image',
  text3: undefined,
}

Pag1Sanfona4.propTypes = {
  text8: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageAlt: PropTypes.string,
  text3: PropTypes.element,
}

export default Pag1Sanfona4
