import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag1-sanfona2.css'

const Pag1Sanfona2 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag1-sanfona2-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona2-topo">
        <span className="pag1-sanfona2-text10 Titulo-Extra-B">
          Robótica no fundo do mar
        </span>
        <div className="pag1-sanfona2-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona2-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona2-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag1-sanfona2-container2">
          <div className="pag1-sanfona2-container3">
            <img
              alt="image"
              src="/Pagina 1/Sanfona 2/pagina%201%20safona%202%20imagem%203-1500w.png"
              className="pag1-sanfona2-image1 Radius"
            />
            <span>
              {props.text ?? (
                <Fragment>
                  <span className="pag1-sanfona2-text26 Texto">
                    <span>
                      Vimos no vídeo que a robótica também está presente em
                      trabalhos no fundo do mar, tornando a vida dos humanos
                      mais seguras ou, ainda, conseguindo realizar operações que
                      antes eram impossíveis.
                    </span>
                    <br></br>
                    <br></br>
                    <span className="pag1-sanfona2-text30">
                      Uma das atividade da robótica no fundo do mar é ajudar no
                      mapeamento dos oceanos.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Olha só esse robô uOne, projetado pela empresa belga uWare
                      Robotics. Ele promete assumir o lugar de mergulhadores
                      humanos na execução de tarefas arriscadas. O robô foi
                      desenvolvido principalmente para a inspeção de estruturas
                      subaquáticas.
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <div className="pag1-sanfona2-container4">
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="pag1-sanfona2-image2"
            />
            <div className="pag1-sanfona2-container5">
              <h1>
                {props.heading ?? (
                  <Fragment>
                    <h1 className="pag1-sanfona2-text37 Titulo-Extra">
                      <span>Mapeando o</span>
                      <br></br>
                      <span>fundo do oceano</span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span>
                {props.text1 ?? (
                  <Fragment>
                    <span className="pag1-sanfona2-text34 Texto">
                      <span className="pag1-sanfona2-text35">
                        Ele consegue criar mapas tridimensionais dos locais onde
                        está passando.
                      </span>
                      <span>
                        {' '}
                        Com um dispositivo SSD integrado, é possível coletar
                        dados como imagens RGB de alta resolução, leituras de
                        temperatura e profundidade e baixá-los assim que o robô
                        estiver de volta à base.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              className="pag1-sanfona2-image3"
            />
          </div>
          <div className="pag1-sanfona2-container6">
            <img
              alt={props.imageAlt2}
              src={props.imageSrc2}
              className="pag1-sanfona2-image4"
            />
            <div className="pag1-sanfona2-container7">
              <h1>
                {props.heading1 ?? (
                  <Fragment>
                    <h1 className="pag1-sanfona2-text15 Subtitulo-A">
                      <span>
                        O
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona2-text17">uOne</span>
                      <span>
                        {' '}
                        tem 40 cm de altura, 40 cm de largura e 50 cm de
                        comprimento
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <a
                href="https://uware.io/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona2-link"
              >
                {props.text2 ?? (
                  <Fragment>
                    <span className="pag1-sanfona2-text19 Texto">
                      <span>
                        Seu peso é de 15 kg, com capacidade para carregar até 5
                        kg de sensores, luzes ou outros itens. A velocidade
                        máxima do robô é de 3 nós (5,5 km/h) e ele tem autonomia
                        de funcionamento de, aproximadamente, duas horas, com
                        uma carga estimada em duas horas de bateria trocável.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Ficou com mais curiosidade ainda?</span>
                      <br></br>
                      <span className="pag1-sanfona2-text25">
                        Dá uma olhada aqui no site do fabricante.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag1Sanfona2.defaultProps = {
  imageSrc2:
    '/Pagina 1/Sanfona 2/pagina%201%20safona%202%20imagem%204-1500w.png',
  imageAlt3: 'image',
  imageAlt2: 'image',
  imageSrc3:
    '/Pagina 1/Sanfona 2/pagina%201%20safona%202%20imagem%201-1500w.png',
  imageSrc1:
    '/Pagina 1/Sanfona 2/pagina%201%20safona%202%20imagem%202-1500w.png',
  heading1: undefined,
  text2: undefined,
  text: undefined,
  imageSrc11: '3341552f-3d36-41cd-a305-85e3488c1fb2',
  text1: undefined,
  heading: undefined,
  imageAlt1: 'image',
  rootClassName: '',
}

Pag1Sanfona2.propTypes = {
  imageSrc2: PropTypes.string,
  imageAlt3: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc1: PropTypes.string,
  heading1: PropTypes.element,
  text2: PropTypes.element,
  text: PropTypes.element,
  imageSrc11: PropTypes.string,
  text1: PropTypes.element,
  heading: PropTypes.element,
  imageAlt1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Pag1Sanfona2
