import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag6-sanfona4.css'

const Pag6Sanfona4 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag6-sanfona4-container10 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag6-sanfona4-topo">
        <span className="pag6-sanfona4-text10 Titulo-Extra-B">
          Fique por dentro
        </span>
        <div className="pag6-sanfona4-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona4-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona4-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag6-sanfona4-container11">
          <div className="pag6-sanfona4-container12">
            <span className="Texto">
              <span>
                Para aprofundar seus conhecimentos e manter atualizadas as
                informações sobre esse universo que muda e se renova a cada
                minuto, seguem abaixo algumas sugestões
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag6-sanfona4-text13">
                online que tratam de profissões e novidades na área da robótica:
              </span>
            </span>
          </div>
          <div className="pag6-sanfona4-container13">
            <div className="pag6-sanfona4-container14">
              <div className="pag6-sanfona4-container15">
                <span>
                  {props.text6 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text49 Texto-Extra">
                        Indicação
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <h1>
                {props.heading ?? (
                  <Fragment>
                    <h1 className="pag6-sanfona4-text50 Titulo-Extra">
                      Sites e blogs
                    </h1>
                  </Fragment>
                )}
              </h1>
              <a
                href="https://community.robotshop.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link10"
              >
                {props.text7 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text30 Texto">
                      <span className="pag6-sanfona4-text31">
                        RobotShop Community
                      </span>
                      <br></br>
                      <span>
                        Uma comunidade online que discute robótica, projetos e
                        carreiras na área.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://robohub.org/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link11"
              >
                {props.text72 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text21 Texto">
                      <span className="pag6-sanfona4-text22">Robohub</span>
                      <br className="pag6-sanfona4-text23"></br>
                      <span>
                        Cobre todas as áreas da robótica, incluindo artigos
                        sobre carreiras e tendências.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://spectrum.ieee.org/topic/robotics/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link12"
              >
                {props.text721 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text26 Texto">
                      <span className="pag6-sanfona4-text27">
                        IEEE Spectrum Robotics
                      </span>
                      <br></br>
                      <span>
                        Seção dedicada a notícias e artigos sobre robótica,
                        incluindo aspectos de carreira.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://www.therobotreport.com/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link13"
              >
                {props.text7211 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text57 Texto">
                      <span className="pag6-sanfona4-text58">
                        Robotics Business Review
                      </span>
                      <br></br>
                      <span>Focado em notícias de negócios  e </span>
                      <span>carreiras na indústria de robótica.</span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
            <div className="pag6-sanfona4-container16 Radius">
              <img
                alt={props.imageAlt2}
                src={props.imageSrc2}
                className="pag6-sanfona4-image1"
              />
            </div>
          </div>
          <div className="pag6-sanfona4-container17">
            <div className="pag6-sanfona4-container18">
              <div className="pag6-sanfona4-container19">
                <span>
                  {props.text61 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text20 Texto-Extra">
                        Indicação
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <h1>
                {props.heading1 ?? (
                  <Fragment>
                    <h1 className="pag6-sanfona4-text56 Titulo-Extra">
                      Vídeos no YouTube
                    </h1>
                  </Fragment>
                )}
              </h1>
              <a
                href="https://www.youtube.com/c/RealEngineering"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link14"
              >
                {props.text73 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text45 Texto">
                      <span className="pag6-sanfona4-text46">
                        Real Engineering
                      </span>
                      <br className="pag6-sanfona4-text47"></br>
                      <span>
                        Vídeos que exploram engenharia e tecnologia, incluindo
                        robótica e suas aplicações.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://www.youtube.com/c/RobotShopTV"
                target="_blank"
                rel="noreferrer noopener"
                className="pag6-sanfona4-link15"
              >
                {props.text722 ?? (
                  <Fragment>
                    <span className="pag6-sanfona4-text67 Texto">
                      <span className="pag6-sanfona4-text68">RobotShop TV</span>
                      <br className="pag6-sanfona4-text69"></br>
                      <span>
                        Tutoriais, demonstrações e discussões sobre robótica.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
            <img
              alt={props.imageAlt21}
              src={props.imageSrc21}
              className="pag6-sanfona4-image2 Radius"
            />
          </div>
          <div className="pag6-sanfona4-container20">
            <div className="pag6-sanfona4-container21">
              <div className="pag6-sanfona4-container22">
                <div className="pag6-sanfona4-container23">
                  <span>
                    {props.text611 ?? (
                      <Fragment>
                        <span className="pag6-sanfona4-text40 Texto-Extra">
                          Indicação
                        </span>
                      </Fragment>
                    )}
                  </span>
                </div>
                <h1>
                  {props.heading11 ?? (
                    <Fragment>
                      <h1 className="pag6-sanfona4-text62 Titulo-Extra">
                        TED Talks
                      </h1>
                    </Fragment>
                  )}
                </h1>
                <a
                  href="https://www.ted.com/talks/radhika_nagpal_what_intelligent_machines_can_learn_from_a_school_of_fish"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag6-sanfona4-link16"
                >
                  {props.text731 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text72 Texto">
                        <span className="pag6-sanfona4-text73">
                          Radhika Nagpal: What intelligent
                        </span>
                        <br className="pag6-sanfona4-text74"></br>
                        <span className="pag6-sanfona4-text75">
                          machines can learn from a school of fish.
                        </span>
                        <br className="pag6-sanfona4-text76"></br>
                        <span>Explora como o comportamento de </span>
                        <span>enxames na natureza pode inspirar novas </span>
                        <span>abordagens para a programação de robôs </span>
                        <span>autônomos e sistemas inteligentes.</span>
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
              <div className="pag6-sanfona4-container24 Radius">
                <img
                  alt={props.imageAlt211}
                  src={props.imageSrc211}
                  className="pag6-sanfona4-image3"
                />
              </div>
            </div>
            <div className="pag6-sanfona4-container25">
              <div className="pag6-sanfona4-container26">
                <a
                  href="https://www.ted.com/talks/rodney_brooks_why_we_will_rely_on_robots"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag6-sanfona4-link17"
                >
                  {props.text7311 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text35 Texto">
                        <span className="pag6-sanfona4-text36">
                          Rodney Brooks: Why we will rely on robots.
                        </span>
                        <br className="pag6-sanfona4-text37"></br>
                        <span>
                          O co-fundador da iRobot, explora como os robôs estão
                          se tornando essenciais em nossas vidas e como isso
                          pode impactar o futuro das profissões.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://www.ted.com/talks/ken_goldberg_4_lessons_from_robots_about_being_human"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag6-sanfona4-link18"
                >
                  {props.text73114 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text51 Texto">
                        <span className="pag6-sanfona4-text52">
                          Ken Goldberg: 4 lessons from robots about being human.
                        </span>
                        <br></br>
                        <span>
                          O palestrante explora a interação com robôs e as
                          possibilidades de aprendermos com eles e sobre nós
                          mesmos, nossa empatia e nossas expectativas para o
                          futuro da colaboração homem-máquina.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://www.ted.com/talks/vijay_kumar_robots_that_fly_and_cooperate?subtitle=en&amp;lng=pt-br&amp;geo=pt-br"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag6-sanfona4-link19"
                >
                  {props.text73113 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text41 Texto">
                        <span className="pag6-sanfona4-text42">
                          Vijay Kumar: Robots that fly... And cooperate.
                        </span>
                        <br></br>
                        <span>
                          Fala sobre pesquisas com robôs voadores e como essas
                          tecnologias podem ser utilizadas para resolver
                          problemas complexos e colaborar em equipes.
                        </span>
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://www.ted.com/talks/cynthia_breazeal_the_rise_of_personal_robots"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag6-sanfona4-link20"
                >
                  {props.text73112 ?? (
                    <Fragment>
                      <span className="pag6-sanfona4-text63 Texto">
                        <span className="pag6-sanfona4-text64">
                          Cynthia Breazeal: The rise of personal robots.
                        </span>
                        <br></br>
                        <span>
                          Explora o potencial dos robôs pessoais integrando
                          nosso cotidiano.
                        </span>
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
            <div className="pag6-sanfona4-container27 Radius">
              <img
                alt={props.imageAlt2112}
                src={props.imageSrc2112}
                className="pag6-sanfona4-image4"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag6Sanfona4.defaultProps = {
  text61: undefined,
  text72: undefined,
  imageSrc211: '/Pagina 6/images/pagina%206%20imagem%208-400h.png',
  text721: undefined,
  text7: undefined,
  text7311: undefined,
  text611: undefined,
  text73113: undefined,
  imageSrc21: '/Pagina 6/images/pagina%206%20imagem%207-1500w.png',
  text73: undefined,
  text6: undefined,
  heading: undefined,
  imageSrc2: '/Pagina 6/images/pagina%206%20imagem%206-400h.png',
  imageAlt2112: 'image',
  imageAlt2: 'image',
  imageAlt21: 'image',
  text73114: undefined,
  heading1: undefined,
  text7211: undefined,
  heading11: undefined,
  rootClassName: '',
  text73112: undefined,
  text722: undefined,
  text731: undefined,
  imageSrc2112: '/Pagina 6/images/pagina%206%20imagem%208-400h.png',
  imageAlt211: 'image',
}

Pag6Sanfona4.propTypes = {
  text61: PropTypes.element,
  text72: PropTypes.element,
  imageSrc211: PropTypes.string,
  text721: PropTypes.element,
  text7: PropTypes.element,
  text7311: PropTypes.element,
  text611: PropTypes.element,
  text73113: PropTypes.element,
  imageSrc21: PropTypes.string,
  text73: PropTypes.element,
  text6: PropTypes.element,
  heading: PropTypes.element,
  imageSrc2: PropTypes.string,
  imageAlt2112: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt21: PropTypes.string,
  text73114: PropTypes.element,
  heading1: PropTypes.element,
  text7211: PropTypes.element,
  heading11: PropTypes.element,
  rootClassName: PropTypes.string,
  text73112: PropTypes.element,
  text722: PropTypes.element,
  text731: PropTypes.element,
  imageSrc2112: PropTypes.string,
  imageAlt211: PropTypes.string,
}

export default Pag6Sanfona4
