import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import HomeSanfona1 from '../components/home-sanfona1'
import SeoFinal from '../components/seo-final'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>MIRT</title>
        <meta property="og:title" content="MIRT" />
      </Helmet>
      <Navbar></Navbar>
      <div className="home-hero-banner DefaultPadding">
        <div className="home-fundo1"></div>
        <div className="home-inner thq-section-max-width">
          <div className="home-interno1">
            <img
              alt="Ellipse921078"
              src="/external/ellipse921078-ro08.svg"
              className="home-ellipse92"
            />
            <div className="home-frame2">
              <span className="home-text10 Titulo-Banner">
                <span>Interativa e</span>
                <br></br>
                <span>itinerante</span>
              </span>
              <span className="home-text14 Texto">
                <span>
                  A
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text16">MIRT</span>
                <span>
                  {' '}
                  chegou para levar
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text18">
                  experiências imersivas em realidade virtual até você.
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
            <Link to="/formulario" className="home-navlink1">
              <div className="home-frame1000003354 Pointer HoverScale">
                <span className="home-text20 Botao">
                  <span>Quero a MIRT pertinho de mim</span>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-seo1 DefaultPadding">
        <div className="home-fundo2"></div>
        <div className="home-container11">
          <div className="home-interno2 thq-section-max-width">
            <div className="home-group1000003551">
              <span className="home-text22 Titulo-A">Sobre a</span>
              <span className="home-text23 Titulo-Banner-2">MIRT</span>
            </div>
            <span className="home-text24 Texto">
              <span className="home-text25">
                A Mostra Interativa de Robótica e Tecnologia (MIRT) é um projeto
                idealizado e mantido pelo Sesc Santa Catarina.
              </span>
              <br></br>
              <br></br>
              <span>
                Interativa e itinerante, a MIRT leva
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text29">
                experiências imersivas em Realidade Virtual para escolas e
                eventos
              </span>
              <span>
                {' '}
                de Santa Catarina, além de distribuir conteúdos educativos
                online em audiovisual e outros formatos.
              </span>
              <br></br>
              <br></br>
              <span>
                Ao todo são 40 óculos Meta Quest 2 que funcionam sincronizados,
                permitindo que turmas escolares ou participantes de eventos
                façam um mergulho no mundo da robótica, da tecnologia e da
                sustentabilidade.
              </span>
            </span>
          </div>
          <div className="home-textura"></div>
        </div>
      </div>
      <div className="home-seo2 DefaultPadding">
        <div className="home-interno3 MaxWidthMenor">
          <img
            alt="image"
            src="/Pagina Home/secao3%20elemento%20mobile-500w.png"
            className="home-image1"
          />
          <div className="home-texto-ei1">
            <span className="home-text34 Titulo-B">
              <span>Experiências imersivas</span>
            </span>
            <span className="home-text36 Texto">
              São 3 experiências imersivas disponíveis dentro dos óculos Meta
              Quest 2. Turmas de até 40 pessoas por vez podem participar da
              experiência. Quer solicitar uma visita do MIRT à sua escola,
              cidade ou evento? Clique aqui.
            </span>
          </div>
          <div className="home-container12">
            <img
              alt="image"
              src="/Pagina Home/secao3%20elemento%20fundo-400h.png"
              className="home-fundo3"
            />
            <img
              alt="image"
              src="/Pagina Home/secao3%20elemento%20oculos-600w.png"
              className="home-oculos1"
            />
          </div>
        </div>
      </div>
      <div className="home-seo3 DefaultPadding">
        <div className="home-interno4">
          <HomeSanfona1 rootClassName="home-sanfona1root-class-name"></HomeSanfona1>
        </div>
      </div>
      <div className="home-seo4 DefaultPadding">
        <div className="home-interno5 MaxWidthMenor">
          <div className="home-texto-ei2">
            <span className="home-text37 Titulo-B">
              <span>Conteúdos</span>
              <br></br>
              <span>online</span>
            </span>
            <span className="home-text41 Texto">
              <span className="home-text42">
                Assista vídeos-colagem, navegue nas páginas de conteúdo e acesse
                os roteiros pedagógicos. 
              </span>
              <span>
                Os conteúdos da MIRT foram desenvolvidos para aprender se
                divertindo em casa ou na escola. Os materiais online são
                complementares às experiências imersivas presenciais.
              </span>
            </span>
          </div>
          <div className="home-container13">
            <img
              alt="image"
              src="/Pagina Home/home%20s4%20lego-1200w.png"
              className="home-fundo4"
            />
            <img
              alt="image"
              src="/Pagina Home/secao%204%20computador-500w.png"
              className="home-oculos2"
            />
          </div>
        </div>
      </div>
      <div className="home-seo5 DefaultPadding">
        <div className="home-container14 MaxWidthMenor">
          <Link to="/pagina-1">
            <div className="home-container15">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%201-1200w.png"
                className="home-image2 BoxShadow"
              />
              <div className="home-container16">
                <div className="home-container17">
                  <span className="home-text44">01</span>
                </div>
                <span className="home-text45 Texto-Extra">
                  <span>De onde viemos? Para onde vamos? </span>
                  <span className="home-text47">
                    Uma breve história da Robótica
                  </span>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/pagina-2">
            <div className="home-container18">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%202-1200w.png"
                className="home-image3 BoxShadow"
              />
              <div className="home-container19">
                <div className="home-container20">
                  <span className="home-text48">02</span>
                </div>
                <span className="home-text49 Texto-Extra">
                  <span className="home-text50">Salve a Robótica:</span>
                  <span> Ferramentas </span>
                  <span>para um futuro sustentável</span>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/pagina-3">
            <div className="home-container21">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%203-1200w.png"
                className="home-image4 BoxShadow"
              />
              <div className="home-container22">
                <div className="home-container23">
                  <span className="home-text53">03</span>
                </div>
                <span className="home-text54 Texto-Extra">
                  <span className="home-text55">
                    Sim, tem um robô perto de você 
                  </span>
                  <span>(ou terá muito em breve)</span>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/pagina-4">
            <div className="home-container24">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%204-1200w.png"
                className="home-image5 BoxShadow"
              />
              <div className="home-container25">
                <div className="home-container26">
                  <span className="home-text57">04</span>
                </div>
                <span className="home-text58 Texto-Extra">
                  <span className="home-text59">Batalha de robôs:</span>
                  <span> Quando a educação </span>
                  <span>e o lúdico caminham juntos</span>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/pagina-5">
            <div className="home-container27">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%205-1200w.png"
                className="home-image6 BoxShadow"
              />
              <div className="home-container28">
                <div className="home-container29">
                  <span className="home-text62">05</span>
                </div>
                <span className="home-text63 Texto-Extra">
                  <span className="home-text64">Games + robótica:</span>
                  <span> Muito além do entretenimento</span>
                </span>
              </div>
            </div>
          </Link>
          <Link to="/pagina-6">
            <div className="home-container30">
              <img
                alt="image"
                src="/Pagina Home/secao%206%20banner%206-1200w.png"
                className="home-image7 BoxShadow"
              />
              <div className="home-container31">
                <div className="home-container32">
                  <span className="home-text66">06</span>
                </div>
                <span className="home-text67 Texto-Extra">
                  Prazer, humano, eu sou um robô!
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="home-seo6 DefaultPadding">
        <div className="home-container33 MaxWidthMenor">
          <div className="home-container34">
            <h1 className="home-text68 Subtitulo-A">
              Olá, professor/professora
            </h1>
            <span className="Texto">
              <span className="home-text70">
                Quer trabalhar os conteúdos da MIRT com seus alunos?
              </span>
              <span>
                {' '}
                Além dos conteúdos acima, você também pode baixar roteiros
                pedagógicos com sugestões de atividades vinculadas à BNCC. Assim
                você trabalha os conteúdos da MIRT de forma alinhada ao seu
                plano de ensino.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <form className="home-form">
              <input
                type="text"
                placeholder="Nome completo"
                className="home-textinput1 Texto"
              />
              <div className="home-container35">
                <input
                  type="text"
                  placeholder="Cidade"
                  className="home-textinput2 Texto"
                />
                <select className="home-select Texto">
                  <option value="UF">Option 1</option>
                  <option value="Option 2">Option 2</option>
                  <option value="Option 3">Option 3</option>
                </select>
              </div>
              <input
                type="text"
                placeholder="E-mail"
                className="home-textinput3 Texto"
              />
              <input
                type="text"
                placeholder="Nome da escola"
                className="home-textinput4 Texto"
              />
              <input
                type="text"
                placeholder="Tipo de instituição"
                className="home-textinput5 Texto"
              />
              <input
                type="text"
                placeholder="O que você leciona"
                className="home-textinput6 Texto"
              />
              <div className="home-container36">
                <div className="home-container37">
                  <input
                    type="checkbox"
                    name="aceite_termos"
                    className="home-checkbox"
                  />
                </div>
                <span className="home-text72 Observacao">
                  Aceito receber novos conteúdos da MIRT e do Sesc Santa
                  Catarina e estou de acordo com os termos de uso dos meus
                  dados.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </div>
              <button type="button" className="home-button Botao">
                Baixar conteúdo
              </button>
            </form>
          </div>
        </div>
        <img
          alt="image"
          src="/Pagina Home/secao%206%20imagem-1500w.png"
          className="home-image8"
        />
      </div>
      <SeoFinal></SeoFinal>
    </div>
  )
}

export default Home
