import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './modelo-slide-classificao.css'

const ModeloSlideClassificao = (props) => {
  return (
    <div
      className={`modelo-slide-classificao-container ${props.rootClassName} `}
    >
      <div className="modelo-slide-classificao-interno">
        <img
          alt={props.imageAlt}
          src={props.imageSrc}
          className="modelo-slide-classificao-image1 HideOnMobile"
        />
        <img
          alt={props.imageAlt}
          src={props.imageSrc2}
          className="modelo-slide-classificao-image2 ShowOnMobile"
        />
        <div className="modelo-slide-classificao-frame1000003600">
          <span className="modelo-slide-classificao-text1 Titulo">
            {props.titulo ?? (
              <Fragment>
                <span className="modelo-slide-classificao-text3 Titulo">
                  De onde viemos? Uma breve história da Robótica
                </span>
              </Fragment>
            )}
          </span>
          <span className="modelo-slide-classificao-text2">
            {props.conteudo ?? (
              <Fragment>
                <span className="modelo-slide-classificao-text4 Texto">
                  <span>
                    Nessa experiência imersiva, os participantes irão conhecer
                    mais sobre os desafios de sustentabilidade do planeta e
                    entender como a robótica e a tecnologia podem ajudar os
                    humanos na preservação da vida e do planeta. O conteúdo
                    também aborda os Objetivos de Desenvolvimento Sustentável.
                  </span>
                  <br className="modelo-slide-classificao-text6"></br>
                  <br className="modelo-slide-classificao-text7"></br>
                  <span>Duração: 00min</span>
                </span>
              </Fragment>
            )}
          </span>
          <div className="modelo-slide-classificao-indicaes1">
            <img
              alt="DJCTQL16141"
              src="/external/livre-200w-200w.png"
              className="modelo-slide-classificao-djctql11"
            />
            <img
              alt="Vector9161"
              src="/external/sinais-200w-200w.png"
              className="modelo-slide-classificao-vector1"
            />
            <img
              alt="Vector9161"
              src="/external/ingles-200w-200w.png"
              className="modelo-slide-classificao-vector2"
            />
            <img
              alt="Vector9161"
              src="/external/descricao-200w-200w.png"
              className="modelo-slide-classificao-vector3"
            />
          </div>
          <div className="modelo-slide-classificao-indicaes2">
            <img
              alt="DJCTQL16141"
              src="/Geral/livre-200w.png"
              className="modelo-slide-classificao-djctql12"
            />
            <img
              alt="Vector9161"
              src="/Geral/sinais-200w.png"
              className="modelo-slide-classificao-vector4"
            />
            <img
              alt="Vector9161"
              src="/Geral/ingles-200w.png"
              className="modelo-slide-classificao-vector5"
            />
            <img
              alt="Vector9161"
              src="/Geral/descricao-200w.png"
              className="modelo-slide-classificao-vector6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ModeloSlideClassificao.defaultProps = {
  titulo: undefined,
  imageSrc: '/Pagina Home/home%20slide%201%20image%203-1200w.png',
  conteudo: undefined,
  rootClassName: '',
  imageAlt: 'image',
  imageSrc2:
    '/Pagina Home/pagina%201%20slide%201%20mobile%20imagem%201-1500h.png',
}

ModeloSlideClassificao.propTypes = {
  titulo: PropTypes.element,
  imageSrc: PropTypes.string,
  conteudo: PropTypes.element,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc2: PropTypes.string,
}

export default ModeloSlideClassificao
