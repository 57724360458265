import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './pag3-sanfona3.css'

const Pag3Sanfona3 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag3-sanfona3-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag3-sanfona3-topo">
        <span className="pag3-sanfona3-text10 Titulo-Extra-B">
          Carros voadores
        </span>
        <div className="pag3-sanfona3-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona3-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona3-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag3-sanfona3-container2">
          <div className="pag3-sanfona3-container3">
            <div className="pag3-sanfona3-container4">
              <a
                href="https://embraer.com/br/pt"
                target="_blank"
                rel="noreferrer noopener"
                className="pag3-sanfona3-link1 Texto"
              >
                <span>
                  A Eve Air Mobility, empresa de mobilidade urbana da
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona3-text12">Embraer</span>
                <span>
                  {' '}
                  (EMBR3), anunciou a produção do
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona3-text14">
                  primeiro protótipo de carro voador em tamanho real no Brasil.
                </span>
                <span> Trata-se do</span>
                <span className="pag3-sanfona3-text16">
                   eVTOL, ou “veículo de pouso de decolagem vertical”.
                </span>
                <br className="pag3-sanfona3-text17"></br>
                <br></br>
                <span>
                  A aeronave
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona3-text20">
                  terá propulsor elétrico, com zero emissão de carbono,
                </span>
                <span>
                  {' '}
                  o que corresponde a uma redução de 80% na emissão de dióxido
                  de carbono comparado aos carros comuns. A Eve Air Mobility
                  ainda
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona3-text22">
                  garante uma poluição sonora 90% menor do que helicópteros.
                </span>
                <span>
                  {' '}
                  A Embraer já recebeu cerca de 3 mil encomendas de seu “carro
                  voador”.
                </span>
              </a>
            </div>
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%204-1500w.png"
              className="pag3-sanfona3-image"
            />
          </div>
          <a
            href="https://evtol.news/"
            target="_blank"
            rel="noreferrer noopener"
            className="pag3-sanfona3-link2 Texto"
          >
            <span>
              Além disso, segundo as previsões da empresa, a aeronave vai
              começar a voar a partir de 2026. Na capital paulista, a
              empresa Voar Aviation comprou 70 eVTOLs para operar em voos
              comerciais. Mas ao que tudo indica, na corrida dos carros
              voadores, embora o Brasil esteja muito bem no ranking, a Alemanha
              lidera.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag3-sanfona3-text25">
              O primeiro carro voador que deve começar a ser utilizado
              no mundo é o Volocity, da empresa alemã Volocopter.
            </span>
            <span>
              {' '}
              O modelo já recebeu a aprovação do Departamento Federal de Aviação
              da Alemanha para ser produzido e será fabricado na cidade de
              Bruchsal.
            </span>
            <br></br>
            <br></br>
            <span>
              Você se interessa pelo assunto? Aí vai um
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag3-sanfona3-text30">
              link de acesso com informações e notícias bem atualizadas sobre o
              tema.
            </span>
          </a>
        </div>
      )}
    </div>
  )
}

Pag3Sanfona3.defaultProps = {
  rootClassName: '',
}

Pag3Sanfona3.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pag3Sanfona3
