import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag4-sanfona3.css'

const Pag4Sanfona3 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag4-sanfona3-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona3-topo">
        <span className="pag4-sanfona3-text10 Titulo-Extra-B">
          FIRST LEGO League
        </span>
        <div className="pag4-sanfona3-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona3-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona3-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag4-sanfona3-container2">
          <div className="pag4-sanfona3-container3">
            <div className="pag4-sanfona3-container4 Radius">
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%207-1500w.png"
                className="pag4-sanfona3-image1 Radius"
              />
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%208-1500w.png"
                className="pag4-sanfona3-image2"
              />
            </div>
            <div className="pag4-sanfona3-container5">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag4-sanfona3-text33 Subtitulo-A">
                      <span>
                        FIRST LEGO League
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag4-sanfona3-text35">
                        é um programa que apresenta ciência, tecnologia,
                        engenharia e matemática
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>(STEM)</span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <a
                href="https://www.firstlegoleague.org/about"
                target="_blank"
                rel="noreferrer noopener"
                className="pag4-sanfona3-link Texto"
              >
                <span>
                  O programa, com abrangência mundial, é voltado para
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona3-text13">
                  crianças de 4 a 16 anos
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  (as idades variam de acordo com o país) por meio de
                  aprendizado prático, divertido e emocionante. Os participantes
                  ganham
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona3-text15">
                  experiência na resolução de problemas do mundo real, por meio
                  de um programa de robótica
                </span>
                <span>
                  {' '}
                  global e guiado, com o objetivo e a meta de construir juntos,
                  alunos e professores, um futuro melhor.
                </span>
                <br></br>
                <br></br>
                <span>
                  As crianças e os jovens
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona3-text20">
                  experimentam e desenvolvem suas habilidades de pensamento
                  crítico, codificação e design
                </span>
                <span>
                  {' '}
                  por meio do aprendizado prático de STEM e da robótica. Os
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona3-text22">
                  Valores Fundamentais
                </span>
                <span>
                  {' '}
                  proporcionam aos participantes aprenderem que a competição
                  amigável e o ganho mútuo não são objetivos separados, e que
                  ajudar uns aos outros é a base do trabalho em equipe.
                </span>
                <br></br>
                <br></br>
                <span>Quais são os pilares?</span>
                <span className="pag4-sanfona3-text27">
                  {' '}
                  Descoberta, Inovação, Impacto, Conclusão, Trabalho em equipe e
                  Diversão.
                </span>
                <br></br>
                <br></br>
                <span>Quer saber mais e ficar por dentro de tudo?</span>
                <br></br>
                <span className="pag4-sanfona3-text32">
                  Acesse a página do FIRST LEGO League.
                </span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag4Sanfona3.defaultProps = {
  heading111: undefined,
  rootClassName: '',
}

Pag4Sanfona3.propTypes = {
  heading111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag4Sanfona3
