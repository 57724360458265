import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag1-sanfona5.css'

const Pag1Sanfona5 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag1-sanfona5-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona5-topo">
        <span className="pag1-sanfona5-text10 Titulo-Extra-B">
          ENIAC, um computador de 30 toneladas
        </span>
        <div className="pag1-sanfona5-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona5-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona5-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag1-sanfona5-container2">
          <div className="pag1-sanfona5-container3">
            <img
              alt={props.imageAlt2}
              src={props.imageSrc2}
              className="pag1-sanfona5-image1"
            />
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="pag1-sanfona5-image2"
            />
            <div className="pag1-sanfona5-container4">
              <span>
                {props.text81 ?? (
                  <Fragment>
                    <span className="pag1-sanfona5-text28 Subtitulo-B">
                      <span className="pag1-sanfona5-text29">
                        Um dos primeiros computadores foi o ENIAC.
                      </span>
                      <span>
                        {' '}
                        Criado em 1946, ele pesava mais de 30 toneladas.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
              <a
                href="https://www.youtube.com/watch?v=pKxWPo73pX0"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona5-link1"
              >
                {props.text31 ?? (
                  <Fragment>
                    <span className="pag1-sanfona5-text31 Texto">
                      <span>Quer conhecer mais sobre essa história?</span>
                      <br></br>
                      <span className="pag1-sanfona5-text34">
                        Dá uma olhada nesse vídeo do TecMundo.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
          </div>
          <div className="pag1-sanfona5-container5">
            <div className="pag1-sanfona5-container6">
              <div className="pag1-sanfona5-container7">
                <span>
                  {props.text6 ?? (
                    <Fragment>
                      <span className="pag1-sanfona5-text41 Texto-Extra">
                        Indicação
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <a
                href="https://editoraaleph.com.br/products/eu-robo?variant=44838472876315"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona5-link2"
              >
                {props.text7 ?? (
                  <Fragment>
                    <span className="pag1-sanfona5-text14 Texto">
                      <span>
                        No vídeo falamos do livro
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona5-text16">“Eu, Robô”</span>
                      <span>.</span>
                      <br></br>
                      <br></br>
                      <span>
                        Um clássico que cunhou o nome utilizado até hoje. Quer
                        saber mais?
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona5-text21">
                        Acesse a página do livro no site da Editora Aleph.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        O
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona5-text25">“Eu, Robô”</span>
                      <span>
                        {' '}
                        também virou filme dirigido por Alex Proyas, baseado em
                        contos do livro homônimo de Isaac Asimov. Foi lançado em
                        2004, estrelado por Will Smith, com classificação
                        indicativa de 10 anos.
                      </span>
                      <br></br>
                    </span>
                  </Fragment>
                )}
              </a>
              <div className="pag1-sanfona5-container8">
                <span>
                  {props.text71 ?? (
                    <Fragment>
                      <span className="pag1-sanfona5-text35 Texto">
                        Está disponível nos stremings
                      </span>
                    </Fragment>
                  )}
                </span>
                <a
                  href="https://www.primevideo.com/-/pt/detail/Eu-Robot/0FNB56KD8RLSI0VDE2Y62RH1VA"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag1-sanfona5-link3"
                >
                  {props.text713 ?? (
                    <Fragment>
                      <span className="pag1-sanfona5-text36 Texto">
                        Prime Vídeo
                      </span>
                    </Fragment>
                  )}
                </a>
                <a
                  href="https://www.disneyplus.com/pt-br/movies/eu-robot/3N5475cdpZUj"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pag1-sanfona5-link4"
                >
                  {props.text711 ?? (
                    <Fragment>
                      <span className="pag1-sanfona5-text37 Texto">
                        <span>
                          {' '}
                          e
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag1-sanfona5-text39">Disney +</span>
                        <span>.</span>
                      </span>
                    </Fragment>
                  )}
                </a>
              </div>
            </div>
            <img
              alt={props.imageAlt31}
              src={props.imageSrc31}
              className="pag1-sanfona5-image3"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Pag1Sanfona5.defaultProps = {
  imageAlt3: 'image',
  text7: undefined,
  text81: undefined,
  text31: undefined,
  text71: undefined,
  imageSrc2:
    '/Pagina 1/Sanfona 5/pagina%201%20sanfona%205%20imagem%201-1500w.png',
  text713: undefined,
  imageSrc31:
    '/Pagina 1/Sanfona 5/pagina%201%20sanfona%205%20imagem%203-1500w.png',
  imageAlt31: 'image',
  text711: undefined,
  rootClassName: '',
  imageSrc3:
    '/Pagina 1/Sanfona 5/pagina%201%20sanfona%205%20imagem%202-300w.png',
  text6: undefined,
  imageAlt2: 'image',
}

Pag1Sanfona5.propTypes = {
  imageAlt3: PropTypes.string,
  text7: PropTypes.element,
  text81: PropTypes.element,
  text31: PropTypes.element,
  text71: PropTypes.element,
  imageSrc2: PropTypes.string,
  text713: PropTypes.element,
  imageSrc31: PropTypes.string,
  imageAlt31: PropTypes.string,
  text711: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc3: PropTypes.string,
  text6: PropTypes.element,
  imageAlt2: PropTypes.string,
}

export default Pag1Sanfona5
