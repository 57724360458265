import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag4-sanfona5.css'

const Pag4Sanfona5 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag4-sanfona5-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona5-topo">
        <span className="pag4-sanfona5-text10 Titulo-Extra-B">
          Vamos relembrar o que foi a Corrida Espacial?
        </span>
        <div className="pag4-sanfona5-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona5-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona5-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag4-sanfona5-container2">
        <div className="pag4-sanfona5-container3" style={isAtivo ? {} : { display: "none" }}>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag4-sanfona5-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text11 Titulo">
                        Corrida espacial
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text12 Texto">
                        <span>
                          Uma disputa travada entre norte-americanos e
                          soviéticos pela hegemonia da
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text14">
                          exploração espacial, que aconteceu entre 1957 e 1975.
                        </span>
                        <br className="pag4-sanfona5-text15"></br>
                        <br></br>
                        <span>
                          As duas potências correram para
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text18">
                          incentivar o desenvolvimento da pesquisa científica
                        </span>
                        <span>
                          {' '}
                          e inúmeros acontecimentos marcantes se passaram nessa
                          corrida, como o envio de satélites artificiais para a
                          órbita terrestre e de seres humanos para o espaço.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%201-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name56"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text20 Titulo">
                        Teve até uma cachorrinha enviada num foguete
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text21 Texto">
                        <span className="pag4-sanfona5-text22">
                          Laika era um cadela com cerca de dois anos de idade
                        </span>
                        <span>
                          , que vivia pelas ruas de Moscou, capital da Rússia.
                          Ela se tornou
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text24">
                          a primeira criatura terrestre viva a orbitar a Terra
                          na missão russa Sputnik 2, em 1957
                        </span>
                        <span>
                          . Infelizmente, Laika morreu 10 dias depois, por causa
                          do superaquecimento da estrutura, mas a sua morte foi
                          ocultada pelo governo soviético.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%202-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name63"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text26 Titulo">
                        Nasa - National Aeronautics Space Administration
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text27 Texto">
                        <span>
                          Um ano depois, os EUA criaram a Nasa, que tinha como
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text29">
                          objetivo coordenar o projeto de exploração espacial
                          dos norte-americanos.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Mas ainda antes da criação da agência, foi
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text33">
                          lançado o Explorer 1
                        </span>
                        <span>
                          , o primeiro satélite artificial dos estadunidenses. O
                          Explorer 1 conseguiu
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text35">
                          comprovar a existência de cinturões de radioatividade
                          ao redor da Terra
                        </span>
                        <span>
                          . Além disso, outros sensores instalados no satélite
                          foram importantes para a obtenção de novas informações
                          sobre o espaço.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%203-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name64"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text37 Titulo">
                        E quem enviou o primeiro homem ao espaço?
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text38 Texto">
                        <span>Bom, os soviéticos levaram a melhor.</span>
                        <br></br>
                        <span>
                          O primeiro cosmonauta a ser enviado foi
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text42">
                          Yuri Gagarin
                        </span>
                        <span>, </span>
                        <span>
                          que foi transportado para o espaço na
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text45">
                          missão russa Vostok 1
                        </span>
                        <span>
                          , em maio de 1961. A nave esteve na órbita terrestre
                          durante 108 minutos. E foi durante essa jornada que
                          Gagarin soltou a famosa frase:
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text47">
                          “A Terra é azul”
                        </span>
                        <span>.</span>
                        <br></br>
                        <br></br>
                        <span>
                          A Vostok 1 não era manobrável e, por isso, quando
                          entrou novamente na atmosfera, Yuri Gagarin foi
                          obrigado a ejetar-se, quando estava na altura de oito
                          mil metros, e completar sua descida de paraquedas.
                          Yuri Gagarin foi convertido em herói nacional
                          soviético.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%204-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name65"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text52 Titulo">
                        E a corrida continua...
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text53 Texto">
                        <span>
                          Um mês depois de Gagarin dar seu rolê pelo espaço, os
                          norte-americanos enviaram o astronauta
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text55">
                          Alan Bartlett Shepard Jr.
                        </span>
                        <span>
                          , que realizou um voo suborbital (que não completa uma
                          volta na órbita terrestre), por 15 minutos, a bordo
                          da Freedom 7.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          E dois anos depois,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text60">
                          a primeira mulher foi enviada ao espaço
                        </span>
                        <span>
                          , também pelos soviéticos! Chamava-se
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text62">
                          Valentina Vladimirovna Tereshkova
                        </span>
                        <span> e foi enviada para o espaço em 1963.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%205-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name66"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona5-slider-slide6 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 2/pagina%203%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona5-text64 Titulo">
                        Cooperação espacial
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona5-text65 Texto">
                        <span>
                          A corrida espacial chega ao
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text67">
                          fim em 17 de julho de 1975.
                        </span>
                        <br className="pag4-sanfona5-text68"></br>
                        <br></br>
                        <span>
                          Quando astronautas norte-americanos e soviéticos
                          realizaram uma missão espacial em cooperação: as naves
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona5-text71">
                          Apollo 18 e Soyuz 19
                        </span>
                        <span> acoplaram-se no espaço.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 2/pagina%204%20slide%202%20imagem%206-1200w.png"
                  imageSrc1="/Pagina 4/slide 2/pagina%204%20slide%202%20mobile%20imagem%206-1500h.png"
                  rootClassName="modelo-slideroot-class-name67"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag4-sanfona5-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag4-sanfona5-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag4-sanfona5-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag4Sanfona5.defaultProps = {
  rootClassName: '',
}

Pag4Sanfona5.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pag4Sanfona5
