import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import HeroPages from '../components/hero-pages'
import Pag1Sanfona1 from '../components/pag1-sanfona1'
import Pag1Sanfona2 from '../components/pag1-sanfona2'
import Pag1Sanfona3 from '../components/pag1-sanfona3'
import Pag1Sanfona4 from '../components/pag1-sanfona4'
import Pag1Sanfona5 from '../components/pag1-sanfona5'
import Pag1Sanfona6 from '../components/pag1-sanfona6'
import SeoFinal from '../components/seo-final'
import './pagina-1.css'

const Pagina1 = (props) => {
  return (
    <div className="pagina1-container1">
      <Helmet>
        <title>Pagina-1 - MIRT</title>
        <meta property="og:title" content="Pagina-1 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name1"></Navbar>
      <HeroPages></HeroPages>
      <div className="pagina1-container2">
        <Pag1Sanfona1 rootClassName="pag1-sanfona1root-class-name"></Pag1Sanfona1>
        <Pag1Sanfona2
          text={
            <Fragment>
              <span className="pagina1-text10 Texto">
                <span>
                  Vimos no vídeo que a robótica também está presente em
                  trabalhos no fundo do mar, tornando a vida dos humanos mais
                  seguras ou, ainda, conseguindo realizar operações que antes
                  eram impossíveis.
                </span>
                <br></br>
                <br></br>
                <span className="pagina1-text14">
                  Uma das atividade da robótica no fundo do mar é ajudar no
                  mapeamento dos oceanos.
                </span>
                <br></br>
                <br></br>
                <span>
                  Olha só esse robô uOne, projetado pela empresa belga uWare
                  Robotics. Ele promete assumir o lugar de mergulhadores humanos
                  na execução de tarefas arriscadas. O robô foi desenvolvido
                  principalmente para a inspeção de estruturas subaquáticas.
                </span>
              </span>
            </Fragment>
          }
          text1={
            <Fragment>
              <span className="pagina1-text18 Texto">
                <span className="pagina1-text19">
                  Ele consegue criar mapas tridimensionais dos locais onde está
                  passando.
                </span>
                <span>
                  {' '}
                  Com um dispositivo SSD integrado, é possível coletar dados
                  como imagens RGB de alta resolução, leituras de temperatura e
                  profundidade e baixá-los assim que o robô estiver de volta à
                  base.
                </span>
              </span>
            </Fragment>
          }
          text2={
            <Fragment>
              <span className="pagina1-text21 Texto">
                <span>
                  Seu peso é de 15 kg, com capacidade para carregar até 5 kg de
                  sensores, luzes ou outros itens. A velocidade máxima do robô é
                  de 3 nós (5,5 km/h) e ele tem autonomia de funcionamento de,
                  aproximadamente, duas horas, com uma carga estimada em duas
                  horas de bateria trocável.
                </span>
                <br></br>
                <br></br>
                <span>Ficou com mais curiosidade ainda?</span>
                <br></br>
                <span className="pagina1-text27">
                  Dá uma olhada aqui no site do fabricante.
                </span>
              </span>
            </Fragment>
          }
          heading={
            <Fragment>
              <span className="pagina1-text28 Titulo-Extra">
                <span>Mapeando o </span>
                <span>fundo do oceano</span>
              </span>
            </Fragment>
          }
          heading1={
            <Fragment>
              <span className="pagina1-text31 Subtitulo-A">
                <span>
                  O
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text33">uOne</span>
                <span>
                  {' '}
                  tem 40 cm de altura, 40 cm de largura e 50 cm de comprimento
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag1-sanfona2root-class-name"
        ></Pag1Sanfona2>
        <Pag1Sanfona3
          text3={
            <Fragment>
              <span className="pagina1-text35 Texto">
                <span>
                  Segundo o IBGE (dados de 2022), a parcela dos domicílios
                  brasileiros com acesso à internet saltou de 70,9% em 2016 para
                  91,5% em 2022. Ao todo, 161,6 milhões de pessoas de 10 anos ou
                  mais usaram a rede no ano passado, o que correspondia a 87,2%
                  da população nessa faixa etária.
                </span>
                <br></br>
                <br></br>
                <span>
                  Embora a internet esteja disponível na maior parte dos lares
                  brasileiros, um estudo recente deu conta de que apenas 22% da
                  população brasileira possui níveis satisfatórios de
                  conectividades.
                </span>
                <br></br>
                <br></br>
                <span>Quer entender melhor sobre isso?</span>
                <br></br>
                <span className="pagina1-text44">
                  Dá uma olhada nessa matéria da Agência Brasil.
                </span>
              </span>
            </Fragment>
          }
          text4={
            <Fragment>
              <span className="pagina1-text45 Texto">
                <span>
                  Se você for jovem, talvez não reconheça esse som. Mas
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text47">
                  há nem tanto tempo, a internet era discada.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  Pergunte para alguém com 25 anos ou acima se ela reconhece
                  esse sonzinho ao lado. E peça para te contar essa história.
                </span>
              </span>
            </Fragment>
          }
          text6={
            <Fragment>
              <span className="pagina1-text49 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text7={
            <Fragment>
              <span className="pagina1-text50 Texto">
                <span>Quer conhecer mais sobre a história da internet? </span>
                <span className="pagina1-text52">
                  Dá uma olhada nesse conteúdo do TecMundo.
                </span>
              </span>
            </Fragment>
          }
          text8={
            <Fragment>
              <span className="pagina1-text53 Subtitulo-A">
                <span>
                  Hoje a internet está
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text55">
                  disponível na maior parte dos lares brasileiros
                </span>
              </span>
            </Fragment>
          }
          rootClassName="pag1-sanfona3root-class-name"
        ></Pag1Sanfona3>
        <Pag1Sanfona4
          text3={
            <Fragment>
              <span className="pagina1-text56 Texto">
                <span>
                  A Soft Robotic trata da
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text58">
                  construção de robôs a partir de materiais altamente
                  complacentes, semelhantes aos encontrados em organismos vivos.
                </span>
                <br></br>
                <br></br>
                <span>
                  No entanto, alguns dispositivos flexíveis podem depender de
                  sistemas de controle eletrônicos rígidos, e dispositivos
                  totalmente flexíveis permanecem restritos em suas capacidades.
                </span>
                <br></br>
                <br></br>
                <span className="pagina1-text64">Veja mais nesse artigo.</span>
              </span>
            </Fragment>
          }
          text8={
            <Fragment>
              <span className="pagina1-text65 Subtitulo-A">
                <span>
                  A
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text67">Soft Robotic</span>
                <span>
                  {' '}
                  ou
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text69">Robótica Mole</span>
                <span> é o subcampo específico da robótica</span>
              </span>
            </Fragment>
          }
          rootClassName="pag1-sanfona4root-class-name"
        ></Pag1Sanfona4>
        <Pag1Sanfona5
          text6={
            <Fragment>
              <span className="pagina1-text71 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text7={
            <Fragment>
              <span className="pagina1-text72 Texto">
                <span>
                  No vídeo falamos do livro
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text74">“Eu, Robô”</span>
                <span>.</span>
                <br></br>
                <br></br>
                <span>
                  Um clássico que cunhou o nome utilizado até hoje. Quer saber
                  mais?
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text79">
                  Acesse a página do livro no site da Editora Aleph.
                </span>
                <br></br>
                <br></br>
                <span>
                  O
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text83">“Eu, Robô”</span>
                <span>
                  {' '}
                  também virou filme dirigido por Alex Proyas, baseado em contos
                  do livro homônimo de Isaac Asimov. Foi lançado em 2004,
                  estrelado por Will Smith, com classificação indicativa de 10
                  anos.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          text31={
            <Fragment>
              <span className="pagina1-text86 Texto">
                <span>Quer conhecer mais sobre essa história?</span>
                <br></br>
                <span className="pagina1-text89">
                  Dá uma olhada nesse vídeo do TecMundo.
                </span>
              </span>
            </Fragment>
          }
          text71={
            <Fragment>
              <span className="pagina1-text90 Texto">
                Está disponível nos stremings
              </span>
            </Fragment>
          }
          text81={
            <Fragment>
              <span className="pagina1-text91 Subtitulo-B">
                <span className="pagina1-text92">
                  Um dos primeiros computadores foi o ENIAC.
                </span>
                <span> Criado em 1946, ele pesava mais de 30 toneladas.</span>
              </span>
            </Fragment>
          }
          text711={
            <Fragment>
              <span className="pagina1-text94 Texto">
                <span>
                  {' '}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina1-text96">Disney +</span>
                <span>.</span>
              </span>
            </Fragment>
          }
          text713={
            <Fragment>
              <span className="pagina1-text98 Texto">Prime Vídeo</span>
            </Fragment>
          }
          rootClassName="pag1-sanfona5root-class-name"
        ></Pag1Sanfona5>
        <Pag1Sanfona6 rootClassName="pag1-sanfona6root-class-name"></Pag1Sanfona6>
      </div>
      <div className="pagina1-sugestao-conteudo DefaultPadding">
        <div className="pagina1-interno DefaultInnerPadding MaxWidthMenor">
          <div className="pagina1-texto-ei">
            <span className="pagina1-text99 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina1-text100 Texto">
              <span>
                Clique para
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pagina1-text102">
                baixar o material pedagógico complementar
              </span>
              <span>
                {' '}
                com sugestões de atividades para serem feitas na escola.
              </span>
            </span>
            <Link
              to="/formulario"
              className="pagina1-button Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina1-container3">
            <img
              alt="image"
              src="/Pagina Home/sugestao%20atvd%20polvo-1200w.png"
              className="pagina1-polvo"
            />
            <img
              alt="image"
              src="/Pagina Home/sugestao%20atvd%20elementos%201-400w.png"
              className="pagina1-elementos"
            />
          </div>
        </div>
        <img
          alt="image"
          src="/Pagina Home/sugestao%20atvd%20papel%20azul-1500w.png"
          className="pagina1-fundo"
        />
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name"
      ></SeoFinal>
    </div>
  )
}

export default Pagina1
