import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag4-sanfona6.css'

const Pag4Sanfona6 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag4-sanfona6-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona6-topo">
        <span className="pag4-sanfona6-text10 Titulo-Extra-B">
          Fique por dentro
        </span>
        <div className="pag4-sanfona6-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona6-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona6-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag4-sanfona6-container2">
          <div className="pag4-sanfona6-container3">
            <div className="pag4-sanfona6-container4">
              <div className="pag4-sanfona6-container5">
                <span>
                  {props.text6 ?? (
                    <Fragment>
                      <span className="pag4-sanfona6-text56 Texto-Extra">
                        Indicação
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="pag4-sanfona6-container6">
                <img
                  alt={props.imageAlt}
                  src={props.imageSrc}
                  className="pag4-sanfona6-image1 HideOnMobile Radius"
                />
                <img
                  alt={props.imageAlt2}
                  src={props.imageSrc2}
                  className="pag4-sanfona6-image2 Radius ShowOnMobile"
                />
                <span>
                  {props.text7 ?? (
                    <Fragment>
                      <span className="pag4-sanfona6-text40 Texto">
                        <span>
                          O programa
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text42">
                          Muito Além dos Robôs
                        </span>
                        <span>
                          {' '}
                          é um bate-papo mensal sobre as diferentes
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text44">
                          aplicações da robótica na vida das pessoas
                        </span>
                        <span>
                          , com entrevistas e muitas ideias para os projetos de
                          inovação das equipes.
                        </span>
                        <br></br>
                        <br></br>
                        <span>Acesso disponível nas plataformas:</span>
                        <br></br>
                        <span className="pag4-sanfona6-text50">Spotify</span>
                        <span>
                          ,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text52">
                          Google Podcasts
                        </span>
                        <span>
                          {' '}
                          e
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text54">Deezer</span>
                        <span>.</span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <div className="pag4-sanfona6-container7">
                <img
                  alt={props.imageAlt1}
                  src={props.imageSrc1}
                  className="pag4-sanfona6-image3 HideOnMobile Radius"
                />
                <img
                  alt={props.imageAlt11}
                  src={props.imageSrc11}
                  className="pag4-sanfona6-image4 Radius ShowOnMobile"
                />
                <span>
                  {props.text71 ?? (
                    <Fragment>
                      <span className="pag4-sanfona6-text32 Texto">
                        <span>
                          O canal
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text34">
                          Sou Robótica
                        </span>
                        <span>
                          {' '}
                          é sobre robótica educacional, além de ter o mesmo nome
                          de um programa de computador famoso, o qual foi um
                          marco da iniciação tecnológica.
                        </span>
                        <br></br>
                        <span>
                           Acesse disponível no
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona6-text38">
                          canal de Youtube
                        </span>
                        <span>.</span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="pag4-sanfona6-container8">
            <div className="pag4-sanfona6-container9">
              <h1>
                {props.heading1111 ?? (
                  <Fragment>
                    <h1 className="pag4-sanfona6-text29 Subtitulo-A">
                      <span>
                        E já que falamos de
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag4-sanfona6-text31">
                        design e arte...
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span>
                  Aqui seguem algumas dicas superlegais de colagistas digitais e
                  artesanais. É só acessar as plataformas disponíveis: Cássia
                  Roriz (
                </span>
                <span className="pag4-sanfona6-text17">Site</span>
                <span>
                  {' '}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona6-text19">Behance</span>
                <span>), Pati Peccin (</span>
                <span className="pag4-sanfona6-text21">Site</span>
                <span>
                  {' '}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona6-text23">Youtube</span>
                <span>) e Gab Sánchez (</span>
                <span className="pag4-sanfona6-text25">Site</span>
                <span>
                  {' '}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona6-text27">Behance</span>
                <span>).</span>
              </span>
            </div>
            <img
              alt="image"
              src="/Pagina 4/imagens/pagina%204%20imagem%2013-1500w.png"
              className="pag4-sanfona6-image5"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Pag4Sanfona6.defaultProps = {
  heading1111: undefined,
  text71: undefined,
  imageSrc2: '/Pagina 4/imagens/pagina%204%20imagem%2011%20mobile-1500w.png',
  text7: undefined,
  imageAlt: 'image',
  imageSrc: '/Pagina 4/imagens/pagina%204%20imagem%2011-1500w.png',
  text6: undefined,
  imageSrc11: '/Pagina 4/imagens/pagina%204%20imagem%2012%20mobile-1500w.png',
  imageSrc1: '/Pagina 4/imagens/pagina%204%20imagem%2012-1500w.png',
  imageAlt2: 'image',
  imageAlt1: 'image',
  imageAlt11: 'image',
  rootClassName: '',
}

Pag4Sanfona6.propTypes = {
  heading1111: PropTypes.element,
  text71: PropTypes.element,
  imageSrc2: PropTypes.string,
  text7: PropTypes.element,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
  text6: PropTypes.element,
  imageSrc11: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt2: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageAlt11: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Pag4Sanfona6
