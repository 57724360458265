import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag6-sanfona1.css'

const Pag6Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag6-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag6-sanfona1-topo">
        <span className="pag6-sanfona1-text10 Titulo-Extra-B">
          Planeta dos robôs
        </span>
        <div className="pag6-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag6-sanfona1-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag6-sanfona1-container3">
          <a
            href="https://www.iberdrola.com/inovacao/androides"
            target="_blank"
            rel="noreferrer noopener"
            className="pag6-sanfona1-link DefaultInnerPadding Texto"
          >
            <span>
              Vamos passar pela ordem cronológica da
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag6-sanfona1-text12">evolução da robótica</span>
            <span>
              {' '}
              do último século, quando esses e outros avanços apenas começaram.
              Sim, ainda tem muita coisa pra descobrir e desenvolver. Gostou do
              assunto e quer saber mais detalhes sobre os marcos históricos da
              robótica e da tecnologia?
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="pag6-sanfona1-text14">
              Dá uma olhada aqui no site da Iberdrola.
            </span>
            <br></br>
          </a>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag6-sanfona1-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag6-sanfona1-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 6/slide 1/pagina%206%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag6-sanfona1-text16 Titulo">1920</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag6-sanfona1-text17 Texto">
                        <span>
                          O termo
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text19">
                          &quot;robô&quot;
                        </span>
                        <span>
                          {' '}
                          aparece pela primeira vez em uma peça de teatro do
                          escritor checo
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text21">
                          Karel Capek, o qual se antecipou a muitos dos debates
                          gerados pela IA.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 6/slide 1/pagina%206%20slide%201%20imagem%201-1500h.png"
                  imageSrc1="/Pagina 6/slide 1/pagina%206%20slide%201%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name79"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag6-sanfona1-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 6/slide 1/pagina%206%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag6-sanfona1-text22 Titulo">1939</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag6-sanfona1-text23 Texto">
                        <span>
                          Durante a Exposição Universal, exibe-se, pela
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text25">
                          primeira vez, um robô humanoide chamado Elektro
                        </span>
                        <span>
                          {' '}
                          desenvolvido pela Westinghouse Electric Corporation.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 6/slide 1/pagina%206%20slide%201%20imagem%202-1500h.png"
                  imageSrc1="/Pagina 6/slide 1/pagina%206%20slide%201%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name80"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag6-sanfona1-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 6/slide 1/pagina%206%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag6-sanfona1-text27 Titulo">2000</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag6-sanfona1-text28 Texto">
                        <span>
                          A Honda cria o
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text30">
                          ASIMO, o primeiro robô humanoide capaz de caminhar
                          como um bípede
                        </span>
                        <span>
                          {' '}
                          e, inclusive, interagir com pessoas em um nível social
                          básico.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 6/slide 1/pagina%206%20slide%201%20imagem%203-1500h.png"
                  imageSrc1="/Pagina 6/slide 1/pagina%206%20slide%201%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name81"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag6-sanfona1-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 6/slide 1/pagina%206%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag6-sanfona1-text32 Titulo">2011</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag6-sanfona1-text33 Texto">
                        <span>
                          A Apple introduz o
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text35">
                          assistente de Voz Siri
                        </span>
                        <span>
                          , revolucionando o mundo da telefonia celular e
                          criando um
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text37">
                          sistema informático capaz de interagir com seres
                          humanos.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 6/slide 1/pagina%206%20slide%201%20imagem%204-1500h.png"
                  imageSrc1="/Pagina 6/slide 1/pagina%206%20slide%201%20mobile%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name82"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag6-sanfona1-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 6/slide 1/pagina%206%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag6-sanfona1-text38 Titulo">2020</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag6-sanfona1-text39 Texto">
                        <span>
                          A indústria dos
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text41">drones</span>
                        <span>
                          , recentemente criada, ganha cada vez mais
                          importância, graças às
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag6-sanfona1-text43">
                          diversas funções de serviços que oferece.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 6/slide 1/pagina%206%20slide%201%20imagem%205-1500h.png"
                  imageSrc1="/Pagina 6/slide 1/pagina%206%20slide%201%20mobile%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name83"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag6-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag6-sanfona1-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag6-sanfona1-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag6Sanfona1.defaultProps = {
  rootClassName: '',
}

Pag6Sanfona1.propTypes = {
  rootClassName: PropTypes.string,
}

export default Pag6Sanfona1
