import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag2-sanfona4.css'

const Pag2Sanfona4 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag2-sanfona4-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag2-sanfona4-topo">
        <span className="pag2-sanfona4-text10 Titulo-Extra-B">
          Tubarões: mais velhos que dinossauros
        </span>
        <div className="pag2-sanfona4-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona4-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona4-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag2-sanfona4-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag2-sanfona4-container3">
          <div className="pag2-sanfona4-container4">
            <span>
              {props.text32 ?? (
                <Fragment>
                  <span className="pag2-sanfona4-text63 Texto">
                    <span>
                      O
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona4-text65">
                      arquipélago das Maldivas
                    </span>
                    <span>
                      {' '}
                      é o paraíso dos mergulhadores. Suas águas cristalinas têm
                      uma visibilidade incrível e o clima é bom o ano todo. Ah e
                      está
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona4-text67">
                      repleto de tubarões
                    </span>
                    <span>
                      , incluindo 26 espécies diferentes! Tem tubarões-raposo,
                      tubarões-baleia, tubarões-lixa e tubarões-tigre.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      E mergulhar com esses seres marinhos pode até ser uma
                      experiência incrível. Mas, com certeza, tem que ser muito
                      experiente pra entrar numa aventura dessa, né gente?
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <img
            alt={props.imageAlt2}
            src={props.imageSrc2}
            className="pag2-sanfona4-image"
          />
        </div>
        <div className="pag2-sanfona4-container5">
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag2-sanfona4-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag2-sanfona4-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 2/pagina%202%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona4-text12 Titulo">
                        Nosso planeta é cheio de lugares maravilhosos para
                        mergulhar, assim como também é cheio de tubarões
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona4-text13 Texto">
                        <span>
                           Os especialistas falam em uma
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text15">
                          população de 8 bilhões!
                        </span>
                        <span>
                          {' '}
                          Eles estão reunidos em
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text17">
                          500 espécies diferentes:
                        </span>
                        <span>
                          {' '}
                          tem o tubarão-lanterna-anão, que é menor que uma mão
                          humana, e o tubarão-baleia, que pode crescer até ficar
                          do tamanho de um ônibus escolar.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          E olha só que legal:
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text22">
                          os tubarões têm um dos períodos de vida mais longos
                        </span>
                        <span> do reino animal.</span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 2/pagina%201%20slide%202%20imagem%201-1200w.png"
                  imageSrc1="/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name26"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona4-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 2/pagina%202%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona4-text25 Titulo">
                        Sim, os tubarões são mais velhos que os dinossauros!
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona4-text26 Texto">
                        <span>
                          Pesquisadores, usando datação por radiocarbono,
                          determinaram que as espécies do Atlântico Norte
                          provavelmente
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text28">
                          vivem em média 272 anos e geralmente não atingem a
                          maturidade até os 150 anos de idade.
                        </span>
                        <br className="pag2-sanfona4-text29"></br>
                        <br></br>
                        <span>
                          A evidência mais antiga de fósseis de tubarão data de
                          450 milhões de anos, o que significa que essas
                          criaturas
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text32">
                          existem há, pelo menos, 190 milhões de anos antes dos
                          dinossauros.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 2/pagina%201%20slide%202%20imagem%202-1200w.png"
                  imageSrc1="/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name31"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona4-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 2/pagina%202%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona4-text33 Titulo">
                        Os tubarões sobreviveram a cinco extinções em massa
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona4-text34 Texto">
                        <span>
                          Uma das quais
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text36">
                          eliminou cerca de 96% de toda a vida marinha.
                        </span>
                        <span>
                          {' '}
                          Tomara que sobreviva também ao nosso tempo, com a
                          pesca predatória, o plástico nos oceanos, os esgotos
                          sem tratamento jogados no mar, a escassez de alimento
                          etc.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Costuma-se falar muito dos ataques que ocorrem com os
                          tubarões, diga-se de passagem, muitos deles
                          decorrentes dos
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text41">
                          desequilíbrios ecológicos
                        </span>
                        <span>
                          {' '}
                          causados pelos habitantes humanos desse planeta.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 2/pagina%201%20slide%202%20imagem%203-1200w.png"
                  imageSrc1="/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name32"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona4-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 2/pagina%202%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona4-text44 Titulo">
                        Mas você sabia que os tubarões são úteis para o nosso
                        meio ambiente?
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona4-text45 Texto">
                        <span className="pag2-sanfona4-text46">
                          Pesquise e se informe!
                        </span>
                        <span>
                          {' '}
                          Dá uma procurada no Google sobre o tubarão-tigre, por
                          exemplo.
                        </span>
                        <br></br>
                        <span>
                          Outra coisa legal que você precisa saber: algumas
                          espécies, como o tubarão-cobra e o tubarão-frade,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text50">
                          podem ficar grávidas por mais de três anos.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          E infelizmente, os longos períodos de gravidez e o
                          fato de alguns tubarões levarem de 10 a 12 anos para
                          atingir a maturidade sexual, como o tubarão-branco,
                          impactam ainda mais as populações dizimadas por
                          práticas pesqueiras insustentáveis.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 2/pagina%201%20slide%202%20imagem%204-1200w.png"
                  imageSrc1="/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name33"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona4-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 2/pagina%202%20slide%202%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona4-text54 Titulo">
                        Os tubarões são criaturas bem silenciosas
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona4-text55 Texto">
                        <span>
                          Diferente das baleias e dos golfinhos,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text57">
                          não vocalizam
                        </span>
                        <span>
                          , pois não possuem órgãos para produzir som. Mas eles
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona4-text59">
                          se comunicam por meio da linguagem corporal, como
                          ziguezaguear, sacudir e mover as mandíbulas.
                        </span>
                        <br className="pag2-sanfona4-text60"></br>
                        <br></br>
                        <span>
                          E mais! Eles podem captar correntes eletromagnéticas
                          nanoscópicas, um ‘poder’ que pode ajudá-los a navegar
                          no oceano e encontrar uma presa, ou até mesmo um
                          companheiro. Com esse sentido apurado, os tubarões
                          caçam animais doentes e fracos, desempenhando um papel
                          crucial na manutenção da saúde do ecossistema marinho.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 2/pagina%201%20slide%202%20imagem%205-1200w.png"
                  imageSrc1="/Pagina 2/Slide 2/pagina%202%20mobile%20slide%202%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name34"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag2-sanfona4-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag2-sanfona4-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag2-sanfona4-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag2Sanfona4.defaultProps = {
  rootClassName: '',
  text32: undefined,
  imageAlt2: 'image',
  imageSrc2: '/Pagina 2/Imagens/pagina%202%20imagem%207-1500w.png',
}

Pag2Sanfona4.propTypes = {
  rootClassName: PropTypes.string,
  text32: PropTypes.element,
  imageAlt2: PropTypes.string,
  imageSrc2: PropTypes.string,
}

export default Pag2Sanfona4
