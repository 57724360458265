import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag2-sanfona3.css'

const Pag2Sanfona3 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag2-sanfona3-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag2-sanfona3-topo">
        <span className="pag2-sanfona3-text10 Titulo-Extra-B">
          Vamos falar de sustentabilidade?
        </span>
        <div className="pag2-sanfona3-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona3-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona3-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag2-sanfona3-container2">
          <div className="pag2-sanfona3-container3">
            <div className="pag2-sanfona3-container4">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag2-sanfona3-text16 Subtitulo-A">
                      <span>
                        Para conservarmos essa beleza e todas essas curiosidades
                        do nosso planeta,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text18">
                        o jeito é cada vez mais investir em tecnologia,
                        conhecimento e inovação.
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span>
                {props.text31 ?? (
                  <Fragment>
                    <span className="pag2-sanfona3-text39 Texto">
                      <span>
                        Um dos meios de se obter energia elétrica consiste no
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text41">
                        aproveitamento da energia cinética das partículas de ar
                        em movimento. Ou seja, do vento!
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        Essa energia é conhecida como
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text43">
                        Energia Eólica
                      </span>
                      <span>
                        . Hoje existem cerca de 30 mil turbinas dessa natureza
                        espalhadas pelo mundo, com uma capacidade de 13000 MW de
                        produção! É muito, gente!
                      </span>
                      <br></br>
                      <br></br>
                      <span className="pag2-sanfona3-text47">
                        A primeira turbina eólica comercial ligada à rede
                        elétrica pública foi instalada em 1976, na Dinamarca.
                      </span>
                      <span>
                        {' '}
                        Atualmente, existem mais de 30 mil turbinas eólicas em
                        operação no mundo.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Um sistema de geração de energia elétrica baseado no
                        aproveitamento de energia eólica funciona basicamente da
                        seguinte forma: as partículas do ar em movimento possuem
                        energia cinética. Ao colidirem com as hélices de uma
                        turbina, elas provocam a rotação de um eixo acoplado a
                        um gerador elétrico. O gerador converte o trabalho
                        mecânico, relacionado à rotação do eixo, em trabalho
                        elétrico,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text52">
                        utilizado para movimentar as cargas negativas em um
                        condutor elétrico, o que produz corrente elétrica.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.imageAlt1}
              src={props.imageSrc1}
              className="pag2-sanfona3-image1"
            />
          </div>
          <div className="pag2-sanfona3-container5">
            <div className="pag2-sanfona3-container6">
              <span>
                {props.text1 ?? (
                  <Fragment>
                    <span className="pag2-sanfona3-text19 Subtitulo-A">
                      <span>
                        Mas não é só na Dinamarca e nos EUA que temos tecnologia
                        sustentável de ponta!
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text21">
                        Aqui no Brasil, tem também.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
              <span>
                {props.text11 ?? (
                  <Fragment>
                    <span className="pag2-sanfona3-text25 Texto">
                      <span>
                        Santa Catarina possui três parques eólicos operando em
                        seu território:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text27">
                        o parque eólico de Bom Jardim da Serra, instalado na
                        cidade de Bom Jardim da Serra, e os de Água Doce e
                        Horizonte
                      </span>
                      <span>
                        , que possuem 15 e 18 aerogeradores respectivamente,
                        ambos instalados na cidade de Água Doce.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="pag2-sanfona3-image2"
            />
          </div>
          <div className="pag2-sanfona3-container7">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="pag2-sanfona3-image3"
            />
            <div className="pag2-sanfona3-container8">
              <h1>
                {props.heading11 ?? (
                  <Fragment>
                    <h1 className="pag2-sanfona3-text22 Subtitulo-A">
                      <span>
                        Mas o que tem a ver a
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text24">
                        energia eólica com o meio ambiente, as tartarugas
                        gigantes e os tubarões?
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <a
                href="https://odsbrasil.gov.br/?"
                target="_blank"
                rel="noreferrer noopener"
                className="pag2-sanfona3-link"
              >
                {props.text3 ?? (
                  <Fragment>
                    <span className="pag2-sanfona3-text29 Texto">
                      <span>
                        Bom, ao contrário de outras formas de obtenção de
                        energia elétrica,
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text31">
                        os geradores eólicos causam poucos impactos ao meio
                        ambiente, pois não poluem a atmosfera com gases
                        causadores do efeito estufa.
                      </span>
                      <span>
                        {' '}
                        Seus impactos ambientais se resumem à poluição sonora e
                        visual. E sim, sobre esses efeitos, há muito ainda a ser
                        estudado e aprimorado. Estamos
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text33">
                        caminhando para um futuro em que tecnologia e natureza
                        se complementem e construam
                      </span>
                      <span> um tempo de cocriação possível.</span>
                      <br></br>
                      <br></br>
                      <span>
                        Você pode começar por conhecer mais de perto as ODS.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag2-sanfona3-text38">
                        Que tal dar uma olhada no site da ODS Brasil?
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag2Sanfona3.defaultProps = {
  rootClassName: '',
  imageSrc: '/Pagina 2/Imagens/pagina%202%20imagem%206-1500w.png',
  heading111: undefined,
  imageAlt: 'image',
  text1: undefined,
  imageSrc1: '/Pagina 2/Imagens/pagina%202%20imagem%204-1500w.png',
  heading11: undefined,
  imageSrc3: '/Pagina 2/Imagens/pagina%202%20imagem%205-1500w.png',
  text11: undefined,
  text3: undefined,
  imageAlt3: 'image',
  text31: undefined,
  imageAlt1: 'image',
}

Pag2Sanfona3.propTypes = {
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  heading111: PropTypes.element,
  imageAlt: PropTypes.string,
  text1: PropTypes.element,
  imageSrc1: PropTypes.string,
  heading11: PropTypes.element,
  imageSrc3: PropTypes.string,
  text11: PropTypes.element,
  text3: PropTypes.element,
  imageAlt3: PropTypes.string,
  text31: PropTypes.element,
  imageAlt1: PropTypes.string,
}

export default Pag2Sanfona3
