import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag3-sanfona4.css'

const Pag3Sanfona4 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag3-sanfona4-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag3-sanfona4-topo">
        <span className="pag3-sanfona4-text10 Titulo-Extra-B">Cão robô?</span>
        <div className="pag3-sanfona4-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona4-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona4-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag3-sanfona4-container2">
          <div className="pag3-sanfona4-container3 Radius">
            <div className="pag3-sanfona4-container4 Radius">
              <div className="pag3-sanfona4-container5">
                <h1 className="Titulo-Extra">
                  <span className="pag3-sanfona4-text12">O </span>
                  <span>CyberDog 2</span>
                  <span className="pag3-sanfona4-text14">
                     é o novo modelo de cão-robô lançado pela Xiaomi
                  </span>
                </h1>
                <span className="Texto">
                  <span>
                    E criado para fazer companhia em casa, com a capacidade de
                    dar saltos para trás, fazer passos de dança, balançar o
                    traseiro e até mesmo andar de skate.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag3-sanfona4-text17">
                    O robô pesa 9 kg e mede cerca de 36 cm.
                  </span>
                </span>
              </div>
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="pag3-sanfona4-image1 Radius"
              />
            </div>
          </div>
          <div className="pag3-sanfona4-container6">
            <div className="pag3-sanfona4-container7">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag3-sanfona4-text33 Subtitulo-A">
                      <span className="pag3-sanfona4-text34">
                        Você se lembra ou chegou a ver os
                      </span>
                      <span> Jetsons?</span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span className="pag3-sanfona4-text20">
                  Os Jetsons era um desenho animado dos anos 60.
                </span>
                <br></br>
                <span>
                  Uma simpática e divertida família que vivia no ano de 2062.
                  Eles realizavam
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona4-text23">
                  videochamadas, andavam em carros voadores, tinham uma robô que
                  executava todas as tarefas domésticas,
                </span>
                <span>
                  {' '}
                  enfim, uma família do futuro! Ah e tinham um cãozinho amigo
                  também, o Astro. Mas ele não era robô não, era de carne e
                  osso! E o mais incrível é que logo, logo, esse futuro já será
                  passado... Assim como os Jetsons!
                </span>
              </span>
              <div className="pag3-sanfona4-container8">
                <span className="Texto">
                  <span>
                    Atualmente, a série só passa no canal
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag3-sanfona4-text27">Tooncast</span>
                  <span>.</span>
                </span>
                <span className="Texto">
                  <span>
                    E alguns
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag3-sanfona4-text31">
                    episódios estão disponíveis no YouTube
                  </span>
                  <span>.</span>
                </span>
              </div>
            </div>
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%206-1500w.webp"
              className="pag3-sanfona4-image2"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Pag3Sanfona4.defaultProps = {
  imageSrc: '/Pagina 3/imagens/pagina%203%20imagem%205%20mobile-1500w.png',
  imageAlt: 'image',
  heading111: undefined,
  rootClassName: '',
}

Pag3Sanfona4.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  heading111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag3Sanfona4
