import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Pag5Sanfona1 from '../components/pag5-sanfona1'
import Pag5Sanfona2 from '../components/pag5-sanfona2'
import SeoFinal from '../components/seo-final'
import './pagina-5.css'

const Pagina5 = (props) => {
  return (
    <div className="pagina5-container1">
      <Helmet>
        <title>Pagina-5 - MIRT</title>
        <meta property="og:title" content="Pagina-5 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name5"></Navbar>
      <div className="pagina5-hero DefaultPadding">
        <img
          alt="image"
          src="/Geral/fundo%20topo%20pagina-700h.png"
          className="pagina5-image1"
        />
        <div className="pagina5-container2 DefaultInnerPadding">
          <div className="pagina5-container3">
            <span className="pagina5-text10">05</span>
            <div className="pagina5-container4">
              <span className="pagina5-text11 Subtitulo-A">
                <span className="pagina5-text12">Games + robótica:</span>
                <br className="pagina5-text13"></br>
                <span>Muito além do entretenimento</span>
              </span>
              <div className="pagina5-container5">
                <div className="pagina5-boto">
                  <span className="pagina5-text15 Botao">Versão acessível</span>
                </div>
                <div className="pagina5-indicaes">
                  <img
                    alt="Vector9161"
                    src="/Geral/sinais-200w.png"
                    className="pagina5-vector1"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/ingles-200w.png"
                    className="pagina5-vector2"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/descricao-200w.png"
                    className="pagina5-vector3"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="/Pagina 5/images/pagina%205%20imagem%201-1200w.png"
            className="pagina5-image2 Radius"
          />
          <div className="pagina5-container6">
            <img
              alt="Vector1068"
              src="/external/vector1068-4e2.svg"
              className="pagina5-vector4"
            />
            <span className="Observacao">
              <span className="pagina5-text17">Vá além e aprenda mais</span>
              <span> a partir dos</span>
              <br></br>
              <span>conteúdos que você assistiu no vídeo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="pagina5-container7">
        <Pag5Sanfona1
          titulo={
            <Fragment>
              <span className="pagina5-text21 Titulo">Scratch</span>
            </Fragment>
          }
          conteudo={
            <Fragment>
              <span className="pagina5-text22 Texto">
                <span>
                  É possível
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina5-text24">desenvolver os próprios</span>
                <span>
                  {' '}
                  jogos nesse aplicativo, é uma excelente forma de aprender os
                  primeiros passos de linguagem de programação para os
                  estudantes.
                </span>
                <br className="pagina5-text26"></br>
                <br className="pagina5-text27"></br>
                <span>Quer saber mais sobre esse aplicativo? </span>
                <span className="pagina5-text29">
                  Dá uma olhada no site da Scratch.
                </span>
              </span>
            </Fragment>
          }
          conteudo1={
            <Fragment>
              <span className="pagina5-text30 Texto">
                <span>
                  E olha que legal o
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina5-text32">gameplay Naruto</span>
                <span>
                  {' '}
                  desenvolvido pelo canal Gamescrever através do Scratch.
                </span>
              </span>
            </Fragment>
          }
          heading111={
            <Fragment>
              <span className="pagina5-text34 Subtitulo-A">
                <span className="pagina5-text35">
                  O
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  termo gamificação
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina5-text37">
                  se origina da expressão em inglês gamification
                </span>
              </span>
            </Fragment>
          }
          heading1111={
            <Fragment>
              <span className="pagina5-text38 DefaultInnerPadding Subtitulo-A">
                <span className="pagina5-text39">
                  Aí vão algumas das
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>ferramentas de gamificação:</span>
              </span>
            </Fragment>
          }
          rootClassName="pag5-sanfona1root-class-name"
        ></Pag5Sanfona1>
        <Pag5Sanfona2 rootClassName="pag5-sanfona2root-class-name"></Pag5Sanfona2>
      </div>
      <div className="pagina5-sugestao-conteudo DefaultPadding">
        <div className="pagina5-interno MaxWidthMenor">
          <div className="pagina5-texto-ei">
            <span className="pagina5-text41 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina5-text42 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina5-button Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <img
            alt="image"
            src="/Pagina 5/images/pagina%205%20imagem%203-1200w.png"
            className="pagina5-image3"
          />
        </div>
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name4"
      ></SeoFinal>
    </div>
  )
}

export default Pagina5
