import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag4-sanfona4.css'

const Pag4Sanfona4 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag4-sanfona4-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona4-topo">
        <span className="pag4-sanfona4-text10 Titulo-Extra-B">
          Robótica educacional
        </span>
        <div className="pag4-sanfona4-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona4-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona4-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag4-sanfona4-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag4-sanfona4-container3 DefaultInnerPadding">
          <div className="pag4-sanfona4-container4">
            <span className="Texto">
              <span>
                A robótica educacional acontece quando a diversão se encontra
                com a aprendizagem para criar um combo de
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag4-sanfona4-text13">
                conhecimento e entretenimento
              </span>
              <span>!</span>
            </span>
            <h1>
              {props.heading111 ?? (
                <Fragment>
                  <h1 className="pag4-sanfona4-text81 Subtitulo-A">
                    <span className="pag4-sanfona4-text82">
                      O que é
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>robótica educacional?</span>
                  </h1>
                </Fragment>
              )}
            </h1>
            <span className="Texto">
              <span>
                Robótica educacional é um método de
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag4-sanfona4-text18">
                aprendizagem focado na pesquisa, descoberta e construção
              </span>
              <span>
                 de uma máquina como resultado da aquisição de conhecimentos. É
                uma grande festa ou um evento muito bacana onde os robôs são os
                convidados especiais!
              </span>
              <br></br>
              <br></br>
              <span>
                Trata-se de uma abordagem de ensino que
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag4-sanfona4-text23">
                utiliza a tecnologia para engajar os alunos em atividades
                práticas e divertidas
              </span>
              <span>
                , enquanto aprendem conceitos de ciência, tecnologia, engenharia
                e matemática. E daí vem o termo STEM que vem do inglês Science,
                Technology, Engineering and Mathematics.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/Pagina 4/imagens/pagina%204%20imagem%209-1500w.png"
            className="pag4-sanfona4-image1 Radius"
          />
        </div>
        <div className="pag4-sanfona4-container5">
          <h1 className="pag4-sanfona4-text25 DefaultInnerPadding Subtitulo-A">
            <span className="pag4-sanfona4-text26">
              Você sabe qual a
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>origem da palavra STEM</span>
            <span className="pag4-sanfona4-text28">
              ? Não? Então senta que lá vem história!
            </span>
          </h1>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag4-sanfona4-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag4-sanfona4-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 4/slide 1/pagina%204%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona4-text29 Titulo">STEM</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona4-text30 Texto">
                        <span>
                          Esse papo de ensino voltado para Ciências, Tecnologia,
                          Engenharia e Matemática iniciou
                        </span>
                        <br></br>
                        <span>
                          há muito tempo. Lá na época da corrida espacial, nos
                          tempos do
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text34">
                          lançamento russo do Sputnik 1, o primeiro satélite
                          artificial
                        </span>
                        <span> da Terra.</span>
                        <br></br>
                        <br></br>
                        <span>
                          E como estamos falando de
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text39">
                          corrida espacial
                        </span>
                        <span>
                          , lá vem mais história!
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          O presidente Kennedy e as equipes de educação dos
                          Estados Unidos perceberam que estavam ficando para
                          trás e começaram a estabelecer
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text44">
                          políticas de ensino
                        </span>
                        <span>
                          {' '}
                          que focassem no
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text46">
                          desenvolvimento de tecnologia numa lógica de
                          conhecimento
                        </span>
                        <span> aplicado.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 1/pagina%204%20slide%201%20imagem%201-1200w.png"
                  imageSrc1="/Pagina 4/slide 1/pagina%204%20slide%201%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name57"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona4-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 4/slide 1/pagina%204%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona4-text48 Titulo">STEM</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona4-text49 Texto">
                        <span>
                          E a prática se espalhou por outros lugares do mundo.
                          Estamos falando da década de 70, quando o ensino e a
                          pesquisa ainda se baseavam em atividades de
                          comprovação, ou seja, ir para um laboratório comprovar
                          conceitos científicos, por exemplo, como medir a
                          gravidade ou calcular a aceleração de uma bolinha de
                          metal.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Com o passar dos anos e o desenvolvimento de novas
                          tecnologias, o
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text54">
                          STEM passou da comprovação de conceitos científicos
                          para uma cultura mais maker
                        </span>
                        <span>
                          , de colocar a mão na massa e efetivamente criar
                          coisas.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 1/pagina%204%20slide%201%20imagem%202-1200w.png"
                  imageSrc1="/Pagina 4/slide 1/pagina%204%20slide%201%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name58"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag4-sanfona4-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 4/slide 1/pagina%204%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag4-sanfona4-text56 Titulo">STEM</span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag4-sanfona4-text57 Texto">
                        <span>
                          O
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text59">
                          surgimento das impressoras 3D e das cortadoras a laser
                        </span>
                        <span>
                          , por exemplo, possibilitaram maior inovação e abriram
                          diversas possibilidades.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Antes, os alunos eram encorajados a criar soluções,
                          mas nem sempre podiam construir. Hoje, eles podem ter
                          uma ideia e,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag4-sanfona4-text64">
                          com a ajuda dessas novas tecnologias, torná-la
                          realidade.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 4/slide 1/pagina%204%20slide%201%20imagem%203-1200w.png"
                  imageSrc1="/Pagina 4/slide 1/pagina%204%20slide%201%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name59"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag4-sanfona4-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag4-sanfona4-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag4-sanfona4-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
        <div className="pag4-sanfona4-container6 DefaultInnerPadding">
          <div className="pag4-sanfona4-container7 Radius">
            <div className="pag4-sanfona4-container8">
              <div className="pag4-sanfona4-container9">
                <h1 className="pag4-sanfona4-text65 Titulo-Extra">
                  <span className="pag4-sanfona4-text66">
                    E qual a relação do
                  </span>
                  <span>
                    {' '}
                    STEM com arte e design?
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </h1>
                <span className="Texto">
                  <span>
                    O
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona4-text70">STEM</span>
                  <span>
                    {' '}
                    surgiu a partir de um movimento na universidade americana
                    RISD - Rhode Island School of Design no início dos anos
                    2000. A ideia era
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona4-text72">
                    acrescentar arte e design ao STEM
                  </span>
                  <span>.</span>
                  <br></br>
                  <br></br>
                  <span>
                    O objetivo desse movimento é
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona4-text77">incentivar</span>
                  <span>
                    {' '}
                    o ensino dessas duas disciplinas nas escolas e
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona4-text79">
                    impulsionar a inovação por meio do design
                  </span>
                  <span>
                    . É uma tendência cada vez maior de apostar na criatividade
                    e no senso artístico.
                  </span>
                </span>
              </div>
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%2010-1500w.png"
                className="pag4-sanfona4-image2 Radius"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag4Sanfona4.defaultProps = {
  rootClassName: '',
  heading111: undefined,
}

Pag4Sanfona4.propTypes = {
  rootClassName: PropTypes.string,
  heading111: PropTypes.element,
}

export default Pag4Sanfona4
