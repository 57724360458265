import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag2-sanfona6.css'

const Pag2Sanfona6 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag2-sanfona6-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag2-sanfona6-topo">
        <span className="pag2-sanfona6-text10 Titulo-Extra-B">
          Você e o futuro do nosso planeta
        </span>
        <div className="pag2-sanfona6-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona6-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag2-sanfona6-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag2-sanfona6-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag2-sanfona6-container3">
          <div className="pag2-sanfona6-container4">
            <span>
              {props.text8 ?? (
                <Fragment>
                  <span className="pag2-sanfona6-text42 Subtitulo-A">
                    <span>
                      Ok, você não é um robô, mas sabe que
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona6-text44">
                      os robôs só existem graças a nós, humanos
                    </span>
                    <span>
                      , certo?
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
            <span>
              {props.text32 ?? (
                <Fragment>
                  <span className="pag2-sanfona6-text38 Texto">
                    <span>
                      E você também sabe que não precisa “virar gente grande”
                      para contribuir na
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className="pag2-sanfona6-text40">
                      construção de um mundo melhor, mais limpo, seguro e
                      saudável.
                    </span>
                    <span>
                      {' '}
                      Aí vão algumas dicas de pequenas ações que fazem toda a
                      diferença!
                    </span>
                  </span>
                </Fragment>
              )}
            </span>
          </div>
          <img
            alt={props.imageAlt2}
            src={props.imageSrc2}
            className="pag2-sanfona6-image"
          />
        </div>
        <div className="pag2-sanfona6-container5">
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag2-sanfona6-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag2-sanfona6-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 4/pagina%202%20slider%204%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona6-text13 Titulo">
                        <span>Faça os 3 Rs:</span>
                        <br></br>
                        <span>Reduzir, Reutilizar, Reciclar</span>
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona6-text17 Texto">
                        <span>
                          É bem simples, é só
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona6-text19">
                          reutilizar os itens do dia a dia
                        </span>
                        <span>
                          {' '}
                          sempre que possível e
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona6-text21">
                          separar os materiais recicláveis
                        </span>
                        <span>, em casa e na escola.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 4/pagina%201%20slide%204%20imagem%201-1500h.png"
                  imageSrc1="/Pagina 2/Slide 4/pagina%202%20mobile%20slide%204%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name41"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona6-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 4/pagina%202%20slider%204%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona6-text23 Titulo">
                        Economize água e energia
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona6-text24 Texto">
                        <span className="pag2-sanfona6-text25">
                          Desligue as luzes e aparelhos eletrônicos quando não
                          estiver usando
                        </span>
                        <span>
                          {' '}
                          e feche a torneira enquanto escova os dentes. Ah!
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag2-sanfona6-text27">
                          Tente tomar banhos mais curtos
                        </span>
                        <span> para economizar água.</span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 4/pagina%201%20slide%204%20imagem%202-1500h.png"
                  imageSrc1="/Pagina 2/Slide 4/pagina%202%20mobile%20slide%204%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name42"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona6-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 4/pagina%202%20slider%204%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona6-text29 Titulo">
                        Meios de transporte mais sustentáveis
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona6-text30 Texto">
                        <span>
                          Sempre que der, peça aos seus responsáveis adultos que
                          troquem o carro por meios de transporte sustentáveis,
                          como caminhar, andar de bicicleta ou utilizar o
                          transporte público. Assim,
                        </span>
                        <br className="pag2-sanfona6-text32"></br>
                        <span>
                          eles estarão cuidando da sua própria saúde e do
                          planeta!
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 4/pagina%201%20slide%204%20imagem%203-1500h.png"
                  imageSrc1="/Pagina 2/Slide 4/pagina%202%20mobile%20slide%204%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name43"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona6-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 4/pagina%202%20slider%204%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona6-text34 Titulo">
                        Sustentabilidade alimentar
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona6-text35 Texto">
                        Se tiver um espacinho de terra em casa ou na escola,
                        procure criar e cuidar de uma horta ou jardim, assim
                        você vai contribuir para o meio ambiente e ainda
                        aprender sobre a sustentabilidade alimentar.
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 4/pagina%201%20slide%204%20imagem%204-1500h.png"
                  imageSrc1="/Pagina 2/Slide 4/pagina%202%20mobile%20slide%204%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name44"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag2-sanfona6-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 2/Slide 4/pagina%202%20slider%204%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag2-sanfona6-text36 Titulo">
                        Evite o desperdício de alimentos
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag2-sanfona6-text37 Texto">
                        Coloque no prato o necessário pra saciar a sua fome, e
                        planeje comer apenas nas refeições (mas claro que, às
                        vezes, pode comer uma coisinha entre as refeições se
                        bater aquela supervontade, né?). Ah! E convide a família
                        e os amigos para criar uma composteira para os resíduos
                        orgânicos.
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 2/Slide 4/pagina%201%20slide%204%20imagem%205-1500h.png"
                  imageSrc1="/Pagina 2/Slide 4/pagina%202%20mobile%20slide%204%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name45"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag2-sanfona6-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag2-sanfona6-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag2-sanfona6-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag2Sanfona6.defaultProps = {
  imageAlt2: 'image',
  rootClassName: '',
  text32: undefined,
  imageSrc2: '/Pagina 2/Imagens/pagina%202%20imagem%209-1500w.png',
  text8: undefined,
}

Pag2Sanfona6.propTypes = {
  imageAlt2: PropTypes.string,
  rootClassName: PropTypes.string,
  text32: PropTypes.element,
  imageSrc2: PropTypes.string,
  text8: PropTypes.element,
}

export default Pag2Sanfona6
