import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag4-sanfona2.css'

const Pag4Sanfona2 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag4-sanfona2-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag4-sanfona2-topo">
        <span className="pag4-sanfona2-text10 Titulo-Extra-B">Ubbu</span>
        <div className="pag4-sanfona2-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona2-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag4-sanfona2-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag4-sanfona2-container2">
          <div className="pag4-sanfona2-container3">
            <div className="pag4-sanfona2-container4 Radius">
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%205-1500w.png"
                className="pag4-sanfona2-image1 Radius"
              />
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%204-1500w.png"
                className="pag4-sanfona2-image2 Radius"
              />
            </div>
            <div className="pag4-sanfona2-container5">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag4-sanfona2-text45 Subtitulo-A">
                      <span>
                        Ubbu
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag4-sanfona2-text47">
                        é uma ferramenta de aprendizado que ensina
                      </span>
                      <span>
                        {' '}
                        ciência da computação e programação para crianças e
                        jovens
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span>
                  O objetivo é
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona2-text14">
                  preparar as suas mentes para o futuro!
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  A ideia é formar pensadores lógicos, solucionadores de
                  problemas e cidadãos habilidosos e conscientes, tudo isso
                  enquanto se divertem!
                </span>
                <br></br>
                <br></br>
                <span>
                  Para isso, a Ubbu explora a interseção de diferentes áreas do
                  saber, ajudando os participantes a construírem novos caminhos
                  de conhecimento sobre os mais diversos assuntos com jogos
                  guiados!
                </span>
                <br></br>
                <br></br>
                <span>
                  Com a Ubbu, você será
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona2-text22">
                  conectado ao Pensamento Computacional com o K-12 CS Framework
                </span>
                <span>
                  , que permite explorar sistemas computacionais, hardware e
                  software, cibe segurança, redes de internet, análise de dados,
                  algoritmos, entre outros. Tem, ainda, as disciplinas
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona2-text24">STEM</span>
                <span>
                  {' '}
                  interdisciplinares: tecnologia, artes, ciência, matemática e
                  engenharia.
                </span>
                <br></br>
                <br></br>
                <span>
                  E já pensou em desenvolver suas habilidades psicomotoras? A
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag4-sanfona2-text29">
                  Ubbu pode ajudar você a trabalhar suas habilidades motoras
                  cognitivas, com muito pensamento crítico e criatividade!
                </span>
              </span>
            </div>
          </div>
          <div className="pag4-sanfona2-container6 Radius">
            <div className="pag4-sanfona2-container7">
              <div className="pag4-sanfona2-container8">
                <h1 className="Titulo-Extra">
                  <span className="pag4-sanfona2-text31">
                    Isso tudo sempre aliado aos
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span>17 ODS da ONU</span>
                </h1>
                <span className="Texto">
                  <span>
                    Os
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona2-text35">
                    17 Objetivos de Desenvolvimento Sustentável
                  </span>
                  <span>
                    {' '}
                    da ONU é o modelo adotado por todos os estados membros para
                    encarar os
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="pag4-sanfona2-text37">
                    desafios globais que o mundo enfrenta hoje.
                  </span>
                  <br className="pag4-sanfona2-text38"></br>
                  <br></br>
                  <span>
                    Pois a Ubbu acredita que a tecnologia pode ajudar a tornar o
                    mundo um lugar melhor, por isso, desde o primeiro dia,
                    assumiu um compromisso:
                  </span>
                  <span className="pag4-sanfona2-text41">
                    {' '}
                    criar um espaço que reflita e afete o mundo real.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="pag4-sanfona2-text44">
                    Acesse e conheça essa aliada e parceira do SESC na educação.
                  </span>
                </span>
              </div>
              <img
                alt="image"
                src="/Pagina 4/imagens/pagina%204%20imagem%206.png"
                className="pag4-sanfona2-image3 Radius"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag4Sanfona2.defaultProps = {
  heading111: undefined,
  rootClassName: '',
}

Pag4Sanfona2.propTypes = {
  heading111: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag4Sanfona2
