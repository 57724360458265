import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag1-sanfona3.css'

const Pag1Sanfona3 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag1-sanfona3-container10 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag1-sanfona3-topo">
        <span className="pag1-sanfona3-text10 Titulo-Extra-B">
          História da internet
        </span>
        <div className="pag1-sanfona3-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona3-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag1-sanfona3-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag1-sanfona3-container11">
          <div className="pag1-sanfona3-container12">
            <div className="pag1-sanfona3-container13">
              <span>
                {props.text8 ?? (
                  <Fragment>
                    <span className="pag1-sanfona3-text23 Subtitulo-A">
                      <span>
                        Hoje a internet está
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona3-text25">
                        disponível na maior parte dos lares brasileiros
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="pag1-sanfona3-image1"
              />
            </div>
            <div className="pag1-sanfona3-container14">
              <a
                href="https://agenciabrasil.ebc.com.br/economia/noticia/2024-04/apenas-22-dos-brasileiros-tem-boas-condicoes-de-conectividade"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona3-link1"
              >
                {props.text3 ?? (
                  <Fragment>
                    <span className="pag1-sanfona3-text26 Texto">
                      <span>
                        Segundo o IBGE (dados de 2022), a parcela dos domicílios
                        brasileiros com acesso à internet saltou de 70,9% em
                        2016 para 91,5% em 2022. Ao todo, 161,6 milhões de
                        pessoas de 10 anos ou mais usaram a rede no ano passado,
                        o que correspondia a 87,2% da população nessa faixa
                        etária.
                      </span>
                      <br></br>
                      <br></br>
                      <span>
                        Embora a internet esteja disponível na maior parte dos
                        lares brasileiros, um estudo recente deu conta de que
                        apenas 22% da população brasileira possui níveis
                        satisfatórios de conectividades.
                      </span>
                      <br></br>
                      <br></br>
                      <span>Quer entender melhor sobre isso?</span>
                      <br></br>
                      <span className="pag1-sanfona3-text35">
                        Dá uma olhada nessa matéria da Agência Brasil.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
              <a
                href="https://www.myinstants.com/en/instant/internet-discada-curto-13050/"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona3-link2"
              >
                <div className="pag1-sanfona3-container15">
                  <div className="pag1-sanfona3-container16">
                    <img
                      alt={props.imageAlt7}
                      src={props.imageSrc7}
                      className="pag1-sanfona3-image2"
                    />
                  </div>
                  <span className="pag1-sanfona3-text12 Texto">
                    Clique para descobrir qual era o som da internet
                  </span>
                </div>
              </a>
              <span>
                {props.text4 ?? (
                  <Fragment>
                    <span className="pag1-sanfona3-text19 Texto">
                      <span>
                        Se você for jovem, talvez não reconheça esse som. Mas
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag1-sanfona3-text21">
                        há nem tanto tempo, a internet era discada.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        Pergunte para alguém com 25 anos ou acima se ela
                        reconhece esse sonzinho ao lado. E peça para te contar
                        essa história.
                      </span>
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div className="pag1-sanfona3-container17">
            <div className="pag1-sanfona3-container18">
              <div className="pag1-sanfona3-container19">
                <span>
                  {props.text6 ?? (
                    <Fragment>
                      <span className="pag1-sanfona3-text18 Texto-Extra">
                        Indicação
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
              <a
                href="https://www.youtube.com/watch?v=pKxWPo73pX0"
                target="_blank"
                rel="noreferrer noopener"
                className="pag1-sanfona3-link3"
              >
                {props.text7 ?? (
                  <Fragment>
                    <span className="pag1-sanfona3-text15 Texto">
                      <span>
                        Quer conhecer mais sobre a história da internet? 
                      </span>
                      <span className="pag1-sanfona3-text17">
                        Dá uma olhada nesse conteúdo do TecMundo.
                      </span>
                    </span>
                  </Fragment>
                )}
              </a>
            </div>
            <img
              alt={props.imageAlt3}
              src={props.imageSrc3}
              className="pag1-sanfona3-image3"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Pag1Sanfona3.defaultProps = {
  text7: undefined,
  imageSrc5: 'c16cb554-06b1-469c-92c6-be68be7e06a1',
  imageAlt7: 'image',
  text6: undefined,
  text4: undefined,
  imageAlt3: 'image',
  text8: undefined,
  rootClassName: '',
  imageSrc3:
    '/Pagina 1/Sanfona 3/pagina%201%20sanfona%203%20imagem%202-1500w.png',
  imageSrc7: '/Pagina 1/Sanfona 3/home%20botao-200h.png',
  imageAlt: 'image',
  text3: undefined,
  imageSrc:
    '/Pagina 1/Sanfona 3/pagina%201%20sanfona%203%20imagem%201-400h.webp',
}

Pag1Sanfona3.propTypes = {
  text7: PropTypes.element,
  imageSrc5: PropTypes.string,
  imageAlt7: PropTypes.string,
  text6: PropTypes.element,
  text4: PropTypes.element,
  imageAlt3: PropTypes.string,
  text8: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc3: PropTypes.string,
  imageSrc7: PropTypes.string,
  imageAlt: PropTypes.string,
  text3: PropTypes.element,
  imageSrc: PropTypes.string,
}

export default Pag1Sanfona3
