import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './pag6-sanfona3.css'

const Pag6Sanfona3 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag6-sanfona3-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag6-sanfona3-topo">
        <span className="pag6-sanfona3-text10 Titulo-Extra-B">
          Para chegar lá!
        </span>
        <div className="pag6-sanfona3-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona3-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona3-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag6-sanfona3-container2">
          <div className="pag6-sanfona3-container3">
            <div className="pag6-sanfona3-container4">
              <h1>
                {props.heading111 ?? (
                  <Fragment>
                    <h1 className="pag6-sanfona3-text32 Subtitulo-A">
                      <span>
                        Quero trabalhar com robótica e tecnologia.
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag6-sanfona3-text34">
                        Qual graduação devo seguir?
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span>
                  Normalmente, o caminho universitário para trabalhar na
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona3-text14">
                  área da robótica é uma graduação na área das Engenharias.
                </span>
                <br></br>
                <br></br>
                <span>
                  O curso de
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona3-text18">
                  Engenharia de Computação
                </span>
                <span>
                  , por exemplo, prepara o profissional para lidar com hardware
                  e software, além de oferecer disciplinas importantes para
                  atuação no campo da Robótica, como Eletrônica Digital,
                  Engenharia de Software, Inteligência Artificial e Automação.
                  Com 5 anos de duração, a graduação em Engenharia de Computação
                  proporciona uma formação completa, que possibilita ao egresso
                  atuar em multifunções.
                </span>
                <br></br>
                <br></br>
                <span>
                  A graduação em
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona3-text23">
                  Engenharia de Produção
                </span>
                <span>
                   também desenvolve habilidades importantes em tecnologia, como
                  Inteligência Artificial e construção de novos processos
                  operacionais autônomos. Nesse campo, a Robótica é essencial
                  para a criação de processos de produção mais eficientes.
                </span>
              </span>
            </div>
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%204-1500w.png"
              className="pag6-sanfona3-image1 Radius"
            />
          </div>
          <div className="pag6-sanfona3-container5">
            <div className="pag6-sanfona3-container6">
              <h1>
                {props.heading1112 ?? (
                  <Fragment>
                    <h1 className="pag6-sanfona3-text36 Subtitulo-A">
                      <span className="pag6-sanfona3-text37">
                        Mas há
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>
                        cursos tecnólogos
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span className="pag6-sanfona3-text39">
                        bem interessantes e eficazes que também podem abrir
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <span>caminhos para a vida profissional.</span>
                    </h1>
                  </Fragment>
                )}
              </h1>
              <span className="Texto">
                <span>
                  Cursos tecnólogos são uma modalidade de ensino superior com
                  duração de 2 a 3 anos, focados na formação de profissionais
                  para atender a
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona3-text28">
                  demandas específicas
                </span>
                <span>
                  {' '}
                  do mercado de trabalho, como tecnologia, segurança, turismo,
                  design, infraestrutura e outros. Assim como os cursos de
                  bacharelado e licenciatura, os
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona3-text30">
                  cursos tecnólogos são reconhecidos pelo Ministério da Educação
                  (MEC)
                </span>
                <span> e seguem diretrizes curriculares nacionais.</span>
              </span>
            </div>
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%205-1500w.png"
              className="pag6-sanfona3-image2 Radius"
            />
          </div>
        </div>
      )}
    </div>
  )
}

Pag6Sanfona3.defaultProps = {
  heading111: undefined,
  heading1112: undefined,
  rootClassName: '',
}

Pag6Sanfona3.propTypes = {
  heading111: PropTypes.element,
  heading1112: PropTypes.element,
  rootClassName: PropTypes.string,
}

export default Pag6Sanfona3
