import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './pag6-sanfona2.css'

const Pag6Sanfona2 = (props) => {
  const [isAtivo, setIsAtivo] = useState(false)
  return (
    <div
      className={`pag6-sanfona2-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag6-sanfona2-topo">
        <span className="pag6-sanfona2-text10 Titulo-Extra-B">
          Profissões na Robótica e Tecnologia
        </span>
        <div className="pag6-sanfona2-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona2-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag6-sanfona2-icone-inativo"
            />
          )}
        </div>
      </div>
      {isAtivo && (
        <div className="pag6-sanfona2-container2">
          <div className="pag6-sanfona2-container3">
            <div className="pag6-sanfona2-container4">
              <span className="Texto">
                <span>
                  A área de tecnologia é um ramo que proporciona grandes
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag6-sanfona2-text13">
                  chances de contribuir para os avanços em direção a um mundo
                  mais sustentável e feliz.
                </span>
                <span>
                  {' '}
                  Porque esses avanços influenciam diretamente as relações e
                  hábitos da sociedade contemporânea. Por ser uma ampla área de
                  atuação, com excelentes oportunidades no mercado de trabalho,
                  existem diversas profissões para seguir.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </div>
          </div>
          <div className="pag6-sanfona2-container5">
            <div className="pag6-sanfona2-container6">
              <h1 className="pag6-sanfona2-text15 Subtitulo-A">
                Trouxemos algumas profissões para você conhecer:
              </h1>
              <span className="Texto">
                <span>• Desenvolvedor de software</span>
                <br></br>
                <span>• Roboticista</span>
                <br></br>
                <span>• Cientista de dados</span>
                <br></br>
                <span>• Arquiteto de Big Data</span>
                <br></br>
                <span>• Engenheiro mecatrônico</span>
                <br></br>
                <span>• Especialista em inteligência artificial</span>
                <br></br>
                <span>• Analista DevOps</span>
                <br></br>
                <span>• Desenvolvedor web</span>
                <br></br>
                <span>• Desenvolvedor full-stack</span>
                <br></br>
                <span>• Segurança da informação</span>
                <br></br>
                <span>• Engenheiro de Quality Assurance</span>
              </span>
            </div>
            <div className="pag6-sanfona2-container7">
              <img
                alt={props.imageAlt1}
                src={props.imageSrc1}
                className="pag6-sanfona2-image1"
              />
              <img
                alt={props.imageAlt}
                src={props.imageSrc}
                className="pag6-sanfona2-image2"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Pag6Sanfona2.defaultProps = {
  imageSrc1: '/Pagina 6/images/pagina%206%20imagem%203.png',
  rootClassName: '',
  imageAlt1: 'image',
  imageSrc: '/Pagina 6/images/pagina%206%20imagem%202.png',
  imageAlt: 'image',
}

Pag6Sanfona2.propTypes = {
  imageSrc1: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default Pag6Sanfona2
