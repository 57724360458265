import React, { useState, Fragment, useEffect } from 'react'

import PropTypes from 'prop-types'

import ModeloSlide from './modelo-slide'
import './pag3-sanfona1.css'

const Pag3Sanfona1 = (props) => {
  const [isAtivo, setIsAtivo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsAtivo(false);
    }, 100);
  }, []);
  return (
    <div
      className={`pag3-sanfona1-container1 AcordeaoContainer ${props.rootClassName} `}
    >
      <div onClick={() => setIsAtivo(!isAtivo)} className="pag3-sanfona1-topo">
        <span className="pag3-sanfona1-text100 Titulo-Extra-B">Smart Home</span>
        <div className="pag3-sanfona1-botoes">
          {isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona1-icone-ativo"
            />
          )}
          {!isAtivo && (
            <img
              alt="image"
              src="/Geral/icone%20acordeao%20aberto-200h.png"
              className="pag3-sanfona1-icone-inativo"
            />
          )}
        </div>
      </div>
      <div className="pag3-sanfona1-container2" style={isAtivo ? {} : { display: "none" }}>
        <div className="pag3-sanfona1-container3">
          <div className="pag3-sanfona1-container4">
            <h1>
              {props.heading111 ?? (
                <Fragment>
                  <h1 className="pag3-sanfona1-text207 Subtitulo-A">
                    O que é uma casa inteligente?
                  </h1>
                </Fragment>
              )}
            </h1>
            <span className="Texto">
              <span>
                A casa inteligente, ou Smart Home,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona1-text104">
                funciona por meio de dispositivos que permitem a automação de
                tarefas do cotidiano
              </span>
              <span>
                , como ligar o ar-condicionado ou colocar uma música para tocar.
                Então é a mesma coisa que uma casa conectada, certo? Mais ou
                menos...
              </span>
              <br></br>
              <br></br>
              <span>
                Uma casa conectada precisa do comando do usuário para executar
                tarefas.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona1-text109">
                As casas inteligentes, por sua vez, sabem o que fazer sem a
                necessidade de comandos!
              </span>
              <span> Como assim?</span>
              <br></br>
              <br></br>
              <span>
                Bom,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="pag3-sanfona1-text114">
                os sistemas já são configurados de acordo com as preferências
              </span>
              <span>
                {' '}
                do morador da casa. Ou seja, é possível escolher ligar o
                ar-condicionado de segunda a sexta-feira às 17h, por exemplo. E
              </span>
              <span className="pag3-sanfona1-text116">
                {' '}
                a ativação pode ser pré-configurada ou feita a distância
              </span>
              <span>
                , por meio de aplicativos e assistentes virtuais, que irão
                transmitir os comandos ao aparelho.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/Pagina 3/imagens/pagina%203%20imagem%202-1500w.png"
            className="pag3-sanfona1-image1"
          />
        </div>
        <div className="pag3-sanfona1-container5">
          <div className="pag3-sanfona1-container6">
            <div className="pag3-sanfona1-container7">
              <h1 className="Titulo-Extra">
                <span className="pag3-sanfona1-text119">
                  É possível ter diversos produtos inteligentes no ambiente,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>desde lâmpadas </span>
                <span>até geladeiras</span>
              </h1>
              <span className="Texto">
                <span>
                  É importante também
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona1-text124">
                  garantir a compatibilidade entre os diferentes aparelhos
                </span>
                <span>, de forma a integrar tudo em um mesmo sistema. </span>
                <span>
                  Com isso,
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pag3-sanfona1-text127">
                  o usuário pode programar diferentes ações para serem
                  executadas
                </span>
                <span> ao longo do dia ou mesmo da semana. Legal, né?</span>
                <br className="pag3-sanfona1-text129"></br>
                <br className="pag3-sanfona1-text130"></br>
                <span>
                  Ok, agora você quer saber o que é preciso para equipar essa
                  casa do futuro? Com os avanços de conceitos como
                  a inteligência artificial e o metaverso, o mercado de produtos
                  inteligentes para casa tem crescido nos últimos anos.
                </span>
              </span>
            </div>
            <img
              alt="image"
              src="/Pagina 3/imagens/pagina%203%20imagem%203-1500w.png"
              className="pag3-sanfona1-image2"
            />
          </div>
        </div>
        <div className="pag3-sanfona1-container8">
          <h1 className="pag3-sanfona1-text132 Subtitulo-A">
            <span>Saiba quais são os dispositivos que podem </span>
            <span>fazer parte de uma casa inteligente:</span>
          </h1>
          <div
            data-thq="slider"
            data-navigation="false"
            data-pagination="true"
            className="pag3-sanfona1-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide1 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text135 Titulo">
                        Lâmpadas
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text136 Texto">
                        <span>
                          As lâmpadas inteligentes podem ser
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text138">
                          controladas por aplicativo ou comando de voz.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          A
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text142">
                          ativação é feita por meio do wi-fi
                        </span>
                        <span>
                          , permitindo ligar e desligar as luzes sem a
                          necessidade de ir até um interruptor. Alguns modelos
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text144">
                          também oferecem ajustes de intensidade e cor
                        </span>
                        <span> para a iluminação.</span>
                        <br></br>
                        <br></br>
                        <span>
                          E para criar um clima relax antes de dormir, é
                          possível
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text149">
                          definir horários para o acionamento automático das
                          lâmpadas
                        </span>
                        <span>
                          {' '}
                          com uma configuração de iluminação mais baixa.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%201-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%201-1500h.png"
                  rootClassName="modelo-slideroot-class-name6"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide2 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text151 Titulo">
                        Sensores de presença
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text152 Texto">
                        <span>
                          Eles funcionam como um alarme para a segurança da
                          casa,
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text154">
                          identificando a presença de pessoas no ambiente.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          A partir dessa identificação, os dispositivos podem
                          enviar sinais de aviso ao proprietário da casa,
                          notificando a invasão. Os avisos podem ser mensagens
                          no celular, alarmes sonoros ou notificações enviadas
                          diretamente à central de monitoramento.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text158">
                          As configurações dependem do modelo e dos ajustes
                          escolhidos pelo usuário.
                        </span>
                        <br></br>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%202-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%202-1500h.png"
                  rootClassName="modelo-slideroot-class-name7"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide3 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text160 Titulo">
                        Interruptor inteligente
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text161 Texto">
                        <span>
                          Assim como as lâmpadas, o interruptor também funciona
                          por meio da internet.
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text163">
                          Os interruptores costumam ter um fio que os conecta ao
                          sistema elétrico da casa e outro que fica responsável
                          por essa conexão com o wi-fi.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          O aparelho deve ser fixado na parede, assim como o
                          interruptor tradicional, e
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text167">
                          permite personalizar as configurações
                        </span>
                        <span>
                          {' '}
                          de iluminação do ambiente, com ativação automática e
                          ajuste de intensidade.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%203-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%203-1500h.png"
                  rootClassName="modelo-slideroot-class-name84"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide4 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text169 Titulo">
                        Câmeras de segurança
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text170 Texto">
                        <span>
                          As câmeras de segurança funcionam por meio da conexão
                          com o wi-fi e as imagens
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text172">
                          podem ser acompanhadas à distância pelo aplicativo
                        </span>
                        <span> do fabricante.</span>
                        <br></br>
                        <br></br>
                        <span>
                          Essa tecnologia tem a mesma função dos sensores:
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text177">
                          identificar a entrada de estranhos e emitir avisos aos
                          proprietários da residência.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          No entanto, as câmeras trazem o registro de imagens
                          como complemento à segurança,
                        </span>
                        <br></br>
                        <span>
                          o que pode contribuir para a montagem de um sistema
                          inteligente para casas mais completo.
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%204-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%204-1500h.png"
                  rootClassName="modelo-slideroot-class-name85"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide5 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text183 Titulo">
                        Robô aspirador
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text184 Texto">
                        <span>
                          A função desses robôs é aspirar a casa. Os robôs
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text186">
                          fazem a limpeza automática dos ambientes
                        </span>
                        <span>
                          , seguindo a configuração indicada pelo usuário.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Alguns modelos podem também
                          <span
                            dangerouslySetInnerHTML={{
                              __html: ' ',
                            }}
                          />
                        </span>
                        <span className="pag3-sanfona1-text191">
                          varrer e passar pano com controle a distância, com
                          carregamento automático e integração com assistentes
                          virtuais.
                        </span>
                        <br className="pag3-sanfona1-text192"></br>
                        <br className="pag3-sanfona1-text193"></br>
                        <span>
                          E tem mais! Modelos mais avançados conseguem fazer o
                          mapeamento da casa, emitir relatórios das atividades e
                          disponibilizar opção de monitoramento pelo celular,
                          além de permitir a definição de horários para a
                          limpeza. Não é uma maravilha?
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%205-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%205-1500h.png"
                  rootClassName="modelo-slideroot-class-name86"
                ></ModeloSlide>
              </div>
              <div
                data-thq="slider-slide"
                className="pag3-sanfona1-slider-slide6 swiper-slide"
              >
                <ModeloSlide
                  icone="/Pagina 3/slide 1/pagina%203%20slide%201%20icon-1500h.png"
                  titulo={
                    <Fragment>
                      <span className="pag3-sanfona1-text195 Titulo">
                        Geladeira smart
                      </span>
                    </Fragment>
                  }
                  conteudo={
                    <Fragment>
                      <span className="pag3-sanfona1-text196 Texto">
                        <span>
                          As geladeiras smart se conectam à internet e permitem
                          o controle a distância, assim como a integração com
                          outros dispositivos. Com isso,
                        </span>
                        <span className="pag3-sanfona1-text198">
                          {' '}
                          é possível ajustar a temperatura ou ativar funções
                          específicas
                        </span>
                        <span>
                          , como o congelamento express de bebidas, sem
                          interagir com o aparelho.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          Alguns modelos também permitem ouvir música, assistir
                          vídeos e exibir fotos no display da geladeira. Outros,
                          mais avançados, oferecem uma
                        </span>
                        <span className="pag3-sanfona1-text203">
                          {' '}
                          funcionalidade que permite verificar quais alimentos
                          estão dentro dela.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          E aí, você gostaria de morar numa casa do futuro?
                        </span>
                      </span>
                    </Fragment>
                  }
                  imageSrc="/Pagina 3/slide 1/pagina%203%20slide%201%20imagem%206-1200w.png"
                  imageSrc1="/Pagina 3/slide 1/pagina%203%20slide%201%20mobile%20imagem%206-1500h.png"
                  rootClassName="modelo-slideroot-class-name87"
                ></ModeloSlide>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="pag3-sanfona1-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="pag3-sanfona1-slider-button-prev swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="pag3-sanfona1-slider-button-next swiper-button-next"
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pag3Sanfona1.defaultProps = {
  rootClassName: '',
  heading111: undefined,
}

Pag3Sanfona1.propTypes = {
  rootClassName: PropTypes.string,
  heading111: PropTypes.element,
}

export default Pag3Sanfona1
