import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Pag6Sanfona1 from '../components/pag6-sanfona1'
import Pag6Sanfona2 from '../components/pag6-sanfona2'
import Pag6Sanfona3 from '../components/pag6-sanfona3'
import Pag6Sanfona4 from '../components/pag6-sanfona4'
import SeoFinal from '../components/seo-final'
import './pagina-6.css'

const Pagina6 = (props) => {
  return (
    <div className="pagina6-container1">
      <Helmet>
        <title>Pagina-6 - MIRT</title>
        <meta property="og:title" content="Pagina-6 - MIRT" />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name6"></Navbar>
      <div className="pagina6-hero DefaultPadding">
        <img
          alt="image"
          src="/Geral/fundo%20topo%20pagina-700h.png"
          className="pagina6-image1"
        />
        <div className="pagina6-container2 DefaultInnerPadding">
          <div className="pagina6-container3">
            <span className="pagina6-text10">06</span>
            <div className="pagina6-container4">
              <span className="pagina6-text11 Subtitulo-A">
                Prazer, humano, eu sou um robô!
              </span>
              <div className="pagina6-container5">
                <div className="pagina6-boto">
                  <span className="pagina6-text12 Botao">Versão acessível</span>
                </div>
                <div className="pagina6-indicaes">
                  <img
                    alt="Vector9161"
                    src="/Geral/sinais-200w.png"
                    className="pagina6-vector1"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/ingles-200w.png"
                    className="pagina6-vector2"
                  />
                  <img
                    alt="Vector9161"
                    src="/Geral/descricao-200w.png"
                    className="pagina6-vector3"
                  />
                </div>
              </div>
            </div>
          </div>
          <img
            alt="image"
            src="/Pagina 6/images/pagina%206%20imagem%201-1200w.png"
            className="pagina6-image2"
          />
          <div className="pagina6-container6">
            <img
              alt="Vector1068"
              src="/external/vector1068-4e2.svg"
              className="pagina6-vector4"
            />
            <span className="Observacao">
              <span className="pagina6-text14">Vá além e aprenda mais</span>
              <span> a partir dos</span>
              <br></br>
              <span>conteúdos que você assistiu no vídeo</span>
            </span>
          </div>
        </div>
      </div>
      <div className="pagina6-container7">
        <Pag6Sanfona1 rootClassName="pag6-sanfona1root-class-name"></Pag6Sanfona1>
        <Pag6Sanfona2 rootClassName="pag6-sanfona2root-class-name"></Pag6Sanfona2>
        <Pag6Sanfona3
          heading111={
            <Fragment>
              <span className="pagina6-text18 Subtitulo-A">
                <span>
                  Quero trabalhar com robótica e tecnologia.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina6-text20">
                  Qual graduação devo seguir?
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
              </span>
            </Fragment>
          }
          heading1112={
            <Fragment>
              <span className="pagina6-text22 Subtitulo-A">
                <span className="pagina6-text23">
                  Mas há
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  cursos tecnólogos
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="pagina6-text25">
                  bem interessantes e eficazes que também podem abrir
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>caminhos para a vida profissional.</span>
              </span>
            </Fragment>
          }
          rootClassName="pag6-sanfona3root-class-name"
        ></Pag6Sanfona3>
        <Pag6Sanfona4
          text6={
            <Fragment>
              <span className="pagina6-text27 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text7={
            <Fragment>
              <span className="pagina6-text28 Texto">
                <span className="pagina6-text29">RobotShop Community</span>
                <br></br>
                <span>
                  Uma comunidade online que discute robótica, projetos e
                  carreiras na área.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          text61={
            <Fragment>
              <span className="pagina6-text33 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text72={
            <Fragment>
              <span className="pagina6-text34 Texto">
                <span className="pagina6-text35">Robohub</span>
                <br className="pagina6-text36"></br>
                <span>
                  Cobre todas as áreas da robótica, incluindo artigos sobre
                  carreiras e tendências.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          text73={
            <Fragment>
              <span className="pagina6-text39 Texto">
                <span className="pagina6-text40">Real Engineering</span>
                <br className="pagina6-text41"></br>
                <span>
                  Vídeos que exploram engenharia e tecnologia, incluindo
                  robótica e suas aplicações.
                </span>
              </span>
            </Fragment>
          }
          heading={
            <Fragment>
              <span className="pagina6-text43 Titulo-Extra">Sites e blogs</span>
            </Fragment>
          }
          text611={
            <Fragment>
              <span className="pagina6-text44 Texto-Extra">Indicação</span>
            </Fragment>
          }
          text721={
            <Fragment>
              <span className="pagina6-text45 Texto">
                <span className="pagina6-text46">IEEE Spectrum Robotics</span>
                <br></br>
                <span>
                  Seção dedicada a notícias e artigos sobre robótica, incluindo
                  aspectos de carreira.
                </span>
              </span>
            </Fragment>
          }
          text722={
            <Fragment>
              <span className="pagina6-text49 Texto">
                <span className="pagina6-text50">RobotShop TV</span>
                <br className="pagina6-text51"></br>
                <span>
                  Tutoriais, demonstrações e discussões sobre robótica.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          text731={
            <Fragment>
              <span className="pagina6-text54 Texto">
                <span className="pagina6-text55">
                  Radhika Nagpal: What intelligent
                </span>
                <br className="pagina6-text56"></br>
                <span className="pagina6-text57">
                  machines can learn from a school of fish.
                </span>
                <br className="pagina6-text58"></br>
                <span>Explora como o comportamento de </span>
                <span>enxames na natureza pode inspirar novas </span>
                <span>abordagens para a programação de robôs </span>
                <span>autônomos e sistemas inteligentes.</span>
              </span>
            </Fragment>
          }
          heading1={
            <Fragment>
              <span className="pagina6-text63 Titulo-Extra">
                Vídeos no YouTube
              </span>
            </Fragment>
          }
          text7211={
            <Fragment>
              <span className="pagina6-text64 Texto">
                <span className="pagina6-text65">Robotics Business Review</span>
                <br></br>
                <span>Focado em notícias de negócios  e </span>
                <span>carreiras na indústria de robótica.</span>
              </span>
            </Fragment>
          }
          text7311={
            <Fragment>
              <span className="pagina6-text69 Texto">
                <span className="pagina6-text70">
                  Rodney Brooks: Why we will rely on robots.
                </span>
                <br className="pagina6-text71"></br>
                <span>
                  O co-fundador da iRobot, explora como os robôs estão se
                  tornando essenciais em nossas vidas e como isso pode impactar
                  o futuro das profissões.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          heading11={
            <Fragment>
              <span className="pagina6-text74 Titulo-Extra">TED Talks</span>
            </Fragment>
          }
          text73112={
            <Fragment>
              <span className="pagina6-text75 Texto">
                <span className="pagina6-text76">
                  Cynthia Breazeal: The rise of personal robots.
                </span>
                <br></br>
                <span>
                  Explora o potencial dos robôs pessoais integrando nosso
                  cotidiano.
                </span>
              </span>
            </Fragment>
          }
          text73113={
            <Fragment>
              <span className="pagina6-text79 Texto">
                <span className="pagina6-text80">
                  Vijay Kumar: Robots that fly... And cooperate.
                </span>
                <br></br>
                <span>
                  Fala sobre pesquisas com robôs voadores e como essas
                  tecnologias podem ser utilizadas para resolver problemas
                  complexos e colaborar em equipes.
                </span>
              </span>
            </Fragment>
          }
          text73114={
            <Fragment>
              <span className="pagina6-text83 Texto">
                <span className="pagina6-text84">
                  Ken Goldberg: 4 lessons from robots about being human.
                </span>
                <br></br>
                <span>
                  O palestrante explora a interação com robôs e as
                  possibilidades de aprendermos com eles e sobre nós mesmos,
                  nossa empatia e nossas expectativas para o futuro da
                  colaboração homem-máquina.
                </span>
                <br></br>
              </span>
            </Fragment>
          }
          rootClassName="pag6-sanfona4root-class-name"
        ></Pag6Sanfona4>
      </div>
      <div className="pagina6-sugestao-conteudo1 DefaultPadding">
        <div className="pagina6-interno1 MaxWidthMenor">
          <div className="pagina6-texto-ei1">
            <span className="pagina6-text88 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina6-text89 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina6-button1 Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina6-container8">
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%2010-1200w.png"
              className="pagina6-image3"
            />
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%209-1200w.png"
              className="pagina6-image4"
            />
          </div>
        </div>
      </div>
      <div className="pagina6-sugestao-conteudo2 DefaultPadding">
        <div className="pagina6-interno2 MaxWidthMenor">
          <div className="pagina6-texto-ei2">
            <span className="pagina6-text90 Titulo-B">
              Sugestão de atividade
            </span>
            <span className="pagina6-text91 Texto">
              Clique para baixar o material pedagógico complementar com
              sugestões de atividades para serem feitas na escola.
            </span>
            <Link
              to="/formulario"
              className="pagina6-button2 Pointer Botao HoverScale"
            >
              Baixar conteúdo
            </Link>
          </div>
          <div className="pagina6-container9">
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%209%20new%20mobile-1200w.png"
              className="pagina6-image5 ShowOnMobile"
            />
            <img
              alt="image"
              src="/Pagina 6/images/pagina%206%20imagem%209%20new-1200w.png"
              className="pagina6-image6 HideOnMobile"
            />
          </div>
        </div>
      </div>
      <SeoFinal
        button="Quero a MIRT pertinho de mim"
        rootClassName="seo-finalroot-class-name5"
      ></SeoFinal>
    </div>
  )
}

export default Pagina6
